import { types, flow } from 'mobx-state-tree';
import { values } from 'mobx';

import { request } from 'src/utils/LodgebookAPIClient';

export const HOTEL_TASKS_URL = '/hotel_tasks';

export const HotelTask = types.model('HotelTask', {
  id: types.identifierNumber,
  description: types.frozen({
    english: types.string,
    spanish: types.string,
    chinese: types.string,
  }),
  mostUsed: types.optional(types.boolean, false),
  imageUrl: types.maybeNull(types.string),
});

const HotelTaskStore = types
  .model('HotelTaskStore', {
    hotelTasks: types.optional(types.map(HotelTask), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingOne: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get hotelTasksAsArray() {
      return values(self.hotelTasks);
    },
  }))
  .actions((self) => ({
    fetchAllHotelTasks: flow(function*(hotelId) {
      self.isFetchingAll = true;

      try {
        const hotelTasksRequest = yield request(
          `${HOTEL_TASKS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.hotelTasks = {};
        hotelTasksRequest.hotelTasks.forEach((hotelTask) => {
          self.hotelTasks.set(hotelTask.id, hotelTask);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.warn('Failed to fetch hotel tasks', error);
      }
      self.isFetchingAll = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default HotelTaskStore;
