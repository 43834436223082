import React, { useContext } from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { ThemeContext } from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import RoomsIcon from 'assets/icons/rooms.svg';
import TasksIcon from 'assets/icons/task.svg';
import RoomScreen from 'src/screens/Room/RoomScreen/RoomScreen';
import TaskListScreen from 'src/screens/Task/TaskListScreen/TaskListScreen';
import { screen } from 'src/constants';
import * as roomRoutes from 'src/screens/Room/routes';
import * as taskRoutes from 'src/screens/Task/routes';

const BottomTab = () => {
  const Tab = createBottomTabNavigator();
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: theme.colors.BACKGROUND_LIGHT,
        inactiveTintColor: theme.colors.BLACK,
        style: {
          backgroundColor: theme.colors.PRIMARY,
        },
        labelStyle: {
          fontSize: screen.SCREEN_WIDTH_MAXED * 0.033,
          lineHeight: 16,
          letterSpacing: 0.4,
          fontFamily: theme.fontFamily.regular,
        },
        labelPosition: 'below-icon',
      }}
    >
      <Tab.Screen
        name={roomRoutes.RoomScreen}
        component={RoomScreen}
        options={() => {
          const options = {
            // eslint-disable-next-line react/prop-types
            tabBarIcon: ({ color, size }) => (
              <RoomsIcon width={size} height={size} fill={color} />
            ),
            title: t(strings.ROOM_TEXT_PLURAL),
          };
          return options;
        }}
      />

      <Tab.Screen
        name={taskRoutes.TaskListScreen}
        component={TaskListScreen}
        options={() => {
          const options = {
            // eslint-disable-next-line react/prop-types
            tabBarIcon: ({ color, size }) => (
              <TasksIcon width={size} height={size} fill={color} />
            ),
            title: t(strings.TASK_TEXT_PLURAL),
          };
          return options;
        }}
      />
    </Tab.Navigator>
  );
};

export default BottomTab;
