import React, { useState } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';

import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import RoomDetailsTaskList from './RoomDetailsTaskList';
import RoomDetailsTabs from './RoomDetailsTabs';

export const testIds = {
  INCOMPLETE_COUNT: 'INCOMPLETE_COUNT',
  INCOMPLETE_LIST: 'INCOMPLETE_LIST',
  COMPLETED_TODAY_LIST: 'COMPLETED_TODAY_LIST',
  COMPLETED_TODAY_COUNT: 'COMPLETED_TODAY_COUNT',
};

const TaskContainer = styled(View)`
  flex: 1;
`;

const RoomDetails = observer(
  ({ room, showTaskHistory, setTaskSelected, userType }) => {
    let tasksForRoom;

    const [statusSelected, setStatusSelected] = useState(INCOMPLETE_STATUS);

    if (statusSelected === INCOMPLETE_STATUS) {
      tasksForRoom = room.incompleteTasks;
    } else {
      tasksForRoom = room.completedTodayTasks;
    }

    return (
      <TaskContainer>
        {showTaskHistory ? (
          <RoomDetailsTaskList
            userType={userType}
            tasksForRoom={tasksForRoom}
            room={room}
            setSelectedTask={setTaskSelected}
            statusSelected={statusSelected}
          />
        ) : (
          <>
            <RoomDetailsTabs
              setStatusSelected={setStatusSelected}
              statusSelected={statusSelected}
              room={room}
            />
            <RoomDetailsTaskList
              userType={userType}
              statusSelected={statusSelected}
              tasksForRoom={tasksForRoom}
              room={room}
              setSelectedTask={setTaskSelected}
            />
          </>
        )}
      </TaskContainer>
    );
  }
);

RoomDetails.propTypes = {
  room: PropTypes.object,
  showTaskHistory: PropTypes.bool,
  setTaskSelected: PropTypes.func,
};

export default RoomDetails;
