import React, { useContext } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';

import CheckIcon from 'assets/icons/check.svg';

export const BUTTON = 'BUTTON';
export const LABEL = 'LABEL';
export const CHECK_ICON = 'CHECK_ICON';

const Container = styled(TouchableOpacity)`
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ selected, backgroundColor, theme }) =>
    selected ? backgroundColor : theme.colors.BACKGROUND_LIGHT};
  border-style: solid;
  border-width: 2px;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 16px;
  margin-bottom: 8px;
  padding-left: 24px;
  padding-right: 18px;
`;

const Circle = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  height: 20px;
  width: 20px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 2px;
  border-color: ${({ borderColor, theme }) =>
    borderColor || theme.colors.DISABLED};
`;

const Label = styled(Text)`
  ${({ theme }) => theme.textStyles.h5}
  color: ${({ selected, theme }) =>
    selected ? theme.colors.SECONDARY : theme.colors.BLACK};
  text-align: center;
`;

const RoomFilterItem = ({
  testID,
  label,
  backgroundColor,
  selected,
  onPress,
}) => {
  const theme = useContext(ThemeContext);
  backgroundColor = backgroundColor || theme.colors.DISABLED;

  return (
    <Container
      testID={testID || BUTTON}
      selected={selected}
      backgroundColor={backgroundColor}
      borderColor={backgroundColor}
      onPress={onPress}
    >
      <Label testID={LABEL} selected={selected}>
        {label}
      </Label>
      <Circle borderColor={backgroundColor}>
        {selected && (
          <CheckIcon
            testID={CHECK_ICON}
            width={11}
            height={11}
            stroke={backgroundColor}
            stroke-width="1"
            fill={backgroundColor}
          />
        )}
      </Circle>
    </Container>
  );
};

RoomFilterItem.propTypes = {
  testID: PropTypes.string,
  label: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default RoomFilterItem;
