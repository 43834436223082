import Constants from 'expo-constants';
import * as Notifications from 'expo-notifications';

export default async function registerForPushNotificationsAsync() {
  let token;

  if (Constants.isDevice) {
    try {
      token = (
        await Notifications.getExpoPushTokenAsync({
          experienceId: '@danielbang/Lodgebook',
        })
      ).data;
    } catch (error) {
      console.warn('Unable to register device for notifications');
    }
  } else {
    console.warn('Must use physical device for Push Notifications');
  }

  // eslint-disable-next-line consistent-return
  return token;
}
