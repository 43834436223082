import { Notifier } from '@airbrake/browser';
// eslint-disable-next-line import/no-unresolved
import { AIRBRAKE_PROJECT_ID, AIRBRAKE_PROJECT_KEY } from '@env';

const LBAirbrakeNotifier = new Notifier({
  projectId: AIRBRAKE_PROJECT_ID,
  projectKey: AIRBRAKE_PROJECT_KEY,
  environment: process.env.NODE_ENV,
});

export default LBAirbrakeNotifier;
