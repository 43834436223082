import { View, Text, TouchableOpacity } from 'react-native';
import React, { useState, useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { debounce } from 'lodash';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import { screen } from 'src/constants';
import { AssignRoomScreen } from 'src/screens/Room/routes';
import BottomModal from 'src/components/BottomModal';
import Button from 'src/components/Button';
import SyncIcon from 'src/components/SyncIcon';

export const testIds = {
  USER_CIRCLE: 'USER_CIRCLE',
};

const Container = styled(View)`
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  height: ${screen.SCREEN_HEIGHT_MAXED * 0.1}px;
  width: 100%;
  justify-content: center;
`;

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h2};
`;

const UserCircleContainer = styled(View)`
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${screen.SCREEN_WIDTH_MAXED * 0.06}px;
`;

const UserCircle = styled(TouchableOpacity)`
  background-color: ${({ theme }) => theme.colors.TERTIARY};
  border-radius: 50px;
  width: ${screen.SCREEN_WIDTH_MAXED * 0.09}px;
  height: ${screen.SCREEN_WIDTH_MAXED * 0.09}px;
  align-items: center;
  justify-content: center;
`;

const UserText = styled(Text)`
  ${({ theme }) => theme.textStyles.h2};
  font-size: ${screen.SCREEN_WIDTH_MAXED * 0.04}px;
  color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const TopHeader = observer(() => {
  const { hotelStore, userStore } = useStore();
  const navigation = useNavigation();
  const { authStore, offlineStore } = useStore();
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const { authenticatedUser } = userStore;
  const [menuVisible, setMenuVisible] = useState(false);
  const [isSyncing, setIsSyncing] = useState(
    offlineStore.actionsAsArray.length > 0
  );

  const setIsSyncingDebounce = debounce(() => {
    setIsSyncing(offlineStore.actionsAsArray.length > 0);
  }, 50);

  useEffect(() => {
    setIsSyncingDebounce();
  }, [offlineStore.actionsAsArray]);

  const firstNameLetter =
    authenticatedUser?.firstName?.charAt(0)?.toUpperCase() || '';
  const lastNameLetter =
    authenticatedUser?.lastName?.charAt(0)?.toUpperCase() || '';

  let authenticatedUserMenuItems = null;
  if (authenticatedUser) {
    authenticatedUserMenuItems = (
      <>
        <Button
          label={t(strings.ASSIGN_ROOMS_MENU_TEXT)}
          title={t(strings.ASSIGN_ROOMS_MENU_TEXT)}
          mode="text"
          onPress={() => {
            navigation.navigate(AssignRoomScreen);
            setMenuVisible(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <Container>
        <Title>{hotelStore.name}</Title>
        <UserCircleContainer>
          <UserCircle
            testID={testIds.USER_CIRCLE}
            onPress={() => {
              setMenuVisible(true);
            }}
          >
            <UserText>{`${firstNameLetter}${lastNameLetter}`}</UserText>
          </UserCircle>
        </UserCircleContainer>
        <SyncIcon
          isVisible={isSyncing}
          isRotating
          width={theme.screen.SCREEN_HEIGHT_MAXED * 0.035}
          height={theme.screen.SCREEN_HEIGHT_MAXED * 0.035}
          color={theme.colors.TERTIARY}
          containerStyle={{
            position: 'absolute',
            right: theme.screen.SCREEN_WIDTH_MAXED * 0.18,
          }}
        />
      </Container>

      <BottomModal
        isVisible={menuVisible}
        onClose={() => setMenuVisible(false)}
      >
        {authenticatedUserMenuItems}
        <Button
          label={t(strings.LOG_OUT_MENU_TEXT)}
          onPress={() => {
            authStore.logout();
            setMenuVisible(false);
          }}
          title={t(strings.LOG_OUT_MENU_TEXT)}
        />
      </BottomModal>
    </>
  );
});

export default TopHeader;
