import React, { useState, useEffect } from 'react';
import styled from 'styled-components/native';
import { View } from 'react-native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { useNavigation } from '@react-navigation/native';
import { useStore } from 'src/context/StoreContext';

import SelectActiveUserScreen from './SelectActiveUserScreen';
import ActiveUserScreen from './ActiveUserScreen';

const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  justify-content: space-between;
  padding-top: ${(props) => props.paddingTop || 0}px;
`;

const AssignRoomScreen = () => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const { roomAssignmentStore, roomStore, hotelStore } = useStore();
  const [activeUsers, setActiveUsers] = useState(
    roomAssignmentStore.activeUsers
  );

  useEffect(() => {
    roomStore.fetchAllRooms(hotelStore.selectedHotelId);
    roomAssignmentStore.fetchAllRoomAssignments(hotelStore.selectedHotelId);
  }, []);

  return (
    <Container paddingTop={insets.top}>
      {Object.keys(activeUsers).length > 0 ? (
        <ActiveUserScreen
          activeUsers={activeUsers}
          setActiveUsers={setActiveUsers}
        />
      ) : (
        <SelectActiveUserScreen
          activeUsers={activeUsers}
          setActiveUsers={setActiveUsers}
          onCancel={navigation.goBack}
        />
      )}
    </Container>
  );
};

export default AssignRoomScreen;
