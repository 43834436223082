import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { screen } from 'src/constants';
import { useNavigation } from '@react-navigation/native';
import { ThemeContext } from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import * as routes from 'src/screens/Room/routes';
import TaskIcon from 'assets/icons/task.svg';
import Button from 'src/components/Button';

const CREATE_BUTTON = 'CREATE_BUTTON';

const CreateTaskButton = ({ room }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();
  const navigation = useNavigation();

  const buttonContainerStyle = {
    marginTop: screen.SCREEN_WIDTH * 0.03,
    marginBottom: screen.SCREEN_WIDTH * 0.16,
    width: '90%',
    alignSelf: 'center',
  };

  return (
    <Button
      onPress={() =>
        navigation.push(routes.ChooseTaskScreen, {
          room,
        })
      }
      iconLabelLeft={
        <TaskIcon
          width={screen.SCREEN_WIDTH_MAXED * 0.05}
          height={screen.SCREEN_WIDTH_MAXED * 0.05}
          fill={theme.colors.PRIMARY_DARK}
          style={{ marginRight: 8 }}
        />
      }
      label={t(strings.CREATE_A_NEW_TASK_TEXT)}
      testID={CREATE_BUTTON}
      mode="outline"
      containerStyle={buttonContainerStyle}
      labelStyle={{ textTransform: 'none' }}
    />
  );
};

CreateTaskButton.propTypes = {
  room: PropTypes.object,
};

export default CreateTaskButton;
