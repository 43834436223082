import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { View, TouchableOpacity, Text } from 'react-native';

const Container = styled(TouchableOpacity)`
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: row;
  justify-content: center;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border-color: ${(props) => props.color};
  border-width: ${(props) => (props.hideBorder ? '0px' : '2px')};
  padding-horizontal: 20px;
  padding-vertical: 7px;
  width: 80%;
  border-radius: 16px;
  margin-vertical: 10px;
`;

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

const MenuItem = ({
  color,
  testID,
  title,
  icon,
  disabled = false,
  onPress,
  hideBorder,
}) => {
  return (
    <Container
      color={color}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      hideBorder={hideBorder}
    >
      <View style={{ flexDirection: 'row' }}>
        {icon &&
          React.cloneElement(icon, {
            width: 24,
            height: 24,
            marginRight: 15,
            fill: color,
          })}
        <Title style={{ color }}>{title}</Title>
      </View>
    </Container>
  );
};

MenuItem.propTypes = {
  testID: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  color: PropTypes.string,
  hideBorder: PropTypes.bool,
};

export default MenuItem;
