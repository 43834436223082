import moment from 'moment';
import { camelCase, snakeCase } from 'lodash';

export default (object, attributes) => {
  let attributeTimestamps = object?.attributeTimestamps || {};
  const camelCaseAttributeTimestamps = {};

  if (typeof attributeTimestamps === 'string') {
    attributeTimestamps = JSON.parse(attributeTimestamps);
  }

  attributes.forEach((attribute) => {
    const timestamp = snakeCase(`${attribute}_updated_at`);
    if (object[attribute] !== undefined && !attributeTimestamps[timestamp]) {
      const currentTime = moment().toISOString();
      attributeTimestamps[timestamp] = currentTime;
      camelCaseAttributeTimestamps[camelCase(timestamp)] = currentTime;
    }
  });

  if (Object.keys(attributeTimestamps).length > 0) {
    object.attributeTimestamps = JSON.stringify(attributeTimestamps);
  }

  return JSON.stringify(camelCaseAttributeTimestamps);
};
