import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { CLEAN_STATUS, COLORS } from 'src/models/RoomStore';
import RoomFilterItem from './RoomFilterItem';

export const DO_NOT_DISTURB = 'DO_NOT_DISTURB';
export const CLEAN = 'CLEAN';
export const DIRTY = 'DIRTY';

const ItemsTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.body2};
  color: ${({ theme }) => theme.colors.BLACK};
  margin-bottom: 8px;
`;

const RoomFilterCleanItems = ({
  localFilters,
  setCleanStatusFilter,
  setLocalFilters,
}) => {
  const { t } = useTranslation();

  return (
    <View>
      <ItemsTitle>{t(strings.CLEAN_STATUS)}</ItemsTitle>
      <RoomFilterItem
        testID={CLEAN}
        label={t(strings.CLEAN_FILTER_TEXT)}
        backgroundColor={COLORS.CLEAN}
        selected={localFilters.cleanStatus.includes(CLEAN_STATUS.CLEAN)}
        onPress={() => {
          setCleanStatusFilter(CLEAN_STATUS.CLEAN);
        }}
      />
      <RoomFilterItem
        testID={DIRTY}
        label={t(strings.DIRTY_FILTER_TEXT)}
        backgroundColor={COLORS.DIRTY}
        selected={localFilters.cleanStatus.includes(CLEAN_STATUS.DIRTY)}
        onPress={() => {
          setCleanStatusFilter(CLEAN_STATUS.DIRTY);
        }}
      />
      <RoomFilterItem
        testID={DO_NOT_DISTURB}
        label={t(strings.DO_NOT_DISTURB_FILTER_TEXT)}
        backgroundColor={COLORS.DO_NOT_DISTURB}
        selected={localFilters.doNotDisturb}
        onPress={() =>
          setLocalFilters({
            ...localFilters,
            doNotDisturb: !localFilters.doNotDisturb,
          })
        }
      />
    </View>
  );
};

RoomFilterCleanItems.propTypes = {
  localFilters: PropTypes.object,
  setCleanStatusFilter: PropTypes.func,
  setLocalFilters: PropTypes.func,
};

export default RoomFilterCleanItems;
