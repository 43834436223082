import React from 'react';
import { FlatList, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { USER_TYPE } from 'src/models/UserStore';
import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import { screen } from 'src/constants';
import { CLEAN_STATUS } from 'src/models/RoomStore';
import InspectionCard from './InspectionCard';
import EmptyRoomDetails from './EmptyRoomDetails';
import CreateTaskButton from './CreateTaskButton';
import TaskCard from '../../../../components/TaskCard';
import { ChosenTaskScreen } from '../../routes';

const RoomDetailsTaskList = observer(
  ({
    setSelectedTask,
    listId,
    tasksForRoom,
    room,
    statusSelected,
    userType,
  }) => {
    const navigation = useNavigation();
    const { t } = useTranslation();

    let emptyState;

    if (
      tasksForRoom.length === 0 &&
      (room.cleanStatus !== CLEAN_STATUS.SHOULD_INSPECT ||
        userType === USER_TYPE.HOUSEKEEPER)
    ) {
      emptyState = <EmptyRoomDetails text={t(strings.NO_TASKS_TEXT)} />;
    }

    let inspectionState;

    if (
      room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT &&
      statusSelected === INCOMPLETE_STATUS &&
      userType !== USER_TYPE.HOUSEKEEPER
    ) {
      inspectionState = <InspectionCard room={room} />;
    }

    return (
      <View style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          {emptyState || null}
          {inspectionState || null}
          <FlatList
            contentContainerStyle={{
              paddingBottom: screen.SCREEN_WIDTH * 0.11,
            }}
            testID={listId}
            data={tasksForRoom}
            keyExtractor={({ id }) => id.toString()}
            renderItem={({ item: task }) => (
              <TaskCard
                task={task}
                onDialogOpen={() => {
                  setSelectedTask(task);
                }}
                onEdit={() => {
                  navigation.navigate(ChosenTaskScreen, {
                    taskId: task.id,
                  });
                }}
              />
            )}
          />
        </View>

        {statusSelected === INCOMPLETE_STATUS ? (
          <View
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              justifyContent: 'center',
            }}
          >
            <CreateTaskButton room={room} />
          </View>
        ) : null}
      </View>
    );
  }
);

RoomDetailsTaskList.propTypes = {
  setSelectedTask: PropTypes.func,
  listId: PropTypes.string,
  statusSelected: PropTypes.string,
  tasksForRoom: PropTypes.arrayOf(PropTypes.object),
  room: PropTypes.object,
};

export default RoomDetailsTaskList;
