import { View, Text } from 'react-native';
import styled from 'styled-components/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { screen } from 'src/constants';

export const CreateTaskContainer = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

export const Header = styled(View)`
  height: ${screen.SCREEN_HEIGHT * 0.14}px;
  padding-top: ${screen.SCREEN_HEIGHT * 0.08}px;
  display: flex;
  padding-left: 25px;
  justify-content: center;
  position: relative;
`;

export const BottomContainer = styled(View)`
  width: 100%;
  height: ${screen.SCREEN_HEIGHT * 0.11}px;
  position: absolute;
  bottom: 0;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  justify-content: center;
  align-items: center;
`;

export const HeaderTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
`;

export const RoomNumberText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

export const Content = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  max-height: ${screen.SCREEN_HEIGHT * 0.85}px;
`;

export const ChosenTaskContent = styled(KeyboardAwareScrollView).attrs(() => ({
  keyboardShouldPersistTaps: 'handled',
  keyboardDismissMode: 'on-drag',
  overScrollMode: 'always',
  contentContainerStyle: {
    justifyContent: 'flex-start',
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 150,
  },
}))`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;
