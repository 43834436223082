import React, { useContext } from 'react';
import { TouchableOpacity, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';

import CheckIcon from 'assets/icons/check.svg';

const Container = styled(View)`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  border-width: 2px;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  border-color: ${({ theme, isChecked, checkedColor }) =>
    isChecked ? checkedColor : theme.colors.TERTIARY};
`;

const ContentContainer = styled(View)`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  opacity: ${({ isChecked }) => (isChecked ? 1 : 0)};
`;

const Checkbox = ({
  isChecked,
  onPress,
  width = 20,
  height = 20,
  color,
} = {}) => {
  const theme = useContext(ThemeContext);
  color = color || theme.colors.PRIMARY;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        padding: 10,
      }}
    >
      <Container
        width={width}
        height={height}
        isChecked={isChecked}
        checkedColor={color}
      >
        <ContentContainer isChecked={isChecked} color={color}>
          <CheckIcon
            width={width * 0.65}
            height={height * 0.65}
            fill={theme.colors.SECONDARY}
          />
        </ContentContainer>
      </Container>
    </TouchableOpacity>
  );
};

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onPress: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

export default Checkbox;
