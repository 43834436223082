import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import Modal from 'react-native-modal';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import Button from 'src/components/Button';

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;
const Prompt = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const TitleContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Container = styled(View)`
  justify-content: space-evenly;
  align-items: center;
  height: 33%;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  padding: 15px;
`;

const CenterContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const BottomContainer = styled(View)`
  flex: 1;
  align-items: center;
  flex-direction: row;
`;

const OkButton = styled(Button)`
  flex: 4;
`;

const CancelButton = styled(Button)`
  flex: 6;
`;

const ConfirmationModel = ({
  isVisible,
  onCancel,
  onOk,
  title,
  prompt,
  okButtonText,
  cancelButtonText,
  hideCancelButton,
  hideOkButton,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isVisible={isVisible} backdropOpacity={0.6} useNativeDriver>
      <Container testID="ConfirmationModel">
        {isVisible ? (
          <>
            <TitleContainer>
              <Title>{title}</Title>
            </TitleContainer>
            <CenterContainer>
              <Prompt>{prompt}</Prompt>
            </CenterContainer>

            <BottomContainer>
              {!hideOkButton ? (
                <OkButton
                  testID="CONFIRMATION_MODAL_OK"
                  onPress={onOk}
                  mode="text"
                  label={okButtonText || t(strings.OK)}
                />
              ) : null}
              {!hideCancelButton ? (
                <CancelButton
                  testID="CONFIRMATION_MODAL_CANCEL"
                  onPress={onCancel}
                  label={cancelButtonText || t(strings.CANCEL)}
                />
              ) : null}
            </BottomContainer>
          </>
        ) : null}
      </Container>
    </Modal>
  );
};

ConfirmationModel.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  title: PropTypes.string,
  prompt: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  hideCancelButton: PropTypes.bool,
  hideOkButton: PropTypes.bool,
};

export default ConfirmationModel;
