import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { CLEAN_STATUS, VACANCY_STATUS } from 'src/models/RoomStore';
import Button from 'src/components/Button';
import BlockedIcon from 'assets/icons/blocked.svg';
import CleanIcon from 'assets/icons/clean.svg';
import DirtyIcon from 'assets/icons/dirty.svg';
import DownArrowIcon from 'assets/icons/down_arrow.svg';
import UpArrowIcon from 'assets/icons/up_arrow.svg';
import MenuBarExpandedContent from './MenuBarExpandedContent';

const Container = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  height: 350px;
  flex-direction: column;
  align-items: center;
  z-index: 2;
`;

const TopContainer = styled(View)`
  height: 100px;
  flex-direction: column;
  align-items: center;
  background-color: ${(props) => props.containerColor};
  width: 100%;
  margin-bottom: 20px;
`;

const BarThumb = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_OPAQUE};
  height: 5px;
  border-radius: 10px;
  width: 15%;
  margin-vertical: 10px;
`;

const MainText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  color: ${({ theme }) => theme.colors.TERTIARY};
  margin-right: 10px;
`;

const RoomStatusButton = styled(Button)`
  padding-horizontal: 15px;
  border-radius: 16px;
  flex-direction: row;
  background-color: ${(props) => props.buttonColor};
`;

const MenuBarContent = ({
  isSheetOpen,
  setIsSheetOpen,
  changeSnapPoints,
  cleanStatus,
  room,
  updateRoom,
  userType,
  navigation,
}) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const handleSheetOpen = () => {
    setIsSheetOpen(!isSheetOpen);
    changeSnapPoints();
  };

  const getRoomStatus = () => {
    let labelText;
    let labelColor;
    let buttonColor;
    let statusIcon;
    let menuBarBackgroundColor;
    if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
      labelText = t(strings.ROOM_BLOCKED_TEXT);
      labelColor = theme.colors.BACKGROUND_LIGHT;
      buttonColor = theme.colors.RED;
      menuBarBackgroundColor = theme.colors.BACKGROUND_LIGHT_RED;
      statusIcon = BlockedIcon;
      return {
        statusIcon,
        labelText,
        labelColor,
        buttonColor,
        menuBarBackgroundColor,
      };
    }

    switch (cleanStatus) {
      case CLEAN_STATUS.CLEAN:
        labelText = t(strings.ROOM_DETAILS_HEADER_CLEAN);
        labelColor = theme.colors.BACKGROUND_LIGHT;
        buttonColor = theme.colors.PRIMARY;
        menuBarBackgroundColor = theme.colors.BACKGROUND_LIGHT_PRIMARY;
        statusIcon = CleanIcon;
        break;
      case CLEAN_STATUS.DIRTY:
        labelText = t(strings.ROOM_DETAILS_HEADER_DIRTY);
        labelColor = theme.colors.BACKGROUND_LIGHT;
        buttonColor = theme.colors.ORANGE;
        menuBarBackgroundColor = theme.colors.BACKGROUND_LIGHT_YELLOW;
        statusIcon = DirtyIcon;
        break;
      case CLEAN_STATUS.SHOULD_INSPECT:
        labelText = t(strings.ROOM_DETAILS_HEADER_INSPECT);
        labelColor = theme.colors.BACKGROUND_LIGHT;
        buttonColor = theme.colors.BLUE;
        menuBarBackgroundColor = theme.colors.BACKGROUND_LIGHT_BLUE;
        statusIcon = CleanIcon;
        break;
      default:
        break;
    }
    return {
      statusIcon,
      labelText,
      labelColor,
      buttonColor,
      menuBarBackgroundColor,
    };
  };

  const IconLeftIcon = getRoomStatus().statusIcon;

  return (
    <Container>
      <TopContainer containerColor={getRoomStatus().menuBarBackgroundColor}>
        <BarThumb />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
          }}
        >
          <MainText>{t(strings.MENU_BAR_TEXT)} </MainText>
          <RoomStatusButton
            label={getRoomStatus().labelText}
            buttonColor={getRoomStatus().buttonColor}
            onPress={handleSheetOpen}
            iconLabelLeft={
              <IconLeftIcon
                width={screen.SCREEN_WIDTH_MAXED * 0.05}
                height={screen.SCREEN_WIDTH_MAXED * 0.05}
                fill={getRoomStatus().labelColor}
                style={{ marginRight: 8 }}
              />
            }
            iconLabelRight={
              isSheetOpen ? (
                <UpArrowIcon
                  width={screen.SCREEN_WIDTH_MAXED * 0.03}
                  height={screen.SCREEN_WIDTH_MAXED * 0.03}
                  fill={getRoomStatus().labelColor}
                  style={{ marginLeft: 8 }}
                />
              ) : (
                <DownArrowIcon
                  width={screen.SCREEN_WIDTH_MAXED * 0.03}
                  height={screen.SCREEN_WIDTH_MAXED * 0.03}
                  fill={getRoomStatus().labelColor}
                  style={{ marginLeft: 8 }}
                />
              )
            }
            labelStyle={{
              color: getRoomStatus().labelColor,
              textTransform: 'none',
            }}
          />
        </View>
      </TopContainer>

      {isSheetOpen ? (
        <MenuBarExpandedContent
          userType={userType}
          room={room}
          updateRoom={updateRoom}
          handleSheetOpen={handleSheetOpen}
          navigation={navigation}
        />
      ) : null}
    </Container>
  );
};

MenuBarContent.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
  }).isRequired,
  isSheetOpen: PropTypes.bool,
  setIsSheetOpen: PropTypes.func,
  changeSnapPoints: PropTypes.func,
  cleanStatus: PropTypes.string,
  updateRoom: PropTypes.func,
  userType: PropTypes.string,
  navigation: PropTypes.object,
};

export default MenuBarContent;
