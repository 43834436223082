import React, { useState } from 'react';
import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useRoute } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import TopHeader from 'src/screens/Room/components/TopHeader';
import RoomFilterCleanItems from './components/RoomFilterCleanItems';
import RoomFilterVacancyItems from './components/RoomFilterVacancyItems';
import RoomFilterFooter from './components/RoomFilterFooter';
import RoomFilterItem from './components/RoomFilterItem';

export const ASSIGNED_TO_ME_TEST_ID = 'ASSIGNED_TO_ME_TEST_ID';

const Container = styled(SafeAreaView).attrs(() => ({
  edges: ['top', 'left', 'right'],
}))`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const ContentContainer = styled(View)`
  flex: 19;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
`;

const BorderTop = styled(View)`
  border-top-color: rgba(0, 0, 0, 0.2);
  border-top-width: 1.5px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  width: 100%;
  position: absolute;
  top: 0px;
  z-index: 10;
`;

const RoomFilterScreen = () => {
  const route = useRoute();
  const { t } = useTranslation();

  const { filters, setFilters } = route.params;
  const [localFilters, setLocalFilters] = useState(filters);
  const [isDisabled, setIsDisabled] = useState(true);

  const setVacancyStatusFilter = (value) => {
    setIsDisabled(false);
    let newVacancyStatuses = localFilters.vacancyStatus;
    if (localFilters.vacancyStatus.includes(value)) {
      newVacancyStatuses = newVacancyStatuses.filter(
        (status) => status !== value
      );
    } else {
      newVacancyStatuses = [...localFilters.vacancyStatus, value];
    }

    setLocalFilters({
      ...localFilters,
      vacancyStatus: newVacancyStatuses,
    });
  };

  const setCleanStatusFilter = (value) => {
    setIsDisabled(false);
    let newCleanStatuses = localFilters.cleanStatus;
    if (localFilters.cleanStatus.includes(value)) {
      newCleanStatuses = newCleanStatuses.filter((status) => status !== value);
    } else {
      newCleanStatuses = [...localFilters.cleanStatus, value];
    }

    setLocalFilters({
      ...localFilters,
      cleanStatus: newCleanStatuses,
    });
  };

  return (
    <Container>
      <TopHeader />
      <ContentContainer>
        <BorderTop />
        <ScrollView style={{ padding: 16 }}>
          <RoomFilterItem
            label={t(strings.ASSIGNED_TO_ME_FILTER_TEXT)}
            testID={ASSIGNED_TO_ME_TEST_ID}
            selected={!!localFilters.assignedToMe}
            onPress={() => {
              setIsDisabled(false);
              setLocalFilters({
                ...localFilters,
                assignedToMe: !localFilters.assignedToMe,
              });
            }}
          />
          <RoomFilterVacancyItems
            setIsDisabled={setIsDisabled}
            setVacancyStatusFilter={setVacancyStatusFilter}
            localFilters={localFilters}
          />
          <RoomFilterCleanItems
            setIsDisabled={setIsDisabled}
            setCleanStatusFilter={setCleanStatusFilter}
            setLocalFilters={setLocalFilters}
            localFilters={localFilters}
          />
        </ScrollView>
      </ContentContainer>

      <RoomFilterFooter
        isDisabled={isDisabled}
        setFilters={setFilters}
        localFilters={localFilters}
      />
    </Container>
  );
};

export default RoomFilterScreen;
