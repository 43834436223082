import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep, merge } from 'lodash';
import i18n from 'i18next';

import { request } from 'src/utils/LodgebookAPIClient';
import addAttributeTimestamps from 'src/utils/addAttributeTimestamps';
import { strings } from 'src/constants/i18n';

export const TASKS_URL = '/tasks';
export const CREATE_TASK_URL = '/tasks';
export const PATCH_TASK_URL = '/tasks/';
export const INCOMPLETE_STATUS = 'incomplete';
export const COMPLETE_STATUS = 'complete';
export const LATER_STATUS = 'later';

export const TaskAssignment = types.model('TaskAssignment', {
  id: types.identifierNumber,
  userId: types.number,
  taskId: types.string,
});

export const Task = types.model('Task', {
  id: types.identifier,
  roomId: types.maybeNull(types.number),
  description: types.frozen({
    english: types.string,
    spanish: types.string,
    chinese: types.string,
  }),
  status: types.enumeration('TaskStatus', [
    INCOMPLETE_STATUS,
    COMPLETE_STATUS,
    LATER_STATUS,
  ]),
  completedAt: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  assignedAt: types.maybeNull(types.string),
  taskAssignments: types.array(TaskAssignment),
  urgent: types.optional(types.boolean, false),
  additionalNotes: types.maybeNull(types.string),
  createdByUserId: types.maybeNull(types.number),
  isSyncing: types.optional(types.boolean, false),
  isLocal: types.optional(types.boolean, false),
  attributeTimestamps: types.maybeNull(types.string),
});

const TaskStore = types
  .model('TaskStore', {
    tasks: types.optional(types.map(Task), {}),
    tasksByDescription: types.optional(types.map(Task), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingOne: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get tasksAsArray() {
      return values(self.tasks);
    },
    get tasksByDescriptionAsArray() {
      return values(self.tasksByDescription);
    },
    get completedTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      return self.tasksAsArray.filter(
        (task) =>
          task.status === COMPLETE_STATUS &&
          timeZone &&
          task.completedAt &&
          moment(task.completedAt)
            .tz(timeZone)
            .isSame(moment().tz(timeZone), 'day')
      );
    },
    get incompleteTasks() {
      return self.tasksAsArray.filter(
        (task) => task.status === INCOMPLETE_STATUS
      );
    },
  }))
  .actions((self) => ({
    fetchAllTasks: flow(function*(hotelId) {
      self.isFetchingAll = true;
      try {
        const taskResponse = yield request(
          `${TASKS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.tasks = {};
        taskResponse.tasks.forEach((task) => {
          /* 
            Compare the timestamp for each changed attribute,
            If the local timestamp for an attribute is newer,
            That means that the local data is the newest and should not be overwritten
          */
          task.id = `${task.id}`;
          task.taskAssignments = task.taskAssignments.map((taskAssignment) => {
            taskAssignment.taskId = `${taskAssignment.taskId}`;
            return taskAssignment;
          });

          const localTask = self.tasks.get(task.id);
          if (task?.attributeTimestamps && localTask?.isSyncing) {
            const localTimestamps = JSON.parse(localTask?.attributeTimestamps);
            Object.keys(localTimestamps).forEach((key) => {
              const attribute = key.replace('updatedAt', '');
              const localTimestamp = localTimestamps[key];
              const timestamp = task?.attributeTimestamps[key];
              if (moment(localTimestamp).isSameOrAfter(moment(timestamp))) {
                // Replace the attribute with the old attribute
                task[attribute] = localTask[attribute];
                task.attributeTimestamps[key] = localTimestamp;
              }
            });
          }
          task.attributeTimestamps =
            JSON.stringify(task?.attributeTimestamps) || null;

          self.tasks.set(task.id, task);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch tasks', error);
      }
      self.isFetchingAll = false;
    }),
    fetchTasksByDescription: flow(function*(hotelId, description) {
      self.isFetchingAll = true;
      try {
        const taskResponse = yield request(
          `/tasks_description?hotel_id=${hotelId}&description=${description}`,
          'GET'
        );
        self.tasksByDescription = {};
        taskResponse.tasks.forEach((task) => {
          /* 
            Compare the timestamp for each changed attribute,
            If the local timestamp for an attribute is newer,
            That means that the local data is the newest and should not be overwritten
          */
          task.id = `${task.id}`;

          const localTask = self.tasksByDescription.get(task.id);
          if (task?.attributeTimestamps && localTask?.isSyncing) {
            const localTimestamps = JSON.parse(localTask?.attributeTimestamps);
            Object.keys(localTimestamps).forEach((key) => {
              const attribute = key.replace('updatedAt', '');
              const localTimestamp = localTimestamps[key];
              const timestamp = task?.attributeTimestamps[key];
              if (moment(localTimestamp).isSameOrAfter(moment(timestamp))) {
                // Replace the attribute with the old attribute
                task[attribute] = localTask[attribute];
                task.attributeTimestamps[key] = localTimestamp;
              }
            });
          }
          task.attributeTimestamps =
            JSON.stringify(task?.attributeTimestamps) || null;

          self.tasksByDescription.set(task.id, task);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
      }
      self.isFetchingAll = false;
    }),
    fetchTask: flow(function*(taskId) {
      try {
        const { task, taskAssignments } = yield request(
          `${TASKS_URL}/${taskId}`,
          'GET'
        );
        task.taskAssignments = taskAssignments.map((taskAssignment) => {
          taskAssignment.taskId = `${taskAssignment.taskId}`;
          return taskAssignment;
        });
        task.id = `${task.id}`;

        const localTask = self.tasks.get(task.id);
        if (task?.attributeTimestamps && localTask?.isSyncing) {
          const localTimestamps = JSON.parse(localTask?.attributeTimestamps);
          Object.keys(localTimestamps).forEach((key) => {
            const attribute = key.replace('updatedAt', '');
            const localTimestamp = localTimestamps[key];
            const timestamp = task?.attributeTimestamps[key];
            if (moment(localTimestamp).isSameOrAfter(moment(timestamp))) {
              // Replace the attribute with the old attribute
              task[attribute] = localTask[attribute];
              task.attributeTimestamps[key] = localTimestamp;
            }
          });
        }
        task.attributeTimestamps =
          JSON.stringify(task?.attributeTimestamps) || null;

        self.tasks.set(task.id, task);
      } catch (error) {
        console.log(error);
      }
    }),
    patchTask: flow(function*({ taskId, options }) {
      taskId = `${taskId}`;
      self.isFetchingOne = true;

      try {
        // Create a local task that matches what the server would respond with
        const localTask = cloneDeep(self.tasks.get(taskId));
        merge(localTask, options.body.task);
        localTask.isSyncing = true;

        localTask.attributeTimestamps = addAttributeTimestamps(
          options.body.task,
          ['description', 'urgent', 'status', 'additionalNotes']
        );

        if (localTask.userIds) {
          localTask.assignedAt = moment().toISOString();
          localTask.taskAssignments = localTask.userIds.map((userId) => ({
            id: userId,
            taskId,
            userId,
          }));
          delete localTask.userIds;
        }
        if (localTask.status === COMPLETE_STATUS) {
          localTask.completedAt = moment().toISOString();
        } else {
          localTask.completedAt = null;
        }
        self.tasks.set(taskId, localTask);

        /* Don't make an actual request if the local task has a uuid
          This means that the task was created offline
          The changes made will be included in the task creation  
        */
        if (!self.tasks.get(taskId).isLocal) {
          const optionsToSend = cloneDeep(options);

          if (optionsToSend.body.task?.description) {
            optionsToSend.body.task.description = JSON.stringify(
              optionsToSend.body.task.description
            );
          }

          const taskResponse = yield request(
            `${PATCH_TASK_URL}${taskId}`,
            'PATCH',
            optionsToSend
          );

          taskResponse.task.id = `${taskResponse.task.id}`;
          taskResponse.task.attributeTimestamps =
            JSON.stringify(taskResponse.task?.attributeTimestamps) || null;
          self.tasks.set(taskResponse.task.id, {
            ...taskResponse.task,
            taskAssignments: taskResponse.taskAssignments.map(
              (taskAssignment) => {
                taskAssignment.taskId = `${taskAssignment.taskId}`;
                return taskAssignment;
              }
            ),
          });
        } else {
          throw new Error(
            'task was created offline - updated createTask action args'
          );
        }
      } catch (error) {
        console.warn('Failed to update task', error);
        /* 
          If the task that was updated was also created offline,
          then we want to update the offlineStore action that creates that task
         */
        if (
          error
            .toString()
            .toLowerCase()
            .includes('task was created offline')
        ) {
          const modifiedOptions = cloneDeep(options);
          delete modifiedOptions.body.task?.attributeTimestamps;

          /*
            There are two ways to create a task,
             1. create a single task via createTask
             2. create bulk tasks via createTasks (with a s)
            To determine if it was created as a single task or in bulk, 
            we check if the offlineStore contains an action for the single createTask or not 
          */
          if (
            !getRoot(self).offlineStore.actions.get(
              `/taskStore-createTask-${taskId}`
            )
          ) {
            const action = {
              args: [
                {
                  options: {
                    body: {
                      tasks: [
                        {
                          ...modifiedOptions.body.task,
                          id: taskId,
                        },
                      ],
                    },
                  },
                },
              ],
              name: 'createTasks',
              path: '/taskStore',
            };
            getRoot(self).offlineStore.upsertAction({ action });
          } else {
            const action = {
              args: [{ taskId, options: modifiedOptions }],
              name: 'createTask',
              path: '/taskStore',
            };
            getRoot(self).offlineStore.upsertAction({ id: taskId, action });
          }
        } else if (
          error
            .toString()
            .toLowerCase()
            .includes('network request failed')
        ) {
          /**
           * If the network request failed, then make an action for patching this task
           */
          const action = {
            args: [{ taskId, options }],
            name: 'patchTask',
            path: '/taskStore',
          };

          getRoot(self).offlineStore.upsertAction({ id: taskId, action });
        } else {
          /**
           * Else, its a problem with the app
           */
          const localTask = self.tasks.get(taskId);
          self.tasks.set(taskId, {
            ...localTask,
            isSyncing: false,
          });
          getRoot(self).notificationStore.createNotification({
            text: i18n.t(strings.SOMETHING_WENT_WRONG),
          });
        }
      }
      self.isFetchingOne = false;
    }),
    createTask: flow(function*({ taskId, options }) {
      const {
        hotelStore: { selectedHotelId },
      } = getRoot(self);
      taskId = taskId || uuidv4();

      self.isFetchingOne = true;
      try {
        const modifiedTask = {
          hotelId: selectedHotelId,
          status: INCOMPLETE_STATUS,
          ...options.body.task,
        };

        if (!self.tasks.get(taskId)) {
          /*
            Create a new local task that matches the server's response
          */
          const localTask = cloneDeep(modifiedTask);
          localTask.isSyncing = true;
          localTask.isLocal = true;
          localTask.id = taskId;
          localTask.createdAt = moment().toISOString();

          if (localTask.userIds) {
            localTask.assignedAt = moment()
              .toDate()
              .toUTCString();

            localTask.taskAssignments = localTask.userIds.map((userId) => ({
              id: userId,
              taskId,
              userId,
            }));
            delete localTask.userIds;
          }
          self.tasks.set(taskId, localTask);
        }
        const taskResponse = yield request(CREATE_TASK_URL, 'POST', {
          ...options,
          body: {
            task: {
              ...modifiedTask,
              description: JSON.stringify(modifiedTask.description),
            },
          },
        });
        const { task } = taskResponse;
        task.id = `${task.id}`;
        task.taskAssignments = taskResponse.taskAssignments.map(
          (taskAssignment) => {
            taskAssignment.taskId = `${taskAssignment.taskId}`;
            return taskAssignment;
          }
        );
        task.attributeTimestamps =
          JSON.stringify(task?.attributeTimestamps) || null;
        self.tasks.set(task.id, task);
        self.tasks.delete(taskId);
      } catch (error) {
        console.warn('Failed to create task', error);

        /**
         * If the network request failed, create the same action and store it in the offlineStore
         */
        if (
          error
            .toString()
            .toLowerCase()
            .includes('network request failed')
        ) {
          const action = {
            args: [{ taskId, options }],
            name: 'createTask',
            path: '/taskStore',
          };

          getRoot(self).offlineStore.upsertAction({ id: taskId, action });
        } else {
          /**
           * Else, there is a problem with the app
           * Revert the changes by deleting the local task
           */
          self.tasks.delete(taskId);
          getRoot(self).notificationStore.createNotification({
            text: i18n.t(strings.SOMETHING_WENT_WRONG),
          });
        }
      }
      self.isFetchingOne = false;
    }),
    createTasks: flow(function*({ options }) {
      const {
        hotelStore: { selectedHotelId },
      } = getRoot(self);

      const tasksToDelete = [];
      try {
        const modifiedTasks = options.body.tasks.map((task) => {
          if (!task?.id) {
            task.id = uuidv4();
          }
          tasksToDelete.push(task.id);

          return {
            hotelId: selectedHotelId,
            status: INCOMPLETE_STATUS,
            ...task,
          };
        });
        /**
         * Create the local tasks
         */
        modifiedTasks.forEach((task) => {
          const localTask = cloneDeep(task);
          localTask.isSyncing = true;
          localTask.isLocal = true;
          localTask.createdAt = moment().toISOString();

          if (localTask?.userIds) {
            localTask.assignedAt = moment().toISOString();

            localTask.taskAssignments = localTask.userIds.map((userId) => ({
              id: userId,
              taskId: task.id,
              userId,
            }));

            delete localTask.userIds;
          }

          self.tasks.set(task.id, localTask);

          delete task.id;
          task.description = JSON.stringify(task.description);
        });

        const tasksResponse = yield request(CREATE_TASK_URL, 'PUT', {
          ...options,
          body: {
            tasks: modifiedTasks,
          },
        });

        const { tasks } = tasksResponse;

        tasks.forEach(({ task, taskAssignments }) => {
          task.id = `${task.id}`;
          task.taskAssignments = taskAssignments.map((taskAssignment) => {
            taskAssignment.taskId = `${taskAssignment.taskId}`;
            return taskAssignment;
          });
          task.attributeTimestamps =
            JSON.stringify(task?.attributeTimestamps) || null;
          self.tasks.set(task.id, task);
        });

        tasksToDelete.forEach((taskId) => {
          self.tasks.delete(taskId);
        });
      } catch (error) {
        if (
          error
            .toString()
            .toLowerCase()
            .includes('network request failed')
        ) {
          const action = {
            args: [{ options }],
            name: 'createTasks',
            path: '/taskStore',
          };

          getRoot(self).offlineStore.upsertAction({ action });
        } else {
          tasksToDelete.forEach((taskId) => {
            self.tasks.delete(taskId);
          });
          getRoot(self).notificationStore.createNotification({
            text: i18n.t(strings.SOMETHING_WENT_WRONG),
          });
        }
      }
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default TaskStore;
