import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';

import { request } from 'src/utils/LodgebookAPIClient';

export const ROOM_ASSIGNMENTS_URL = '/room_assignments';

export const RoomAssignment = types.model('RoomAssignment', {
  id: types.identifierNumber,
  roomId: types.number,
  userId: types.number,
  clearedAt: types.maybeNull(types.string),
});

const RoomAssignmentStore = types
  .model('RoomAssignmentStore', {
    roomAssignments: types.optional(types.map(RoomAssignment), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingOne: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get roomAssignmentsAsArray() {
      return values(self.roomAssignments);
    },

    get activeRoomAssignments() {
      return self.roomAssignmentsAsArray.filter((roomAssignment) => {
        return !roomAssignment.clearedAt;
      });
    },

    get activeUsers() {
      const userIds = self.activeRoomAssignments.map((roomAssignment) => {
        return roomAssignment.userId;
      });

      const root = getRoot(self);
      const users = root.userStore.usersAsArray.filter((user) => {
        return userIds.includes(user.id);
      });
      return users.reduce((map, user) => {
        map[user.id] = user;
        return map;
      }, {});
    },
    getRoomAssignmentsForUserAsArray(userId) {
      return self.activeRoomAssignments.filter(
        (roomAssignment) => roomAssignment.userId === userId
      );
    },
    getRoomAssignmentsForOtherUsersAsArray(userId) {
      return self.activeRoomAssignments.filter(
        (roomAssignment) => roomAssignment.userId !== userId
      );
    },
  }))
  .actions((self) => ({
    fetchAllRoomAssignments: flow(function*(hotelId) {
      self.isFetchingAll = true;
      try {
        const roomAssignmentsRequest = yield request(
          `${ROOM_ASSIGNMENTS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.roomAssignments = {};
        roomAssignmentsRequest.roomAssignments.forEach((roomAssignment) => {
          self.roomAssignments.set(roomAssignment.id, roomAssignment);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.warn('Failed to fetch room assignments', error);
      }
      self.isFetchingAll = false;
    }),
    updateRoomAssignments: flow(function*({ roomAssignments, signal }) {
      self.isFetchingOne = true;
      const root = getRoot(self);
      const hotelId = root.hotelStore.selectedHotelId;
      try {
        const updatedRoomAssignmentsResponse = yield request(
          `${ROOM_ASSIGNMENTS_URL}?hotel_id=${hotelId}`,
          'PUT',
          {
            body: {
              roomAssignments,
            },
            signal,
          }
        );
        updatedRoomAssignmentsResponse.roomAssignments.forEach(
          (updatedRoomAssignment) => {
            self.roomAssignments.set(updatedRoomAssignment.id, {
              ...updatedRoomAssignment,
            });
          }
        );
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.warn('Failed to update room assignments', error);
      }
      self.isFetchingOne = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default RoomAssignmentStore;
