import { types, flow, applySnapshot } from 'mobx-state-tree';
import AsyncStorage from '@react-native-async-storage/async-storage';

import RoomStore from './RoomStore';
import HotelStore from './HotelStore';
import TaskStore from './TaskStore';
import UserStore from './UserStore';
import AuthStore from './AuthStore';
import RoomAssignmentStore from './RoomAssignmentStore';
import HotelTaskStore from './HotelTaskStore';
import NotificationStore from './NotificationStore';
import OfflineStore from './OfflineStore';

const Store = types
  .model('Store', {
    authStore: types.optional(AuthStore, {}),
    roomStore: types.optional(RoomStore, {}),
    taskStore: types.optional(TaskStore, {}),
    userStore: types.optional(UserStore, {}),
    hotelStore: types.optional(HotelStore, {}),
    notificationStore: types.optional(NotificationStore, {}),
    roomAssignmentStore: types.optional(RoomAssignmentStore, {}),
    hotelTaskStore: types.optional(HotelTaskStore, {}),
    offlineStore: types.optional(OfflineStore, {}),
  })
  .views((self) => ({
    get isAnyFetchLoading() {
      return (
        self.roomStore.isFetchingAll ||
        self.taskStore.isFetchingAll ||
        self.userStore.isFetchingAll ||
        self.hotelTaskStore.isFetchingAll ||
        self.userStore.isFetchingAuthenticatedUser
      );
    },
    get networkErrors() {
      return [
        self.roomStore.networkError,
        self.hotelTaskStore.networkError,
        self.taskStore.networkError,
        self.userStore.networkError,
      ].filter((e) => e && e !== '');
    },
  }))
  .actions((self) => ({
    dismissErrors() {
      self.taskStore.dismissNetworkError();
      self.roomStore.dismissNetworkError();
      self.userStore.dismissNetworkError();
      self.hotelTaskStore.dismissNetworkError();
    },
    fetchAll: flow(function*() {
      if (!self.hotelStore.selectedHotelId) {
        yield self.hotelStore.fetchAndSetHotel();
      }
      self.roomStore.fetchAllRooms(self.hotelStore.selectedHotelId);
      self.taskStore.fetchAllTasks(self.hotelStore.selectedHotelId);
      self.userStore.fetchAllUsers(self.hotelStore.selectedHotelId);
      self.hotelTaskStore.fetchAllHotelTasks(self.hotelStore.selectedHotelId);
      self.userStore.fetchAuthenticatedUser(self.hotelStore.selectedHotelId);
      self.roomAssignmentStore.fetchAllRoomAssignments(
        self.hotelStore.selectedHotelId
      );
    }),
    resetStore() {
      applySnapshot(self, {});
    },
    afterCreate: flow(function*() {
      const existingSnapshot = JSON.parse(
        yield AsyncStorage.getItem('mst-snapshot')
      );

      if (Store.is(existingSnapshot)) {
        applySnapshot(self, existingSnapshot);
      }
    }),
  }));

export default Store;
