import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';

import styledComponentsTheme from 'src/constants/styledComponentsTheme';

const Container = styled(View)`
  flex: 1;
  background-color: ${styledComponentsTheme.colors.BACKGROUND_OPAQUE};
`;

const LoadingScreen = () => {
  return <Container />;
};

export default LoadingScreen;
