import React from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import styled from 'styled-components/native';
import { RadioButton } from 'react-native-paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';

const SortItemContainer = styled(View)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const SortText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
`;

const SortTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
  margin-bottom: 20px;
`;

const SortItem = styled(TouchableOpacity)`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const SortContent = ({ sortOption, setSortOption, setMenuVisible }) => {
  const ROOM_SORT_VALUE = 'room';
  const RECENT_SORT_VALUE = 'recent';
  const DESCRIPTION_SORT_VALUE = 'description';
  const { t } = useTranslation();

  return (
    <Container>
      <SortTitle>{t(strings.SORT_TASKS_TEXT)}</SortTitle>
      <SortItemContainer>
        <SortItem
          onPress={() => {
            setSortOption(ROOM_SORT_VALUE);
            setMenuVisible(false);
          }}
        >
          <SortText>{t(strings.ROOM_SORT_TEXT)}</SortText>
          <RadioButton.Android
            onPress={() => {
              setSortOption(ROOM_SORT_VALUE);
              setMenuVisible(false);
            }}
            status={sortOption === ROOM_SORT_VALUE ? 'checked' : 'unchecked'}
            value={ROOM_SORT_VALUE}
          />
        </SortItem>
        <SortItem
          onPress={() => {
            setSortOption(RECENT_SORT_VALUE);
            setMenuVisible(false);
          }}
        >
          <SortText>{t(strings.RECENT_SORT_TEXT)}</SortText>
          <RadioButton.Android
            onPress={() => {
              setSortOption(RECENT_SORT_VALUE);
              setMenuVisible(false);
            }}
            status={sortOption === RECENT_SORT_VALUE ? 'checked' : 'unchecked'}
            value={RECENT_SORT_VALUE}
          />
        </SortItem>
        <SortItem
          onPress={() => {
            setSortOption(DESCRIPTION_SORT_VALUE);
            setMenuVisible(false);
          }}
        >
          <SortText>{t(strings.DESCRIPTION_SORT_TEXT)}</SortText>
          <RadioButton.Android
            onPress={() => {
              setSortOption(DESCRIPTION_SORT_VALUE);
              setMenuVisible(false);
            }}
            value={DESCRIPTION_SORT_VALUE}
            status={
              sortOption === DESCRIPTION_SORT_VALUE ? 'checked' : 'unchecked'
            }
          />
        </SortItem>
      </SortItemContainer>
    </Container>
  );
};

SortContent.propTypes = {
  setMenuVisible: PropTypes.func,
  setSortOption: PropTypes.func,
  sortOption: PropTypes.string,
};

export default SortContent;
