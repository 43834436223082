import React, { useState, useContext } from 'react';
import { FlatList, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FAB } from 'react-native-paper';
import { useNavigation } from '@react-navigation/native';

import BottomModal from 'src/components/BottomModal';
import { screen } from 'src/constants';
import * as routes from 'src/screens/Room/routes';
import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';
import TaskHeader from 'src/screens/Room/components/TopHeader';
import RoomTaskContainer from '../components/RoomTaskContainer';
import DescriptionTaskContainer from '../components/DescriptionTaskContainer';
import EmptyTaskList from '../components/EmptyTaskList';
import TaskListTabs from '../components/TaskListTabs';
import SortSelection from '../components/SortSelection';
import TaskDetail from '../../Room/TaskDetail/TaskDetail';
import { getTasksByRoom, getTasksByDescription } from '../util/getSortedTasks';

const Container = styled(SafeAreaView).attrs(() => ({
  edges: ['top', 'left', 'right'],
}))`
  flex: 1;
  position: relative;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const CreateButton = styled(FAB)`
  background-color: ${({ theme }) => theme.colors.DIM_GREY};
  position: absolute;
  border-radius: 50px;
  bottom: 20px;
  right: 20px;
  margin: auto;
  text-align: center;
`;

const ROOM_SORT_VALUE = 'room';
const DESCRIPTION_SORT_VALUE = 'description';
const RECENT_SORT_VALUE = 'recent';

const TaskListScreen = observer(() => {
  const theme = useContext(ThemeContext);
  const [statusSelected, setStatusSelected] = useState(INCOMPLETE_STATUS);
  const [taskSelected, setTaskSelected] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [sortOption, setSortOption] = useState(ROOM_SORT_VALUE);
  const { roomStore, userStore, taskStore } = useStore();
  const { tasksAsArray, completedTodayTasks } = taskStore;
  const { authenticatedUser } = userStore;
  const navigation = useNavigation();

  const rooms = roomStore.roomsInNumericalOrder;
  const { t } = useTranslation();

  const getRoomTaskContainer = (item, index) => {
    return (
      <RoomTaskContainer
        sortOption={sortOption}
        setMenuVisible={setMenuVisible}
        index={index}
        roomTask={item}
        statusSelected={statusSelected}
        setTaskSelected={setTaskSelected}
      />
    );
  };

  const getDescriptionTaskContainer = (item, index) => {
    return (
      <DescriptionTaskContainer
        setMenuVisible={setMenuVisible}
        index={index}
        statusSelected={statusSelected}
        setTaskSelected={setTaskSelected}
        languagePreference={authenticatedUser.languagePreference}
        descriptionTask={item}
      />
    );
  };

  let taskData;

  switch (sortOption) {
    case ROOM_SORT_VALUE:
      taskData = getTasksByRoom(rooms, statusSelected);
      break;
    case RECENT_SORT_VALUE:
      taskData = getTasksByRoom(rooms, statusSelected).sort((a, b) => {
        return (
          new Date(b.mostRecentCreatedAt) - new Date(a.mostRecentCreatedAt)
        );
      });
      break;
    case DESCRIPTION_SORT_VALUE:
      taskData = getTasksByDescription(
        tasksAsArray,
        completedTodayTasks,
        authenticatedUser.languagePreference,
        statusSelected
      );
      break;

    default:
      taskData = getTasksByRoom(rooms, statusSelected);
      break;
  }

  return (
    <>
      <Container>
        <TaskHeader />
        <View
          style={{
            backgroundColor: theme.colors.BACKGROUND_DARK,
            flex: 1,
          }}
        >
          <TaskListTabs
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
          />
          {taskStore.completedTodayTasks.length === 0 &&
          statusSelected === COMPLETE_STATUS ? (
            <EmptyTaskList text={t(strings.NO_TASKS_TEXT)} />
          ) : null}
          {taskStore.incompleteTasks.length === 0 &&
          statusSelected === INCOMPLETE_STATUS ? (
            <EmptyTaskList text={t(strings.NO_TASKS_TEXT)} />
          ) : null}
          <FlatList
            contentContainerStyle={{
              paddingBottom: 30,
              marginTop: screen.SCREEN_HEIGHT * 0.01,
            }}
            data={taskData}
            keyExtractor={({ id }) => id.toString()}
            renderItem={({ item, index }) => {
              if (sortOption === DESCRIPTION_SORT_VALUE) {
                return getDescriptionTaskContainer(item, index);
              }
              return getRoomTaskContainer(item, index);
            }}
          />
        </View>
      </Container>
      {taskSelected ? (
        <TaskDetail
          users={userStore.users}
          task={taskSelected}
          onClose={() => setTaskSelected(null)}
          isFromTaskList
        />
      ) : null}
      <CreateButton
        onPress={() =>
          navigation.push(routes.ChooseTaskScreen, { isFromTaskList: true })
        }
        icon="plus"
        color={theme.colors.BACKGROUND_LIGHT}
      />
      <BottomModal
        isVisible={menuVisible}
        onClose={() => setMenuVisible(false)}
      >
        <SortSelection
          sortOption={sortOption}
          setSortOption={setSortOption}
          setMenuVisible={setMenuVisible}
        />
      </BottomModal>
    </>
  );
});

export default TaskListScreen;
