import React from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { Dialog } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { screen } from 'src/constants';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
  color: ${({ theme }) => theme.colors.BLACK};
`;
const SubTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.h5}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const TitleContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin-left: 24px;
`;

const Container = styled(Dialog)`
  justify-content: space-evenly;
  height: 40%;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  padding-bottom: ${screen.SCREEN_HEIGHT * 0.03}px;
`;

const CenterContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: flex-start;
`;

const PromptText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.BLACK};
  margin-left: 24px;
  margin-right: 24px;
`;

const ChangeRoomAssignmentModal = observer(
  ({ isOpen, onClose, onChange, roomAssignmentToChange, userToAssign }) => {
    const { roomStore, userStore } = useStore();
    const { t } = useTranslation();

    const room = roomStore.rooms.get(roomAssignmentToChange.roomId);
    const userCurrentlyAssigned = userStore.users.get(
      roomAssignmentToChange.userId
    );
    const userToAssignName = `${userToAssign?.firstName} ${userToAssign?.lastName}`;
    const userCurrentlyAssignedName = `${userCurrentlyAssigned?.firstName} ${userCurrentlyAssigned?.lastName}`;

    return (
      <Container visible={isOpen} dismissable={false}>
        <TitleContainer>
          <Title>{`${t(strings.ROOM_TEXT)} ${room?.number}`}</Title>
          <SubTitle>
            {t(strings.ASSIGNED_TO_TEXT)} {userCurrentlyAssignedName}
          </SubTitle>
        </TitleContainer>

        <CenterContainer>
          <PromptText>
            {t(strings.PROMPT_TEXT)}
            {userToAssignName}
            {' ?'}
          </PromptText>
        </CenterContainer>

        <SubmitButtonFooter
          submitButtonText={t(strings.CHANGE_TEXT)}
          onSubmit={() => {
            onChange();
            onClose();
          }}
          onCancel={() => onClose()}
          style={{
            paddingBottom: 0,
          }}
        />
      </Container>
    );
  }
);

ChangeRoomAssignmentModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  roomAssignmentToChange: PropTypes.object.isRequired,
  userToAssign: PropTypes.object.isRequired,
};

export default ChangeRoomAssignmentModal;
