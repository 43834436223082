export default {
  ADD_A_TASK_TEXT: 'ADD_A_TASK_TEXT',
  ADDITIONAL_NOTES_PLACEHOLDER_TEXT: 'ADDITIONAL_NOTES_PLACEHOLDER_TEXT',
  AGO_TEXT: 'AGO_TEXT',
  APPLY_ASSIGNMENTS_TEXT: 'APPLY_ASSIGNMENTS_TEXT',
  APPLY_BUTTON_FILTER_TEXT: 'APPLY_BUTTON_FILTER_TEXT',
  ASSIGN_ERROR_TEXT: 'ASSIGN_ERROR_TEXT',
  ASSIGN_LOADING_TEXT: 'ASSIGN_LOADING_TEXT',
  ASSIGN_ROOMS_MENU_TEXT: 'ASSIGN_ROOMS_MENU_TEXT',
  ASSIGN_SUCCESS_TEXT: 'ASSIGN_SUCCESS_TEXT',
  ASSIGN_TO_BUTTON_TEXT: 'ASSIGN_TO_BUTTON_TEXT',
  ASSIGN_TO_TEXT: 'ASSIGN_TO_TEXT',
  ASSIGNED_TEXT: 'ASSIGNED_TEXT',
  CREATED_BY_TEXT: 'CREATED_BY_TEXT',
  AGO_TEXT_SPANISH: 'AGO_TEXT_SPANISH',
  ARE_YOU_SURE_YOU_WANT_TO_EXIT_TEXT: 'ARE_YOU_SURE_YOU_WANT_TO_EXIT_TEXT',
  ASSIGNED_TO_ME_FILTER_TEXT: 'ASSIGNED_TO_ME_FILTER_TEXT',
  ASSIGNED_TO_TEXT: 'ASSIGNED_TO_TEXT',
  SORT_TASKS_TEXT: 'SORT_TASKS_TEXT',
  BACK_BUTTON_CREATE_TEXT: 'BACK_BUTTON_CREATE_TEXT',
  BLOCK_ROOM_TEXT: 'BLOCK_ROOM_TEXT',
  SORT_TEXT: 'SORT_TEXT',
  BLOCKED_FILTER_TEXT: 'BLOCKED_FILTER_TEXT',
  ROOM_SORT_TEXT: 'ROOM_SORT_TEXT',
  RECENT_SORT_TEXT: 'RECENT_SORT_TEXT',
  DESCRIPTION_SORT_TEXT: 'DESCRIPTION_SORT_TEXT',
  RECENT_SORT_LABEL: 'RECENT_SORT_LABEL',
  DESCRIPTION_SORT_LABEL: 'DESCRIPTION_SORT_LABEL',
  CANCEL: 'CANCEL',
  CHANGE_ACTIVE_STAFF_ASSIGN_TEXT: 'CHANGE_ACTIVE_STAFF_ASSIGN_TEXT',
  CHANGE_TEXT: 'CHANGE_TEXT',
  CHECKOUT_ASSIGN_TEXT: 'CHECKOUT_ASSIGN_TEXT',
  CHECKOUT_FILTER_TEXT: 'CHECKOUT_FILTER_TEXT',
  CHOOSE_A_STAFF_MEMBER_ASSIGN_TEXT: 'CHOOSE_A_STAFF_MEMBER_ASSIGN_TEXT',
  CLEAN_FILTER_TEXT: 'CLEAN_FILTER_TEXT',
  CLEAN_STATUS: 'CLEAN_STATUS',
  CLEAR_FILTER: 'CLEAR_FILTER',
  COMPLETED_TEXT: 'COMPLETED_TEXT',
  COMPLETED_TODAY_TEXT: 'COMPLETED_TODAY_TEXT',
  CREATE_A_NEW_TASK_TEXT: 'CREATE_A_NEW_TASK_TEXT',
  CREATE_BUTTON_CREATE_TEXT: 'CREATE_BUTTON_CREATE_TEXT',
  CREATE_CUSTOM_TASK_TEXT: 'CREATE_CUSTOM_TASK_TEXT',
  CREATE_ERROR_TITLE: 'CREATE_ERROR_TITLE',
  CREATE_LOADING_TITLE: 'CREATE_LOADING_TITLE',
  CREATE_SUCCESS_TITLE: 'CREATE_SUCCESS_TITLE',
  CREATED_TEXT: 'CREATED_TEXT',
  DIRTY_FILTER_TEXT: 'DIRTY_FILTER_TEXT',
  DND_OFF_TEXT: 'DND_OFF_TEXT',
  DND_ON_TEXT: 'DND_ON_TEXT',
  DO_NOT_DISTURB_FILTER_TEXT: 'DO_NOT_DISTURB_FILTER_TEXT',
  EDIT_BUTTON_TEXT: 'EDIT_BUTTON_TEXT',
  EDIT_ERROR_TITLE: 'EDIT_ERROR_TITLE',
  EDIT_LOADING_TITLE: 'EDIT_LOADING_TITLE',
  EDIT_SUCCESS_TITLE: 'EDIT_SUCCESS_TITLE',
  EDIT_TASK_DETAIL_TEXT: 'EDIT_TASK_DETAIL_TEXT',
  EDIT_TASK_TEXT: 'EDIT_TASK_TEXT',
  FILTER: 'FILTER',
  FILTER_COMPLETE_TEXT: 'FILTER_COMPLETE_TEXT',
  FILTER_OPEN_TEXT: 'FILTER_OPEN_TEXT',
  FILTERS_APPLIED: 'FILTERS_APPLIED',
  INCOMPLETE_TEXT: 'INCOMPLETE_TEXT',
  INSPECT_CLEAN_TEXT: 'INSPECT_CLEAN_TEXT',
  INSPECT_NOT_READY_TEXT: 'INSPECT_NOT_READY_TEXT',
  INSPECT_TEXT: 'INSPECT_TEXT',
  LATER_LABEL_TEXT: 'LATER_LABEL_TEXT',
  LOGIN: 'LOGIN',
  LOGIN_NETWORK_FAILURE_TEXT: 'LOGIN_NETWORK_FAILURE_TEXT',
  LOGIN_CREDENTIALS_FAILURE_TEXT: 'LOGIN_CREDENTIALS_FAILURE_TEXT',
  LOG_OUT_MENU_TEXT: 'LOG_OUT_MENU_TEXT',
  NETWORK_ERROR_FAILED_TO_GET_ROOMS: 'NETWORK_ERROR_FAILED_TO_GET_ROOMS',
  NO_ROOMS_TO_DISPLAY: 'NO_ROOMS_TO_DISPLAY',
  MARK_AS_CLEAN_TEXT: 'MARK_AS_CLEAN_TEXT',
  MARK_AS_COMPLETED_TASK_DETAIL_TEXT: 'MARK_AS_COMPLETED_TASK_DETAIL_TEXT',
  MARK_AS_DIRTY_LABEL_TEXT: 'MARK_AS_DIRTY_LABEL_TEXT',
  ADDITIONAL_NOTES_TEXT: 'ADDITIONAL_NOTES_TEXT',
  MARK_AS_DIRTY_TEXT: 'MARK_AS_DIRTY_TEXT',
  ROOMS_SELECTED_TEXT: 'ROOMS_SELECTED_TEXT',
  MORE_OPTIONS_TEXT: 'MORE_OPTIONS_TEXT',
  MARK_AS_URGENT_LABEL_TEXT: 'MARK_AS_URGENT_LABEL_TEXT',
  MENU_BAR_TEXT: 'MENU_BAR_TEXT',
  MORE_THAN_THREE_NOTIFICATIONS_MESSAGE:
    'MORE_THAN_THREE_NOTIFICATIONS_MESSAGE',
  NO: 'NO',
  NO_TASKS_TEXT: 'NO_TASKS_TEXT',
  OK: 'OK',
  PASSWORD: 'PASSWORD',
  PLEASE_TRY_AGAIN: 'PLEASE_TRY_AGAIN',
  PROMPT_TEXT: 'PROMPT_TEXT',
  REOPEN_TASK_TASK_DETAIL_TEXT: 'REOPEN_TASK_TASK_DETAIL_TEXT',
  RETRY: 'RETRY',
  COMPLETE_TASKS_PROMPT: 'COMPLETE_TASKS_PROMPT',
  YES_RESPONSE: 'YES_RESPONSE',
  NO_RESPONSE: 'NO_RESPONSE',
  ROOM_BLOCKED_TEXT: 'ROOM_BLOCKED_TEXT',
  ROOM_CHECKOUT_TEXT: 'ROOM_CHECKOUT_TEXT',
  ROOM_DETAILS_HEADER_CLEAN: 'ROOM_DETAILS_HEADER_CLEAN',
  ROOM_DETAILS_HEADER_DIRTY: 'ROOM_DETAILS_HEADER_DIRTY',
  ROOM_DETAILS_HEADER_INSPECT: 'ROOM_DETAILS_HEADER_INSPECT',
  ROOM_DND_TEXT: 'ROOM_DND_TEXT',
  ROOM_STATUS: 'ROOM_STATUS',
  ROOM_STAYOVER_TEXT: 'ROOM_STAYOVER_TEXT',
  ROOM_TEXT: 'ROOM_TEXT',
  ROOM_TEXT_PLURAL: 'ROOM_TEXT_PLURAL',
  ROOM_UPDATE_ERROR_PREFIX: 'ROOM_UPDATE_ERROR_PREFIX',
  ROOM_UPDATE_CLEAN_STATUS_TO_CLEAN_ERROR:
    'ROOM_UPDATE_CLEAN_STATUS_TO_CLEAN_ERROR',
  ROOM_UPDATE_CLEAN_STATUS_TO_DIRTY_ERROR:
    'ROOM_UPDATE_CLEAN_STATUS_TO_DIRTY_ERROR',
  ROOM_UPDATE_DO_NOT_DISTURB_TO_OFF_ERROR:
    'ROOM_UPDATE_DO_NOT_DISTURB_TO_OFF_ERROR',
  ROOM_UPDATE_DO_NOT_DISTURB_TO_ON_ERROR:
    'ROOM_UPDATE_DO_NOT_DISTURB_TO_ON_ERROR',
  ROOM_UPDATE_VACANCY_STATUS_TO_VACANT_ERROR:
    'ROOM_UPDATE_VACANCY_STATUS_TO_VACANT_ERROR',
  ROOM_UPDATE_VACANCY_STATUS_TO_BLOCKED_ERROR:
    'ROOM_UPDATE_VACANCY_STATUS_TO_BLOCKED_ERROR',
  ROOM_VACANT_TEXT: 'ROOM_VACANT_TEXT',
  SEARCH_ALL_TASKS_TEXT: 'SEARCH_ALL_TASKS_TEXT',
  SEARCH_PLACEHOLDER_TEXT: 'SEARCH_PLACEHOLDER_TEXT',
  SELECT_TASK_BUTTON_TEXT: 'SELECT_TASK_BUTTON_TEXT',
  SET_ACTIVE_USERS_TEXT: 'SET_ACTIVE_USERS_TEXT',
  SOMETHING_WENT_WRONG: 'SOMETHING_WENT_WRONG',
  CHOOSE_ROOMS_TEXT: 'CHOOSE_ROOMS_TEXT',
  NEVER_TEXT: 'NEVER_TEXT',
  STAYOVER_ASSIGN_TEXT: 'STAYOVER_ASSIGN_TEXT',
  STAYOVER_FILTER_TEXT: 'STAYOVER_FILTER_TEXT',
  SUBMIT: 'SUBMIT',
  TASK_UPDATE_STATUS_TO_COMPLETE_ERROR: 'TASK_UPDATE_STATUS_TO_COMPLETE_ERROR',
  TASK_UPDATE_STATUS_TO_INCOMPLETE_ERROR:
    'TASK_UPDATE_STATUS_TO_INCOMPLETE_ERROR',
  TASK_DESCRIPTION_LABEL_TEXT: 'TASK_DESCRIPTION_LABEL_TEXT',
  TASK_DESCRIPTION_PLACEHOLDER_TEXT: 'TASK_DESCRIPTION_PLACEHOLDER_TEXT',
  TASK_HISTORY_HEADER_TEXT: 'TASK_HISTORY_HEADER_TEXT',
  TASK_TEXT_PLURAL: 'TASK_TEXT_PLURAL',
  TURN_OFF_DND_TEXT: 'TURN_OFF_DND_TEXT',
  TURN_ON_DND_TEXT: 'TURN_ON_DND_TEXT',
  UNASSIGNED_ASSIGN_TEXT: 'UNASSIGNED_ASSIGN_TEXT',
  UNBLOCK_CLEAN_TEXT: 'UNBLOCK_CLEAN_TEXT',
  UNBLOCK_DIRTY_TEXT: 'UNBLOCK_DIRTY_TEXT',
  UNBLOCK_ROOM_TEXT: 'UNBLOCK_ROOM_TEXT',
  UPDATE_TASK_BUTTON_TEXT: 'UPDATE_TASK_BUTTON_TEXT',
  URGENT: 'URGENT',
  USERNAME: 'USERNAME',
  VACANT_FILTER_TEXT: 'VACANT_FILTER_TEXT',
  WHO_IS_WORKING_TODAY_TEXT: 'WHO_IS_WORKING_TODAY_TEXT',
  YES: 'YES',
  YOU_HAVE_UNSAVED_CHANGES_TEXT: 'YOU_HAVE_UNSAVED_CHANGES_TEXT',
};
