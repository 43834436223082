import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';

import Checkbox from 'src/components/Checkbox';

const Container = styled(TouchableOpacity)`
  flex-direction: row;
  align-items: center;
`;

const Name = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  font-family: ${({ theme }) => theme.fontFamily.regular};
`;

const SelectActiveUserCheckbox = ({ name, onPress, isSelected }) => {
  return (
    <Container onPress={onPress}>
      <Checkbox isChecked={isSelected} onPress={onPress} />
      <Name>{name}</Name>
    </Container>
  );
};

SelectActiveUserCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
export default SelectActiveUserCheckbox;
