import React, { useContext } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import strings from 'src/constants/i18n/strings';
import BackIcon from 'assets/icons/back.svg';
import { screen } from 'src/constants';
import CleanStatusIcon from 'src/components/CleanStatusIcon';
import BlockedIcon from 'assets/icons/blocked.svg';
import DoNotDisturbIcon from 'assets/icons/do_not_disturb.svg';
import { CLEAN_STATUS, COLORS, VACANCY_STATUS } from 'src/models/RoomStore';
import { USER_TYPE } from 'src/models/UserStore';
import SyncIcon from 'src/components/SyncIcon';

export const testIds = {
  HEADER: 'HEADER',
  CLEAN_ICON: 'CLEAN_ICON',
  DIRTY_ICON: 'DIRTY_ICON',
  SHOULD_INSPECT_ICON: 'SHOULD_INSPECT_ICON',
  BLOCKED_ICON: 'BLOCKED_ICON',
  DO_NOT_DISTURB_ICON: 'DO_NOT_DISTURB_ICON',
  ROOM_COLOR: 'ROOM_COLOR',
};

const HEADER_HEIGHT = screen.SCREEN_HEIGHT_MAXED * 0.175;

const HeaderContainer = styled(View)`
  height: ${HEADER_HEIGHT}px;
  padding-bottom: ${screen.SCREEN_WIDTH_MAXED * 0.04}px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const TitleContainer = styled(View)`
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const RoomNumber = styled(Text)`
  ${({ theme }) => theme.textStyles.h2}
  font-size: ${({ theme }) => theme.rem * 0.55}px;
`;

const Subheader = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const CleanStatusColorIndicator = styled(View)`
  background-color: ${(props) => props.color};
  width: ${({ theme }) => theme.screen.SCREEN_WIDTH_MAXED * 0.14}px;
  height: 65%;
  border-bottom-left-radius: 16px;
  position: absolute;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
`;

const BackText = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const RoomDetailHeader = observer(
  ({
    number,
    doNotDisturb,
    vacancyStatus,
    cleanStatus,
    isSheetOpen,
    setIsSheetOpen,
    changeSnapPoints,
    userType,
    room,
    updateRoom,
  }) => {
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();
    const theme = useContext(ThemeContext);
    const { t } = useTranslation();

    const onColorIndicatorPress = () => {
      if (userType === USER_TYPE.HOUSEKEEPER) {
        const updatedCleanStatus =
          room.vacancyStatus === VACANCY_STATUS.STAYOVER
            ? CLEAN_STATUS.CLEAN
            : CLEAN_STATUS.SHOULD_INSPECT;

        updateRoom({
          roomId: room.id,
          options: {
            body: {
              room: {
                cleanStatus:
                  room.cleanStatus === CLEAN_STATUS.DIRTY
                    ? updatedCleanStatus
                    : CLEAN_STATUS.DIRTY,
              },
            },
          },
        });
      } else {
        setIsSheetOpen(!isSheetOpen);
        changeSnapPoints();
      }
    };

    const getBackgroundColor = () => {
      if (doNotDisturb) {
        return COLORS.DO_NOT_DISTURB;
      }
      if (vacancyStatus.toLowerCase() === VACANCY_STATUS.BLOCKED) {
        return COLORS.BLOCKED;
      }

      switch (cleanStatus.toLowerCase()) {
        case CLEAN_STATUS.CLEAN:
          return COLORS.CLEAN;
        case CLEAN_STATUS.DIRTY:
          return COLORS.DIRTY;
        case CLEAN_STATUS.SHOULD_INSPECT:
          return COLORS.SHOULD_INSPECT;
        default:
          return theme.colors.SECONDARY;
      }
    };

    const getVacancyStatusText = () => {
      if (doNotDisturb) {
        return t(strings.ROOM_DND_TEXT);
      }
      switch (vacancyStatus) {
        case VACANCY_STATUS.STAYOVER:
          return t(strings.ROOM_STAYOVER_TEXT);
        case VACANCY_STATUS.BLOCKED:
          return t(strings.ROOM_BLOCKED_TEXT);
        case VACANCY_STATUS.VACANT:
          return t(strings.ROOM_VACANT_TEXT);
        case VACANCY_STATUS.CHECKOUT:
          return t(strings.ROOM_CHECKOUT_TEXT);
        default:
          return '';
      }
    };

    const getRoomIcon = () => {
      let roomIcon;
      if (doNotDisturb) {
        roomIcon = (
          <DoNotDisturbIcon
            testID={testIds.DO_NOT_DISTURB_ICON}
            width={screen.SCREEN_WIDTH_MAXED * 0.0625}
            height={screen.SCREEN_WIDTH_MAXED * 0.0625}
            fill={theme.colors.SECONDARY}
          />
        );
      } else if (vacancyStatus.toLowerCase() === VACANCY_STATUS.BLOCKED) {
        roomIcon = (
          <BlockedIcon
            testID={testIds.BLOCKED_ICON}
            width={screen.SCREEN_WIDTH_MAXED * 0.0625}
            height={screen.SCREEN_WIDTH_MAXED * 0.0625}
            fill={theme.colors.SECONDARY}
          />
        );
      } else {
        roomIcon = (
          <CleanStatusIcon
            cleanStatus={cleanStatus}
            width={screen.SCREEN_WIDTH_MAXED * 0.0625}
            height={screen.SCREEN_WIDTH_MAXED * 0.0625}
            fill={theme.colors.SECONDARY}
            testID={`${cleanStatus.toUpperCase()}_ICON`}
          />
        );
      }

      return roomIcon;
    };

    return (
      <>
        <HeaderContainer
          testID={testIds.HEADER}
          style={{ paddingTop: insets.top }}
        >
          <TouchableOpacity
            onPress={() => {
              navigation.goBack();
            }}
            style={{
              height: '100%',
              flex: 3,
              flexDirection: 'row',
              alignItems: 'center',
              paddingLeft: screen.SCREEN_WIDTH_MAXED * 0.06,
            }}
          >
            <BackIcon
              style={{ marginRight: 5 }}
              width={10}
              height={10}
              fill={theme.colors.TERTIARY}
            />
            <BackText>{t(strings.BACK_BUTTON_CREATE_TEXT)}</BackText>
          </TouchableOpacity>

          <TitleContainer>
            <RoomNumber>
              {t(strings.ROOM_TEXT)} {number}
            </RoomNumber>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Subheader>{getVacancyStatusText()}</Subheader>
            </View>
          </TitleContainer>
          <View
            style={{
              flex: 3,
              height: '100%',
            }}
          >
            <CleanStatusColorIndicator
              testID={testIds.ROOM_COLOR}
              color={getBackgroundColor()}
            >
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onPress={onColorIndicatorPress}
              >
                {getRoomIcon()}
              </TouchableOpacity>
            </CleanStatusColorIndicator>
          </View>
          <SyncIcon
            isVisible={room.isSyncing}
            isRotating
            width={screen.SCREEN_HEIGHT_MAXED * 0.035}
            height={screen.SCREEN_HEIGHT_MAXED * 0.035}
            color={theme.colors.TERTIARY}
            containerStyle={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              width: screen.SCREEN_WIDTH_MAXED * 0.14,
              height: '50%',
            }}
          />
        </HeaderContainer>
      </>
    );
  }
);
RoomDetailHeader.propTypes = {
  number: PropTypes.string,
  doNotDisturb: PropTypes.bool,
  vacancyStatus: PropTypes.string,
  cleanStatus: PropTypes.string,
  isSheetOpen: PropTypes.bool,
  setIsSheetOpen: PropTypes.func,
  changeSnapPoints: PropTypes.func,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
    isSyncing: PropTypes.bool,
  }).isRequired,
  updateRoom: PropTypes.func,
  userType: PropTypes.string,
};

export default RoomDetailHeader;
