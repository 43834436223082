import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import BottomTab from 'src/screens/BottomTab';
import * as routes from './routes';
import RoomDetailScreen from './RoomDetailScreen/RoomDetailScreen';
import RoomFilterScreen from './RoomFilterScreen/RoomFilterScreen';
import ChooseTaskScreen from './CreateTaskScreens/ChooseTaskScreen';
import ChosenTaskScreen from './CreateTaskScreens/ChosenTaskScreen';
import ChooseRoomScreen from './CreateTaskScreens/ChooseRoomScreen';
import AssignRoomScreen from './AssignRoomScreen/AssignRoomScreen';
import SelectRoomScreen from './AssignRoomScreen/SelectRoomScreen';

const RoomStack = createStackNavigator();

export const deepLinkConfig = {
  Rooms: {
    initialRouteName: routes.RoomScreen,
    screens: {
      [routes.RoomDetailScreen]: {
        path: 'task/:taskId',
      },
    },
  },
};

export default () => {
  const defaultScreenOptions = {
    ...TransitionPresets.SlideFromRightIOS,
  };
  return (
    <RoomStack.Navigator initialRouteName={routes.RoomScreen} headerMode="none">
      <RoomStack.Screen name={routes.RoomScreen} component={BottomTab} />
      <RoomStack.Screen
        name={routes.RoomDetailScreen}
        component={RoomDetailScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <RoomStack.Screen
        name={routes.RoomFilterScreen}
        component={RoomFilterScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />

      <RoomStack.Screen
        name={routes.ChooseTaskScreen}
        component={ChooseTaskScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <RoomStack.Screen
        name={routes.ChosenTaskScreen}
        component={ChosenTaskScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <RoomStack.Screen
        name={routes.AssignRoomScreen}
        component={AssignRoomScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <RoomStack.Screen
        name={routes.SelectRoomScreen}
        component={SelectRoomScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <RoomStack.Screen
        name={routes.ChooseRoomScreen}
        component={ChooseRoomScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
    </RoomStack.Navigator>
  );
};
