/* eslint-disable react/jsx-props-no-spreading */
// https://github.com/react-native-community/react-native-modal
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import Modal from 'react-native-modal';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { screen } from 'src/constants';

const Container = styled(Modal)`
  justify-content: flex-end;
  margin: 0;
`;

const ContentContainer = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  justify-content: space-between;
  padding: ${({ theme }) => theme.rem * 0.5}px;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom || screen.SCREEN_HEIGHT * 0.025}px;
`;

const BottomModal = ({
  isVisible,
  onClose,
  contentContainerStyle,
  children,
  ...props
}) => {
  const insets = useSafeAreaInsets();

  return (
    <Container
      isVisible={isVisible}
      onClose={onClose}
      onBackdropPress={onClose}
      onSwipeComplete={onClose}
      swipeDirection="down"
      propagateSwipe
      backdropTransitionOutTiming={0}
      useNativeDriver
      backdropOpacity={0.5}
      {...props}
    >
      <ContentContainer
        paddingBottom={insets.bottom}
        style={contentContainerStyle}
      >
        {children}
      </ContentContainer>
    </Container>
  );
};

BottomModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  contentContainerStyle: PropTypes.object,
  children: PropTypes.any,
};

export default BottomModal;
