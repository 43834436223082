import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import NoTasks from 'assets/icons/no_tasks.svg';
import PropTypes from 'prop-types';
import { screen } from 'src/constants';

export const testIds = {
  NO_TASKS: 'NO_TASKS',
  ADD_TASK_ICON: 'ADD_TASK_ICON',
};

const NoOpenTasks = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const EmptyTaskList = ({ text }) => {
  const theme = useContext(ThemeContext);

  return (
    <View
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: screen.SCREEN_WIDTH * 0.06,
      }}
    >
      <NoOpenTasks>{text}</NoOpenTasks>
      <NoTasks
        testID={testIds.NO_TASKS}
        width={screen.SCREEN_WIDTH_MAXED * 0.37}
        height={screen.SCREEN_WIDTH_MAXED * 0.37}
        fill={theme.colors.BLACK}
        style={{
          marginTop: screen.SCREEN_WIDTH * 0.07,
        }}
      />
    </View>
  );
};

EmptyTaskList.propTypes = {
  text: PropTypes.string,
};

export default EmptyTaskList;
