import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';
import { ChosenTaskScreen } from '../../routes';

export const BUTTON_PRIMARY = 'BUTTON_PRIMARY';
export const BUTTON_SECONDARY = 'BUTTON_SECONDARY';

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const MarkAsCompletedButton = styled(Button).attrs((props) => ({
  label: props.buttonLabel,
}))`
  flex: 6;
`;

const ReopenTaskButton = styled(Button).attrs((props) => ({
  mode: 'outline',
  label: props.buttonLabel,
}))`
  flex: 6;
`;

const EditTaskButton = styled(Button).attrs((props) => ({
  mode: 'text',
  label: props.buttonLabel,
}))`
  flex: 4;
`;

const Buttons = ({ onClose, task, isFromTaskList }) => {
  const { t } = useTranslation();

  const navigation = useNavigation();
  const { taskStore } = useStore();

  const toggleStatus = (currentStatus) => {
    const newStatus =
      currentStatus === INCOMPLETE_STATUS ? COMPLETE_STATUS : INCOMPLETE_STATUS;

    return { status: newStatus };
  };

  const navigateToTaskEdit = () => {
    onClose();
    navigation.navigate(ChosenTaskScreen, {
      taskId: task.id,
      isFromTaskList,
    });
  };

  const onPatch = () => {
    if (task.status === COMPLETE_STATUS) {
      navigateToTaskEdit();
    }

    taskStore.patchTask({
      taskId: task.id,
      options: {
        body: { task: toggleStatus(task.status) },
      },
    });
    onClose();
  };

  return (
    <Container>
      {task.status === INCOMPLETE_STATUS ? (
        <>
          <EditTaskButton
            buttonLabel={t(strings.EDIT_TASK_DETAIL_TEXT)}
            onPress={navigateToTaskEdit}
            testID={BUTTON_SECONDARY}
          />
          <MarkAsCompletedButton
            onPress={onPatch}
            testID={BUTTON_PRIMARY}
            buttonLabel={t(strings.MARK_AS_COMPLETED_TASK_DETAIL_TEXT)}
          />
        </>
      ) : (
        <ReopenTaskButton
          onPress={onPatch}
          testID={BUTTON_PRIMARY}
          buttonLabel={t(strings.REOPEN_TASK_TASK_DETAIL_TEXT)}
        />
      )}
    </Container>
  );
};

Buttons.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  isFromTaskList: PropTypes.bool,
};

export default Buttons;
