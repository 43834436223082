import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';

const Container = styled(TouchableOpacity)`
  width: ${({ width }) => width || 48}px;
  height: ${({ width }) => width || 48}px;
  background-color: ${({ isSelected, alreadyAssigned, theme }) => {
    if (alreadyAssigned) {
      return theme.colors.DISABLED;
    }

    return isSelected ? theme.colors.PRIMARY : theme.colors.BACKGROUND_LIGHT;
  }};
  border: 1px solid ${({ theme }) => theme.colors.PRIMARY};
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  margin-right: ${({ width }) => width * 0.2 || 10}px;
  margin-bottom: ${({ width }) => width * 0.2 || 10}px;
`;

const RoomNumberText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.SECONDARY : theme.colors.TERTIARY};
`;

const SelectRoomCard = ({
  width,
  roomNumber,
  onPress,
  isSelected = false,
  alreadyAssigned = false,
} = {}) => {
  return (
    <Container
      isSelected={isSelected}
      alreadyAssigned={alreadyAssigned}
      onPress={onPress}
      width={width}
    >
      <RoomNumberText isSelected={isSelected}>{roomNumber}</RoomNumberText>
    </Container>
  );
};

SelectRoomCard.propTypes = {
  roomNumber: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  alreadyAssigned: PropTypes.bool.isRequired,
  width: PropTypes.number,
};

export default SelectRoomCard;
