import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { View, Text } from 'react-native';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useStore } from 'src/context/StoreContext';
import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { COMPLETE_STATUS } from 'src/models/TaskStore';

export const testIds = {
  BUTTON_PRIMARY: 'BUTTON_PRIMARY',
  BUTTON_SECONDARY: 'BUTTON_SECONDARY',
  BLOCKED_TASK_ICON: 'BLOCKED_TASK_ICON',
  TASK_ICON: 'TASK_ICON',
};

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: ${screen.SCREEN_WIDTH * 0.02}px 0px;
`;

const Content = styled(Text)`
  ${({ theme }) => theme.textStyles.h5}
  margin-vertical: ${screen.SCREEN_WIDTH * 0.003}px;
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const DetailsText = ({ task }) => {
  const { t } = useTranslation();
  const createdProperty = 'createdAt';
  const completedProperty = 'completedAt';
  const assignedProperty = 'assignedAt';
  const { userStore } = useStore();

  const createdByName = task.createdByUserId
    ? userStore.users.get(task.createdByUserId).firstName
    : null;

  const getTimestamp = (type, date) => {
    let description;
    switch (type) {
      case createdProperty:
        description = `${t(strings.CREATED_TEXT)} ${moment
          .duration(moment() - moment(date))
          .humanize()} ${t(strings.AGO_TEXT)}`;
        break;
      case assignedProperty:
        description = `${t(strings.ASSIGNED_TEXT)} ${moment
          .duration(moment() - moment(date))
          .humanize()} ${t(strings.AGO_TEXT)}`;
        break;
      case completedProperty:
        description = `${t(strings.COMPLETED_TEXT)} ${moment
          .duration(moment() - moment(date))
          .humanize()} ${t(strings.AGO_TEXT)}`;
        break;
      default:
        description = null;
    }
    return <Content>{description}</Content>;
  };

  return (
    <Container>
      {createdByName ? (
        <>
          <Content>{`${t(strings.CREATED_BY_TEXT)} ${createdByName}`}</Content>
          <Content> | </Content>
        </>
      ) : null}
      {getTimestamp(createdProperty, task.createdAt)}
      {task.taskAssignments.length > 0 ? (
        <>
          <Content> | </Content>
          {getTimestamp(assignedProperty, task.assignedAt)}
        </>
      ) : null}
      {task.status === COMPLETE_STATUS ? (
        <>
          <Content> | </Content>
          {getTimestamp(completedProperty, task.completedAt)}
        </>
      ) : null}
    </Container>
  );
};

DetailsText.propTypes = {
  task: PropTypes.object.isRequired,
};

export default DetailsText;
