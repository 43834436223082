import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import * as routes from 'src/screens/Room/routes';
import strings from 'src/constants/i18n/strings';
import { USER_TYPE } from 'src/models/UserStore';
import MarkAsCleanIcon from 'assets/icons/clean.svg';
import MarkAsDirtyIcon from 'assets/icons/dirty.svg';
import BlockRoomIcon from 'assets/icons/blocked.svg';
import { VACANCY_STATUS, CLEAN_STATUS } from 'src/models/RoomStore';
import MenuItem from './MenuItem';

export const BLOCK_ROOM = 'Block Room';
export const UNBLOCK_AS_CLEAN = 'Unblock as Clean';
export const UNBLOCK_AS_DIRTY = 'Unblock as Dirty';

const VacancyStatusMenuItem = ({
  room,
  handleSheetOpen,
  updateRoom,
  userType,
  navigation,
  showAlert,
}) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  if (room.vacancyStatus === VACANCY_STATUS.BLOCKED) {
    return (
      <>
        <MenuItem
          icon={<MarkAsCleanIcon />}
          color={theme.colors.PRIMARY_DARK}
          testID={UNBLOCK_AS_CLEAN}
          title={t(strings.UNBLOCK_CLEAN_TEXT)}
          onPress={() => {
            let newStatus = CLEAN_STATUS.CLEAN;
            if (userType === USER_TYPE.HOUSEKEEPER) {
              newStatus =
                room.vacancyStatus === VACANCY_STATUS.STAYOVER
                  ? CLEAN_STATUS.CLEAN
                  : CLEAN_STATUS.SHOULD_INSPECT;
            }
            updateRoom({
              roomId: room.id,
              options: {
                body: {
                  room: {
                    vacancyStatus: VACANCY_STATUS.VACANT,
                    cleanStatus: newStatus,
                  },
                },
              },
            });
            if (room.incompleteTasks.length > 0) {
              showAlert();
            }

            handleSheetOpen();
          }}
        />
        <MenuItem
          icon={<MarkAsDirtyIcon />}
          color="#E57A3B"
          testID={UNBLOCK_AS_DIRTY}
          title={t(strings.UNBLOCK_DIRTY_TEXT)}
          onPress={() => {
            updateRoom({
              roomId: room.id,
              options: {
                body: {
                  room: {
                    vacancyStatus: VACANCY_STATUS.VACANT,
                    cleanStatus: CLEAN_STATUS.DIRTY,
                  },
                },
              },
            });
            handleSheetOpen();
          }}
        />
      </>
    );
  }

  return (
    <MenuItem
      hideBorder
      color={theme.colors.RED}
      testID={BLOCK_ROOM}
      title={t(strings.BLOCK_ROOM_TEXT)}
      icon={<BlockRoomIcon />}
      onPress={() => {
        updateRoom({
          roomId: room.id,
          options: {
            body: {
              room: {
                vacancyStatus: VACANCY_STATUS.BLOCKED,
              },
            },
          },
        });
        if (room.incompleteTasks.length === 0) {
          navigation.push(routes.ChooseTaskScreen, {
            room,
          });
        }
        handleSheetOpen();
      }}
    />
  );
};

VacancyStatusMenuItem.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
    incompleteTasks: PropTypes.array.isRequired,
  }).isRequired,
  handleSheetOpen: PropTypes.func,
  updateRoom: PropTypes.func,
  showAlert: PropTypes.func,
  userType: PropTypes.string,
  navigation: PropTypes.object,
};

export default VacancyStatusMenuItem;
