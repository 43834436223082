import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Text, View } from 'react-native';
import { Switch } from 'react-native-paper';
import styled, { ThemeContext } from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';

export const SET_DO_NOT_DISTURB = 'SET_DO_NOT_DISTURB';
export const UNSET_DO_NOT_DISTURB = 'UNSET_DO_NOT_DISTURB';

const Container = styled(View)`
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 20px;
`;

const DoNotDisturbText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5};
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const DoNotDisturbStatusText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5};
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const DoNotDisturbMenuItem = ({ room, updateRoom, handleSheetOpen }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  return (
    <Container>
      <View style={{ flexDirection: 'column' }}>
        <DoNotDisturbText>{t(strings.ROOM_DND_TEXT)}</DoNotDisturbText>
        <DoNotDisturbStatusText>
          {room.doNotDisturb ? t(strings.DND_ON_TEXT) : t(strings.DND_OFF_TEXT)}
        </DoNotDisturbStatusText>
      </View>
      <Switch
        testID={room.doNotDisturb ? UNSET_DO_NOT_DISTURB : SET_DO_NOT_DISTURB}
        value={room.doNotDisturb}
        onValueChange={() => {
          updateRoom({
            roomId: room.id,
            options: {
              body: {
                room: {
                  doNotDisturb: !room.doNotDisturb,
                },
              },
            },
            errorNotificationText: room.doNotDisturb
              ? t(strings.ROOM_UPDATE_DO_NOT_DISTURB_TO_OFF_ERROR, {
                  roomNumber: room.number,
                })
              : t(strings.ROOM_UPDATE_DO_NOT_DISTURB_TO_ON_ERROR, {
                  roomNumber: room.number,
                }),
          });
          handleSheetOpen();
        }}
        color={theme.colors.PINK}
      />
    </Container>
  );
};

DoNotDisturbMenuItem.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
  }).isRequired,
  handleSheetOpen: PropTypes.func,
  updateRoom: PropTypes.func,
};

export default DoNotDisturbMenuItem;
