import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import BottomSheet from 'reanimated-bottom-sheet';
import { useStore } from 'src/context/StoreContext';
import MenuBarContent from './components/MenuBarContent';

const Overlay = styled(View)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: 1;
`;

const MenuBar = ({
  isSheetOpen,
  setIsSheetOpen,
  changeSnapPoints,
  bottomSheetRef,
  cleanStatus,
  room,
  userType,
  navigation,
}) => {
  const { roomStore } = useStore();
  const renderContent = () => (
    <MenuBarContent
      userType={userType}
      updateRoom={roomStore.updateRoom}
      isSheetOpen={isSheetOpen}
      setIsSheetOpen={setIsSheetOpen}
      changeSnapPoints={changeSnapPoints}
      room={room}
      cleanStatus={cleanStatus}
      navigation={navigation}
    />
  );

  return (
    <>
      {isSheetOpen ? (
        <Overlay visible={isSheetOpen} pointerEvents="box-none">
          <TouchableOpacity
            onPress={() => {
              setIsSheetOpen(!isSheetOpen);
              changeSnapPoints();
            }}
            style={{ width: '100%', height: '100%' }}
          />
        </Overlay>
      ) : null}
      <BottomSheet
        ref={bottomSheetRef}
        snapPoints={[350, 100]}
        enabledContentTapInteraction={false}
        changeSnapPoints={changeSnapPoints}
        onOpenEnd={() => {
          setIsSheetOpen(true);
        }}
        onCloseEnd={() => {
          setIsSheetOpen(false);
        }}
        initialSnap={1}
        borderRadius={10}
        renderContent={renderContent}
      />
    </>
  );
};

MenuBar.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
  }).isRequired,
  isSheetOpen: PropTypes.bool,
  setIsSheetOpen: PropTypes.func,
  changeSnapPoints: PropTypes.func,
  cleanStatus: PropTypes.string,
  bottomSheetRef: PropTypes.object,
  userType: PropTypes.string,
  navigation: PropTypes.object,
};

export default MenuBar;
