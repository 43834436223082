import { INCOMPLETE_STATUS } from 'src/models/TaskStore';

export const getTasksByRoom = (rooms, statusSelected) =>
  rooms
    .map((room) => {
      let tasksForRoom;
      if (statusSelected === INCOMPLETE_STATUS) {
        tasksForRoom = room.incompleteTasks;
      } else {
        tasksForRoom = room.completedTodayTasks;
      }

      if (tasksForRoom.length === 0) {
        return null;
      }

      tasksForRoom.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      const mostRecentCreatedAt = new Date(
        Math.max(...tasksForRoom.map((task) => new Date(task.createdAt)))
      );

      return {
        id: room.id,
        room,
        mostRecentCreatedAt,
        tasks: tasksForRoom,
      };
    })
    .filter((el) => el);

export const getTasksByDescription = (
  tasksAsArray,
  completedTodayTasks,
  languagePreference,
  statusSelected
) => {
  const groupBy = (arr, property) => {
    return arr.reduce((memo, x) => {
      if (!memo[x[property]]) {
        memo[x[property]] = [];
      }
      memo[x[property]].push(x);
      return memo;
    }, {});
  };

  let tasksDisplayed;

  const incompleteTasks = tasksAsArray.filter(
    (task) => task.status === INCOMPLETE_STATUS
  );

  if (statusSelected === INCOMPLETE_STATUS) {
    tasksDisplayed = incompleteTasks;
  } else {
    tasksDisplayed = completedTodayTasks;
  }

  tasksDisplayed.forEach((task) => {
    const descriptionStringified = JSON.stringify(task.description);
    task.descriptionStringified = descriptionStringified;
  });

  const descriptions = Object.keys(
    groupBy(tasksDisplayed, 'descriptionStringified')
  );

  const taskGroupByDescription = descriptions.map((description) => {
    return groupBy(tasksDisplayed, 'descriptionStringified')[description];
  });

  const sortDescriptionAlphabetically = (a, b) => {
    return a[0].description[languagePreference].localeCompare(
      b[0].description[languagePreference]
    );
  };
  taskGroupByDescription.sort(sortDescriptionAlphabetically);

  return taskGroupByDescription.map((tasks, index) => {
    return {
      tasks,
      description: tasks[0].description,
      id: index,
    };
  });
};
