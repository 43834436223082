import React, { useState } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { Searchbar } from 'react-native-paper';

import strings from 'src/constants/i18n/strings';
import Button from 'src/components/Button';
import { useStore } from 'src/context/StoreContext';
import TaskIcon from 'assets/icons/task2.svg';
import { ChosenTaskScreen, ChooseRoomScreen } from '../routes';
import { screen } from '../../../constants';
import TaskImage from './components/TaskImage';
import {
  CreateTaskContainer,
  Header,
  HeaderTitle,
  Content,
  BottomContainer,
} from './CreateTaskScreensStyles';

export const SEARCH = 'SEARCH';

const CancelButton = styled(Button)`
  margin-bottom: ${screen.SCREEN_WIDTH * 0.05}px;
  margin-top: ${screen.SCREEN_WIDTH * 0.05}px;
`;

const CardContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: ${screen.SCREEN_HEIGHT * 0.02}px;
  margin-bottom: ${screen.SCREEN_HEIGHT * 0.15}px;
`;

const SearchTaskContainer = styled.ScrollView.attrs({
  contentContainerStyle: {
    paddingHorizontal: 22,
  },
})`
  margin-top: ${screen.SCREEN_WIDTH * 0.04}px;
  width: 100%;
  height: ${screen.SCREEN_HEIGHT * 0.1}px;
  border-bottom-width: 1px;
  border-bottom-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
`;

const SearchTask = styled(Searchbar)`
  box-shadow: none;
  border-width: 1px;
  border-color: rgba(25, 25, 25, 0.32);
`;

const ChooseTaskScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { room, isInspect, isFromTaskList } = route.params;
  const { t } = useTranslation();
  const { userStore, hotelTaskStore } = useStore();

  const [search, setSearch] = useState('');
  const { authenticatedUser } = userStore;
  const { hotelTasksAsArray } = hotelTaskStore;

  const navigateToChooseRoom = (task) => {
    const taskWithoutOnPress = {};
    Object.assign(taskWithoutOnPress, task);
    taskWithoutOnPress.onPress = undefined;
    navigation.push(ChooseRoomScreen, {
      hotelTask: taskWithoutOnPress,
    });
  };

  const navigateToChosenTask = (task) => {
    const taskWithoutOnPress = {};
    Object.assign(taskWithoutOnPress, task);
    taskWithoutOnPress.onPress = undefined;
    navigation.push(ChosenTaskScreen, {
      room,
      task: taskWithoutOnPress,
      isInspect,
    });
  };

  const navigateToChosenTaskFromCustom = (selectedTask) => {
    const taskWithoutOnNonSerializableAttributes = {};
    Object.assign(taskWithoutOnNonSerializableAttributes, selectedTask);
    if (taskWithoutOnNonSerializableAttributes?.isCustom) {
      taskWithoutOnNonSerializableAttributes.title = search;
    }
    taskWithoutOnNonSerializableAttributes.onPress = undefined;
    taskWithoutOnNonSerializableAttributes.icon = undefined;
    navigation.push(ChosenTaskScreen, {
      room,
      task: taskWithoutOnNonSerializableAttributes,
      isInspect,
    });
  };

  const mostUsedHotelTasks = hotelTasksAsArray.filter(
    (hotelTask) => hotelTask.mostUsed
  );

  const searchAllTasks = hotelTasksAsArray.filter(
    (hotelTask) => !hotelTask.mostUsed
  );

  let allTasks = mostUsedHotelTasks.concat(searchAllTasks);

  const customTaskImage = {
    title: t(strings.CREATE_CUSTOM_TASK_TEXT),
    icon: TaskIcon,
    onPress: isFromTaskList
      ? navigateToChooseRoom
      : navigateToChosenTaskFromCustom,
    isCustom: true,
  };

  allTasks = allTasks.map((hotelTask) => {
    return {
      title: hotelTask.description[authenticatedUser.languagePreference],
      description: hotelTask.description,
      image: hotelTask.imageUrl,
      onPress: isFromTaskList ? navigateToChooseRoom : navigateToChosenTask,
    };
  });

  allTasks.push(customTaskImage);

  const searchMatch = (title) => {
    return (
      search === '' ||
      title === t(strings.CREATE_CUSTOM_TASK_TEXT) ||
      title.toLocaleLowerCase().includes(search.toLocaleLowerCase())
    );
  };

  return (
    <>
      <CreateTaskContainer>
        <Header>
          <HeaderTitle>{t(strings.CREATE_A_NEW_TASK_TEXT)}</HeaderTitle>
        </Header>

        <Content>
          <SearchTaskContainer
            scrollEnabled={false}
            keyboardShouldPersistTaps="always"
          >
            <SearchTask
              placeholder={t(strings.SEARCH_PLACEHOLDER_TEXT)}
              onChangeText={(query) => setSearch(query)}
              value={search}
              testID={SEARCH}
            />
          </SearchTaskContainer>

          <ScrollView>
            <CardContainer>
              {allTasks.map((hotelTask) => {
                if (searchMatch(hotelTask.title)) {
                  return (
                    <TaskImage
                      key={hotelTask.title}
                      task={hotelTask}
                      image={hotelTask.image}
                      onPressImage={hotelTask.onPress}
                      touchable
                      icon={hotelTask.icon}
                    />
                  );
                }
                return null;
              })}
            </CardContainer>
          </ScrollView>
        </Content>
        <BottomContainer>
          <CancelButton
            label={t(strings.CANCEL)}
            onPress={navigation.goBack}
            mode="text"
          />
        </BottomContainer>
      </CreateTaskContainer>
    </>
  );
};

export default ChooseTaskScreen;
