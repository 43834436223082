import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { strings } from 'src/constants/i18n';
import { useStore } from 'src/context/StoreContext';
import NotificationCard from './NotificationCard';

const Container = styled(View)`
  position: absolute;
  top: -90px;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
`;

const NotificationOverlay = observer(() => {
  const { notificationStore } = useStore();
  const { t } = useTranslation();

  const getNotificationCards = () => {
    const { notificationsAsArray } = notificationStore;

    if (notificationsAsArray.length >= 2) {
      return (
        <NotificationCard
          text={`${t(strings.MORE_THAN_THREE_NOTIFICATIONS_MESSAGE, {
            count: notificationsAsArray.length,
          })}`}
          onRetry={() => {
            notificationsAsArray.forEach(({ callback }) => {
              callback();
            });
          }}
          onDismiss={() => {
            notificationsAsArray.forEach(({ id }) => {
              notificationStore.deleteNotification({ id });
            });
          }}
        />
      );
    }
    return notificationsAsArray.map(({ id, text, callback }) => {
      return (
        <NotificationCard
          key={id}
          text={text}
          onRetry={callback}
          onDismiss={() => {
            notificationStore.deleteNotification({ id });
          }}
        />
      );
    });
  };

  return (
    <Container pointerEvents="box-none">{getNotificationCards()}</Container>
  );
});

export default NotificationOverlay;
