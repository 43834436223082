import React from 'react';
import { ScrollView, View } from 'react-native';
import styled from 'styled-components/native';
import { Dialog } from 'react-native-paper';
import PropTypes from 'prop-types';

import { useStore } from 'src/context/StoreContext';
import Button from 'src/components/Button';

const Container = styled(Dialog)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const TaskAssignModal = ({ setAssignedUser, isOpen, setIsOpen }) => {
  const { userStore } = useStore();
  const { usersAsArray } = userStore;

  const users = usersAsArray.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  );

  const selectUser = (user) => {
    setAssignedUser(user);
    setIsOpen(false);
  };

  return (
    <Container visible={isOpen} onDismiss={() => setIsOpen(false)}>
      <ScrollView>
        {users.map((user) => {
          return (
            <View key={user.id} style={{ flexDirection: 'row', margin: 5 }}>
              <Button
                key={user.id}
                style={{ width: '100%' }}
                mode="text"
                onPress={() => selectUser(user)}
                label={`${user.firstName} ${user.lastName}`}
              />
            </View>
          );
        })}
      </ScrollView>
    </Container>
  );
};

TaskAssignModal.propTypes = {
  setAssignedUser: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default TaskAssignModal;
