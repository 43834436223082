import React, { useState, useEffect } from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import {
  useRoute,
  useNavigationState,
  useNavigation,
} from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/context/StoreContext';
import { Portal } from 'react-native-paper';

import LoadingScreen from 'src/components/LoadingScreen';
import MenuBar from '../RoomDetailMenuBar/MenuBar';
import TaskDetail from '../TaskDetail/TaskDetail';
import RoomDetailsHeader from './components/RoomDetailsHeader';
import RoomDetails from './components/RoomDetails';
import * as routes from '../routes';

export const testIds = {
  INCOMPLETE_COUNT: 'INCOMPLETE_COUNT',
  INCOMPLETE_LIST: 'INCOMPLETE_LIST',
  COMPLETED_TODAY_LIST: 'COMPLETED_TODAY_LIST',
  COMPLETED_TODAY_COUNT: 'COMPLETED_TODAY_COUNT',
};

const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_OPAQUE};
`;

const RoomDetailScreen = observer(() => {
  const navigation = useNavigation();
  const route = useRoute();
  const navigationState = useNavigationState((state) => state);
  const bottomSheetRef = React.useRef(null);

  const [taskSelected, setTaskSelected] = useState(null);
  const [room, setRoom] = useState(null);
  const { userStore, roomStore, taskStore, isAnyFetchLoading } = useStore();
  const { authenticatedUser } = userStore;
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const changeSnapPoints = () => {
    if (isSheetOpen) {
      bottomSheetRef.current.snapTo(1);
    } else {
      bottomSheetRef.current.snapTo(0);
    }
  };

  useEffect(() => {
    if (route.params?.taskId) {
      const task = taskStore.tasks.get(route.params?.taskId);
      setTaskSelected(task);
      setRoom(roomStore.rooms.get(task?.roomId));
    } else {
      setRoom(roomStore.rooms.get(route.params.roomId));
    }
  }, [route.params, isAnyFetchLoading]);

  if (!room) {
    return <LoadingScreen />;
  }

  const { doNotDisturb, vacancyStatus, cleanStatus, number } = room;

  return (
    <>
      <Container>
        <RoomDetailsHeader
          changeSnapPoints={changeSnapPoints}
          isSheetOpen={isSheetOpen}
          setIsSheetOpen={setIsSheetOpen}
          bottomSheetRef={bottomSheetRef}
          number={number}
          doNotDisturb={doNotDisturb}
          cleanStatus={cleanStatus}
          vacancyStatus={vacancyStatus}
          updateRoom={roomStore.updateRoom}
          room={room}
          userType={authenticatedUser.userType}
        />
        <RoomDetails
          userType={authenticatedUser.userType}
          room={room}
          setTaskSelected={setTaskSelected}
        />
        {/* The height of this view is the same as the smallest 'snapPoint' in MenuBar/BottomSheet */}
        <View style={{ height: 100 }} />
        {navigationState.routes[navigationState.routes.length - 1].name ===
        routes.RoomDetailScreen ? (
          <Portal>
            <MenuBar
              changeSnapPoints={changeSnapPoints}
              isSheetOpen={isSheetOpen}
              setIsSheetOpen={setIsSheetOpen}
              bottomSheetRef={bottomSheetRef}
              cleanStatus={cleanStatus}
              room={room}
              userType={authenticatedUser.userType}
              navigation={navigation}
            />
          </Portal>
        ) : null}
      </Container>
      {taskSelected ? (
        <TaskDetail
          users={userStore.users}
          task={taskSelected}
          onClose={() => setTaskSelected(null)}
        />
      ) : null}
    </>
  );
});

export default RoomDetailScreen;
