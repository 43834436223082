import React from 'react';
import { Text, View } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const Container = styled(View)`
  min-height: 56px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
`;

const TitleContainer = styled(View)`
  justify-content: center;
  align-items: center;
  height: 56px;
`;

const AssignRoomHeader = observer(({ title, children }) => {
  return (
    <Container>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {children}
    </Container>
  );
});

AssignRoomHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default AssignRoomHeader;
