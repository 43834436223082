import React, { useContext, useRef, useEffect } from 'react';
import { Animated, Easing } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';

import SyncIcon from 'assets/icons/sync.svg';

const SyncIconContainer = styled(Animated.View)`
  justify-content: center;
  align-items: center;
`;

const SyncIconComponent = ({
  isVisible,
  isRotating,
  width,
  height,
  color,
  testID = 'SYNC_ICON',
  containerStyle = {},
} = {}) => {
  const theme = useContext(ThemeContext);
  const rotationValue = new Animated.Value(0);
  const opacityValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isVisible) {
      Animated.timing(opacityValue, {
        toValue: 1,
        duration: 350,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.timing(opacityValue, {
        toValue: 0,
        duration: 350,
        useNativeDriver: true,
      }).start();
    }
  }, [isVisible]);

  Animated.loop(
    Animated.timing(rotationValue, {
      toValue: 1,
      duration: 4000,
      useNativeDriver: true,
      easing: Easing.linear,
    })
  ).start();

  return (
    <SyncIconContainer
      testID={testID}
      isVisible={isVisible}
      style={{
        opacity: opacityValue,
        transform: [
          {
            rotate: isRotating
              ? rotationValue.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '360deg'],
                })
              : '0deg',
          },
        ],
        ...containerStyle,
      }}
    >
      <SyncIcon
        width={width || theme.screen.SCREEN_HEIGHT_MAXED * 0.035}
        height={height || theme.screen.SCREEN_HEIGHT_MAXED * 0.035}
        color={color || theme.colors.TERTIARY}
      />
    </SyncIconContainer>
  );
};

SyncIconComponent.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isRotating: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  testID: PropTypes.string,
  containerStyle: PropTypes.object,
};

export default SyncIconComponent;
