import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import NotificationOverlay from 'src/components/NotificationOverlay';
import LoginScreen from './LoginScreen/LoginScreen';
import * as routes from './routes';

const AuthStack = createStackNavigator();

export default () => {
  const defaultScreenOptions = {
    ...TransitionPresets.SlideFromRightIOS,
  };

  return (
    <>
      <AuthStack.Navigator>
        <AuthStack.Screen
          options={{
            ...defaultScreenOptions,
            headerShown: false,
          }}
          name={routes.LoginScreen}
          component={LoginScreen}
        />
      </AuthStack.Navigator>
      <NotificationOverlay />
    </>
  );
};
