import React, { useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';
import { useStore } from 'src/context/StoreContext';
import { RoomDetailScreen } from 'src/screens/Room/routes';
import { TaskListScreen } from 'src/screens/Task/routes';
import { CLEAN_STATUS, VACANCY_STATUS } from 'src/models/RoomStore';
import { LATER_STATUS } from 'src/models/TaskStore';
import {
  CreateTaskContainer,
  Header,
  HeaderTitle,
  BottomContainer,
} from './CreateTaskScreensStyles';
import TaskAssignModal from './components/TaskAssignModal';
import FormInputs from './components/FormInputs';

const ChosenTaskScreen = observer(() => {
  let room;
  let rooms;
  let task;
  let isFromTaskList;
  let roomAssignmentForRoom;
  let isInspect;

  const navigation = useNavigation();
  const route = useRoute();
  const { t } = useTranslation();
  const { taskStore, userStore, roomStore, roomAssignmentStore } = useStore();

  const { authenticatedUser } = userStore;
  const [description, setDescription] = useState('');
  const [additionalNotes, setAdditionalNotes] = useState('');
  const [assignedUser, setAssignedUser] = useState(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);
  const [isBlock, setIsBlock] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [isLater, setIsLater] = useState(false);

  if (route.params?.taskId) {
    task = taskStore.tasks.get(route.params.taskId);
    room = roomStore.rooms.get(task.roomId);
    isFromTaskList = route.params.isFromTaskList;
  } else if (route.params?.rooms) {
    ({ task, rooms } = route.params);
    isFromTaskList = route.params.isFromTaskList;
  } else {
    ({ room, task, isInspect } = route.params);
    roomAssignmentForRoom = roomAssignmentStore.activeRoomAssignments.filter(
      (roomAssignment) => roomAssignment.roomId === room.id
    );
  }
  const isEdit = !!task?.id;
  const isCreatingMultiple = !!rooms;

  useEffect(() => {
    if (isEdit) {
      setDescription(task.description[authenticatedUser.languagePreference]);
      setAdditionalNotes(task.additionalNotes);
      setIsUrgent(task.urgent);
      setIsLater(task.status === LATER_STATUS);
      if (task.taskAssignments.length > 0) {
        setAssignedUser(
          userStore.users.get(
            task.taskAssignments[task.taskAssignments.length - 1].userId
          )
        );
      }
    } else {
      if (isInspect) {
        const preAssignedUserId =
          roomAssignmentForRoom.length > 0
            ? roomAssignmentForRoom[0].userId
            : null;
        const preAssignedUser = userStore.users.get(preAssignedUserId);
        setAssignedUser(preAssignedUser);
      }
      setDescription(task.title);
    }
  }, [isEdit]);

  const createMultipleTasks = (newOrUpdatedTask) => {
    const tasks = rooms.map((item) => {
      const modifiedPayload = {
        ...newOrUpdatedTask,
        roomId: item.id,
      };
      return modifiedPayload;
    });
    if (isBlock) {
      const roomsToBlock = rooms.map((item) => {
        return {
          id: item.id,
          number: item.number,
          vacancyStatus: VACANCY_STATUS.BLOCKED,
        };
      });

      roomStore.updateRooms({
        options: {
          body: {
            rooms: roomsToBlock,
          },
        },
      });
    }
    taskStore.createTasks({
      options: {
        body: {
          tasks,
        },
      },
    });
  };

  const onSubmit = () => {
    const userIds = [];
    if (assignedUser) {
      userIds.push(assignedUser.id);
    }

    const descriptionWithLanguages = task.description
      ? task.description
      : {
          english: description,
          spanish: description,
          chinese: description,
        };

    const newOrUpdatedTask = {
      description: descriptionWithLanguages,
      additionalNotes,
      userIds,
      urgent: isUrgent,
      createdByUserId: authenticatedUser.id,
    };

    if (isLater) {
      newOrUpdatedTask.status = LATER_STATUS;
    }

    if (isEdit) {
      taskStore.patchTask({
        taskId: task.id,
        options: {
          body: {
            task: newOrUpdatedTask,
          },
        },
      });
    } else if (isCreatingMultiple) {
      createMultipleTasks(newOrUpdatedTask);
    } else {
      taskStore.createTask({
        options: {
          body: {
            task: {
              ...newOrUpdatedTask,
              roomId: room?.id,
            },
          },
        },
      });
      if (isInspect) {
        roomStore.updateRoom({
          roomId: room?.id,
          options: {
            body: {
              room: {
                cleanStatus: isDirty ? CLEAN_STATUS.DIRTY : CLEAN_STATUS.CLEAN,
              },
            },
          },
        });
      }
    }

    if (isFromTaskList) {
      navigation.navigate(TaskListScreen);
    } else {
      navigation.navigate(RoomDetailScreen);
    }
  };

  return (
    <>
      <CreateTaskContainer>
        <Header>
          <HeaderTitle>
            {isEdit
              ? t(strings.EDIT_TASK_TEXT)
              : t(strings.CREATE_A_NEW_TASK_TEXT)}
          </HeaderTitle>
        </Header>
        <FormInputs
          navigation={navigation}
          isCreatingMultiple={isCreatingMultiple}
          rooms={rooms}
          isLater={isLater}
          setIsLater={setIsLater}
          isInspect={isInspect}
          isUrgent={isUrgent}
          setIsUrgent={setIsUrgent}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
          isBlock={isBlock}
          setIsBlock={setIsBlock}
          isEdit={isEdit}
          task={task}
          additionalNotes={additionalNotes}
          setAdditionalNotes={setAdditionalNotes}
          description={description}
          setDescription={setDescription}
          assignedUser={assignedUser}
          setAssignedUser={setAssignedUser}
          setIsAssignModalOpen={setIsAssignModalOpen}
        />
        <BottomContainer>
          <SubmitButtonFooter
            onSubmit={onSubmit}
            onCancel={navigation.goBack}
            submitButtonText={
              isEdit
                ? t(strings.UPDATE_TASK_BUTTON_TEXT)
                : t(strings.CREATE_BUTTON_CREATE_TEXT)
            }
            cancelButtonText={t(strings.BACK_BUTTON_CREATE_TEXT)}
            disabled={!description}
          />
        </BottomContainer>
      </CreateTaskContainer>
      <TaskAssignModal
        setAssignedUser={setAssignedUser}
        isOpen={isAssignModalOpen}
        setIsOpen={setIsAssignModalOpen}
      />
    </>
  );
});

export default ChosenTaskScreen;
