import React from 'react';
import PropTypes from 'prop-types';
import CleanIcon from 'assets/icons/clean.svg';
import DirtyIcon from 'assets/icons/dirty.svg';
import InspectIcon from 'assets/icons/inspect.svg';
import { CLEAN_STATUS } from '../models/RoomStore';

const CleanStatusIcon = ({ cleanStatus, width, height, fill, testID }) => {
  let IconComponent;
  switch (cleanStatus) {
    case CLEAN_STATUS.CLEAN:
      IconComponent = CleanIcon;
      break;
    case CLEAN_STATUS.DIRTY:
      IconComponent = DirtyIcon;
      break;
    case CLEAN_STATUS.SHOULD_INSPECT:
      IconComponent = InspectIcon;
      break;
    default:
      throw new Error('Invalid CLEAN_STATUS for CleanStatusIcon');
  }
  return (
    <IconComponent testID={testID} width={width} height={height} fill={fill} />
  );
};

CleanStatusIcon.propTypes = {
  cleanStatus: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fill: PropTypes.string.isRequired,
  testID: PropTypes.string.isRequired,
};

export default CleanStatusIcon;
