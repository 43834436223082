import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';

import { View, Text } from 'react-native';
import Modal from 'react-native-modal';
import { observer } from 'mobx-react-lite';

import { screen } from 'src/constants';
import TaskDetailDescription from './components/TaskDetailDescription';
import DetailsText from './components/DetailsText';
import Buttons from './components/Buttons';

const MODAL_POSITION =
  screen.SCREEN_HEIGHT * 0.28 < 200 ? 100 : screen.SCREEN_HEIGHT * 0.2;

const Container = styled(View)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  display: flex;
  flex-direction: column;
  top: ${MODAL_POSITION}px;
  padding: ${screen.SCREEN_WIDTH * 0.05}px ${screen.SCREEN_WIDTH * 0.06}px;
`;

const AdditionNotesText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5}
  margin-top: ${screen.SCREEN_WIDTH * 0.02}px;
  margin-bottom: ${screen.SCREEN_WIDTH * 0.06}px;
`;

const TaskDetail = observer(({ onClose, task, users, isFromTaskList }) => {
  return (
    <Modal
      isVisible
      onBackdropPress={onClose}
      style={{ margin: 0 }}
      backdropOpacity={0.5}
    >
      <Container>
        <TaskDetailDescription users={users} onClose={onClose} task={task} />
        <DetailsText task={task} />
        <AdditionNotesText>
          {task.additionalNotes ? task.additionalNotes : null}
        </AdditionNotesText>
        <Buttons
          onClose={onClose}
          task={task}
          isFromTaskList={isFromTaskList}
        />
      </Container>
    </Modal>
  );
});

TaskDetail.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  users: PropTypes.object,
  isFromTaskList: PropTypes.bool,
};

export default TaskDetail;
