/* eslint-disable global-require */
import { merge } from 'lodash';
import { types, applyAction, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';

const Action = types.model('Action', {
  args: types.frozen(),
  name: types.string,
  path: types.string,
});

const OfflineStore = types
  .model('OfflineStore', {
    actions: types.optional(types.map(Action), {}),
  })
  .views((self) => ({
    get actionsAsArray() {
      return values(self.actions);
    },
  }))
  .actions((self) => ({
    upsertAction({ id, action }) {
      const uniqueId = `${action.path}-${action.name}${id ? `-${id}` : ''}`;
      const existingAction = self.actions.get(uniqueId);
      if (existingAction) {
        existingAction.args.forEach((arg, index) => {
          if (typeof arg === 'object') {
            merge(existingAction.args[index], action.args[index]);
          }
        });
        self.actions.set(uniqueId, existingAction);
      } else {
        self.actions.set(uniqueId, action);
      }
    },
    setAction({ id, action }) {
      self.actions.set(id, action);
    },
    retryActions() {
      if (self.actionsAsArray.length > 0) {
        applyAction(getRoot(self), self.actionsAsArray);
        self.actions = {};
      }
    },
  }));

export default OfflineStore;
