import React, { useEffect, useRef } from 'react';
import { AppState } from 'react-native';
import i18n from 'i18next';
import moment from 'moment';

import { useStore } from 'src/context/StoreContext';
import { createStackNavigator } from '@react-navigation/stack';
import { LANGUAGE_CODES } from 'src/models/UserStore';
import { RoomStack, TaskStack } from './src/screens';
import NotificationOverlay from './src/components/NotificationOverlay';

const Authenticated = () => {
  const Stack = createStackNavigator();
  const { fetchAll, authStore, userStore } = useStore();

  const appState = useRef(AppState.currentState);

  const handleAppStateChange = (nextAppState) => {
    if (
      appState.current.match(/inactive|background/) &&
      nextAppState === 'active'
    ) {
      if (userStore.authenticatedUser) {
        moment.locale(
          LANGUAGE_CODES[
            userStore.authenticatedUser.languagePreference.toLowerCase()
          ]
        );
        i18n.changeLanguage(
          LANGUAGE_CODES[userStore.authenticatedUser.languagePreference]
        );
      }
      fetchAll();
    }

    appState.current = nextAppState;
  };

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);

    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
    };
  }, []);

  useEffect(() => {
    fetchAll();
    authStore.registerNotificationToken();
  }, []);

  return (
    <>
      <Stack.Navigator headerMode="none">
        <Stack.Screen name="Rooms" component={RoomStack} />
        <Stack.Screen name="Tasks" component={TaskStack} />
      </Stack.Navigator>
      <NotificationOverlay />
    </>
  );
};

export default Authenticated;
