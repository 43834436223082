import { types, flow } from 'mobx-state-tree';
import { values } from 'mobx';
import { request } from 'src/utils/LodgebookAPIClient';
import i18n from 'i18next';
import moment from 'moment';

export const USERS_URL = '/users';
export const AUTHENTICATED_USER_URL = '/users/authenticated';

export const USER_TYPE = {
  HOUSEKEEPER: 'housekeeper',
  SUPERVISOR: 'supervisor',
};

export const LANGUAGE_PREFERENCE = {
  ENGLISH: 'english',
  SPANISH: 'spanish',
  CHINESE: 'chinese',
};

export const User = types.model('User', {
  id: types.identifierNumber,
  username: types.string,
  firstName: types.string,
  lastName: types.string,
  languagePreference: types.string,
  userType: types.maybeNull(types.string),
});

export const LANGUAGE_CODES = {
  english: 'en',
  spanish: 'es',
  chinese: 'zh-cn',
};

const UserStore = types
  .model('UserStore', {
    authenticatedUser: types.maybe(types.frozen(User)),
    users: types.optional(types.map(User), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingAuthenticatedUser: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get usersAsArray() {
      return values(self.users);
    },
  }))
  .actions((self) => ({
    fetchAllUsers: flow(function*(hotelId) {
      self.isFetchingAll = true;
      try {
        const usersResponse = yield request(
          `${USERS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.users = {};
        usersResponse.users.forEach((user) => {
          self.users.set(user.id, user);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch users', error);
      }
      self.isFetchingAll = false;
    }),
    fetchAuthenticatedUser: flow(function*(hotelId) {
      self.isFetchingAuthenticatedUser = true;
      try {
        const authenticatedUserRequest = yield request(
          `${AUTHENTICATED_USER_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        self.authenticatedUser = authenticatedUserRequest.user;
        moment.locale(
          LANGUAGE_CODES[
            authenticatedUserRequest.user.languagePreference.toLowerCase()
          ]
        );
        i18n.changeLanguage(
          LANGUAGE_CODES[authenticatedUserRequest.user.languagePreference]
        );
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.error('Failed to fetch user', error);
      }
      self.isFetchingAuthenticatedUser = false;
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
  }));

export default UserStore;
