/* eslint-disable react/jsx-filename-extension, global-require */

import React, { useContext, useEffect, useMemo } from 'react';
import { Platform } from 'react-native';
// eslint-disable-next-line import/no-unresolved
import { AUTH_COOKIE_NAME } from '@env';
import { observer } from 'mobx-react-lite';
import { useFonts } from 'expo-font';
import BackgroundFetch from 'react-native-background-fetch';
import BackgroundTimer from 'react-native-background-timer';

import { useStore } from 'src/context/StoreContext';
import ActionCableContext from 'src/context/ActionCableContext';
import Authenticated from './Authenticated';
import { AuthStack } from './src/screens';
import LoadingScreen from './src/components/LoadingScreen';

// eslint-disable-next-line react/prop-types
const Screens = observer(({ allCookies }) => {
  const {
    authStore,
    roomStore,
    taskStore,
    hotelStore,
    roomAssignmentStore,
    offlineStore,
  } = useStore();
  const [fontsLoaded] = useFonts({
    'Open Sans Regular': require('./assets/fonts/Open_Sans/OpenSans-Regular.ttf'),
    'Open Sans SemiBold': require('./assets/fonts/Open_Sans/OpenSans-SemiBold.ttf'),
    'Open Sans Bold': require('./assets/fonts/Open_Sans/OpenSans-Bold.ttf'),
  });

  const actionCableConsumer = useContext(ActionCableContext);

  const isAuth = useMemo(() => {
    if (Platform.OS === 'web') {
      return AUTH_COOKIE_NAME in allCookies;
    }
    return !!authStore.cookie;
  }, [allCookies, authStore.cookie]);

  useEffect(() => {
    BackgroundTimer.runBackgroundTimer(() => {
      offlineStore.retryActions();
    }, 10000);

    BackgroundFetch.configure(
      {
        minimumFetchInterval: 15,
      },
      async (backgroundId) => {
        offlineStore.retryActions();
        BackgroundFetch.finish(backgroundId);
      },
      (error) => {
        console.log('[Background]', error);
      }
    );

    return () => {
      BackgroundTimer.stopBackgroundTimer();
    };
  }, []);

  useEffect(() => {
    if (isAuth && hotelStore.selectedHotelId) {
      actionCableConsumer.subscriptions.create(
        {
          channel: 'TasksChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ taskId, method }) => {
            if (method === 'FETCH' && taskId) {
              taskStore.fetchTask(taskId);
            }
          },
        }
      );

      actionCableConsumer.subscriptions.create(
        {
          channel: 'RoomsChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ roomId, method }) => {
            if (method === 'FETCH' && roomId) {
              roomStore.fetchRoom(roomId);
            } else if (method === 'FETCH') {
              roomStore.fetchAllRooms(hotelStore.selectedHotelId);
            }
          },
        }
      );

      actionCableConsumer.subscriptions.create(
        {
          channel: 'RoomAssignmentsChannel',
          hotel_id: hotelStore.selectedHotelId,
        },
        {
          received: ({ method }) => {
            if (method === 'FETCH') {
              roomAssignmentStore.fetchAllRoomAssignments(
                hotelStore.selectedHotelId
              );
            }
          },
        }
      );
    } else {
      actionCableConsumer.subscriptions.subscriptions.forEach(
        (subscription) => {
          subscription.unsubscribe();
        }
      );
    }
  }, [isAuth, hotelStore.selectedHotelId]);

  if (authStore.isRestoringCookie || !fontsLoaded) {
    return <LoadingScreen />;
  }

  return isAuth ? <Authenticated /> : <AuthStack />;
});

const LBScreenSwitch = () => {
  if (Platform.OS === 'web') {
    // eslint-disable-next-line global-require
    const { withCookies } = require('react-cookie');
    const RoutesWithCookies = withCookies(Screens);
    return <RoutesWithCookies />;
  }
  return <Screens />;
};

export default LBScreenSwitch;
