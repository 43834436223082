import React from 'react';
import { Alert } from 'react-native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { VACANCY_STATUS } from 'src/models/RoomStore';
import { COMPLETE_STATUS } from 'src/models/TaskStore';
import { useStore } from 'src/context/StoreContext';
import PropTypes from 'prop-types';
import VacancyStatusMenuItem from './VacancyStatusMenuItem';
import CleanStatusMenuItem from './CleanStatusMenuItem';
import DoNotDisturbMenuItem from './DoNotDisturbMenuItem';

const MenuBarExpandedContent = ({
  room,
  updateRoom,
  handleSheetOpen,
  userType,
  navigation,
}) => {
  const { taskStore } = useStore();
  const { t } = useTranslation();

  const updateIncompleteTasks = () => {
    room.incompleteTasks.forEach((task) => {
      const taskUpdates = {
        status: COMPLETE_STATUS,
      };
      taskStore.patchTask({
        taskId: task.id,
        options: {
          body: {
            task: taskUpdates,
          },
        },
      });
    });
  };

  const showAlert = () =>
    Alert.alert(
      t(strings.COMPLETE_TASKS_PROMPT),
      '',
      [
        {
          text: t(strings.NO_RESPONSE),
          onPress: () => {},
          style: 'cancel',
        },
        {
          text: t(strings.YES_RESPONSE),
          onPress: updateIncompleteTasks,
          style: 'cancel',
        },
      ],
      {
        cancelable: true,
        onDismiss: () => {},
      }
    );

  return (
    <>
      {room.vacancyStatus === VACANCY_STATUS.STAYOVER ? (
        <DoNotDisturbMenuItem
          handleSheetOpen={handleSheetOpen}
          room={room}
          updateRoom={updateRoom}
        />
      ) : null}
      {room.vacancyStatus !== VACANCY_STATUS.BLOCKED ? (
        <CleanStatusMenuItem
          handleSheetOpen={handleSheetOpen}
          room={room}
          updateRoom={updateRoom}
          userType={userType}
          showAlert={showAlert}
        />
      ) : null}
      <VacancyStatusMenuItem
        handleSheetOpen={handleSheetOpen}
        room={room}
        updateRoom={updateRoom}
        userType={userType}
        navigation={navigation}
        showAlert={showAlert}
      />
    </>
  );
};

MenuBarExpandedContent.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
    incompleteTasks: PropTypes.array.isRequired,
  }).isRequired,
  handleSheetOpen: PropTypes.func,
  updateRoom: PropTypes.func,
  userType: PropTypes.string,
  navigation: PropTypes.object,
};

export default MenuBarExpandedContent;
