import React, { useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { View, FlatList } from 'react-native';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import { COMPLETE_STATUS } from 'src/models/TaskStore';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';
import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import RoomListItem from './components/RoomListItem';
import { screen } from '../../../constants';
import { ChosenTaskScreen } from '../routes';
import { HeaderTitle } from './CreateTaskScreensStyles';

const Header = styled(View)`
  height: ${screen.SCREEN_HEIGHT * 0.14}px;
  padding-top: ${screen.SCREEN_HEIGHT * 0.08}px;
  display: flex;
  padding-left: 25px;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  width: 100%;
`;

export const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const BottomContainer = styled(View)`
  margin-bottom: ${screen.SCREEN_WIDTH * 0.06}px;
  width: 100%;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  height: 25%;
`;

export const RoomListContainer = styled(View)`
  height: 75%;
  width: 95%;
  flex-direction: column;
  border-top-width: 1px;
  border-top-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const ChooseRoomScreen = observer(() => {
  const navigation = useNavigation();
  const route = useRoute();
  const [roomsSelected, setRoomsSelected] = useState([]);
  const { hotelTask } = route.params;
  const { t } = useTranslation();
  const { roomStore, hotelStore, taskStore } = useStore();
  const { roomsAsArray } = roomStore;
  const { timeZone, selectedHotelId } = hotelStore;
  const { tasksByDescriptionAsArray, fetchTasksByDescription } = taskStore;
  useEffect(() => {
    fetchTasksByDescription(selectedHotelId, hotelTask.descriptionAsJSON);
  }, []);

  const navigateToChosenTask = (task) => {
    const rooms = roomsSelected;
    const taskWithoutOnPress = {};
    Object.assign(taskWithoutOnPress, task);
    taskWithoutOnPress.onPress = undefined;
    navigation.push(ChosenTaskScreen, {
      rooms,
      task: taskWithoutOnPress,
      isFromTaskList: true,
    });
  };

  hotelTask.descriptionAsJSON = JSON.stringify(hotelTask.description);
  const completeTasks = tasksByDescriptionAsArray.filter(
    (task) => task.status === COMPLETE_STATUS
  );
  return (
    <>
      <Container>
        <Header>
          <HeaderTitle>{hotelTask.title}</HeaderTitle>
        </Header>

        <RoomListContainer>
          <FlatList
            contentContainerStyle={{
              paddingBottom: screen.SCREEN_HEIGHT * 0.05,
            }}
            keyExtractor={({ id }) => id.toString()}
            data={roomsAsArray}
            renderItem={({ item: room }) => (
              <RoomListItem
                selected={roomsSelected.includes(room)}
                roomsSelected={roomsSelected}
                completeTasks={completeTasks}
                setRoomsSelected={setRoomsSelected}
                hotelTask={hotelTask}
                room={room}
                timeZone={timeZone}
              />
            )}
          />
        </RoomListContainer>
        <BottomContainer>
          <SubmitButtonFooter
            onCancel={navigation.goBack}
            onSubmit={() => navigateToChosenTask(hotelTask)}
            submitButtonText={`${t(strings.CHOOSE_ROOMS_TEXT)} ${
              roomsSelected.length > 0 ? `(${roomsSelected.length})` : ''
            }`}
            disabled={roomsSelected.length === 0}
          />
        </BottomContainer>
      </Container>
    </>
  );
});

export default ChooseRoomScreen;
