import React from 'react';
import {
  createStackNavigator,
  TransitionPresets,
} from '@react-navigation/stack';

import ChosenTaskScreen from 'src/screens/Room/CreateTaskScreens/ChosenTaskScreen';
import * as roomStackRoutes from 'src/screens/Room/routes';
import BottomTab from 'src/screens/BottomTab';
import * as routes from './routes';

const TaskStack = createStackNavigator();

export default () => {
  const defaultScreenOptions = {
    ...TransitionPresets.SlideFromRightIOS,
  };

  return (
    <TaskStack.Navigator
      initialRouteName={routes.TaskListScreen}
      headerMode="none"
    >
      <TaskStack.Screen
        name={routes.TaskListScreen}
        component={BottomTab}
        options={{
          ...defaultScreenOptions,
        }}
      />
      <TaskStack.Screen
        name={roomStackRoutes.ChosenTaskScreen}
        component={ChosenTaskScreen}
        options={{
          ...defaultScreenOptions,
        }}
      />
    </TaskStack.Navigator>
  );
};
