import React, { useState, useContext } from 'react';
import { View, TouchableOpacity } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';
import { Snackbar } from 'react-native-paper';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import CloseIcon from 'assets/icons/close.svg';

const DISMISS_BUTTON = 'DISMISS_BUTTON';

const DismissButton = styled(TouchableOpacity)`
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border-color: ${({ theme }) => theme.colors.BLACK};
  border-width: 2px;
  position: absolute;
  top: 0;
  right: 4px;
  justify-content: center;
  align-items: center;
`;

const NotificationCard = ({ text, onDismiss, onRetry }) => {
  const theme = useContext(ThemeContext);
  const { t } = useTranslation();

  const [isVisible, setIsVisible] = useState(true);

  const action = onRetry
    ? {
        label: t(strings.RETRY),
        onPress: onRetry,
      }
    : {
        label: t(strings.OK),
        onPress: onDismiss,
      };

  const duration = onRetry ? Infinity : Snackbar.DURATION_SHORT;

  return (
    <View
      style={{
        width: '100%',
      }}
      pointerEvents="box-none"
    >
      <Snackbar
        wrapperStyle={{
          position: 'relative',
        }}
        duration={duration}
        visible={isVisible}
        onDismiss={() => {
          setIsVisible(false);
          onDismiss();
        }}
        action={action}
      >
        {text}
      </Snackbar>
      {onRetry ? (
        <DismissButton testID={DISMISS_BUTTON} onPress={onDismiss}>
          <CloseIcon fill={theme.colors.BLACK} width={10} height={10} />
        </DismissButton>
      ) : null}
    </View>
  );
};

NotificationCard.propTypes = {
  text: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onRetry: PropTypes.func,
};

export default NotificationCard;
