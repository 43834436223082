import React, { useState } from 'react';
import { View, ScrollView, Text } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useStore } from 'src/context/StoreContext';
import { observer } from 'mobx-react-lite';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { VACANCY_STATUS, CLEAN_STATUS } from 'src/models/RoomStore';
import { screen } from 'src/constants';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';
import AssignRoomHeader from './components/AssignRoomHeader';
import SelectRoomCard from './components/SelectRoomCard';
import ChangeRoomAssignmentModal from './components/ChangeRoomAssignmentModal';

const Container = styled(View)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  padding-top: ${({ paddingTop }) => paddingTop || 24}px;
`;

const ListContainer = styled(ScrollView)`
  flex: 19;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  padding: 20px;
  padding-top: 24px;
`;

const CheckoutStayoverText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  flex: 1;
  color: ${({ theme }) => theme.colors.TERTIARY};
  margin-bottom: 8px;
`;

const RoomCardsContainer = styled(View)`
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: ${() => screen.SCREEN_WIDTH * 0.15 * -0.2 || -10}px;
`;

const SelectRoomScreen = observer(() => {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const {
    params: {
      user,
      roomAssignments,
      setRoomAssignments,
      roomAssignmentsToClear,
      setRoomAssignmentsToClear,
    },
  } = useRoute();
  const { t } = useTranslation();

  const { roomStore, hotelStore } = useStore();

  // If the room assignment is already assigned to another user
  const [localRoomAssignments, setLocalRoomAssignments] = useState(
    roomAssignments
  );
  const [
    localRoomAssignmentsToClear,
    setLocalRoomAssignmentsToClear,
  ] = useState(roomAssignmentsToClear);
  const [roomAssignmentToChange, setRoomAssignmentToChange] = useState(null);

  const checkoutRooms = [];
  const stayoverRooms = [];

  const toggleSelectRoom = ({ room }) => {
    const temp = cloneDeep(localRoomAssignments);
    const tempToClear = cloneDeep(localRoomAssignmentsToClear);

    // if was assigned to user, unassign it
    if (temp[user.id][room.id]) {
      // unassign,if it has a id, then clear it
      if (temp[user.id][room.id].id) {
        temp[user.id][room.id].clearedAt = moment()
          .tz(hotelStore.timeZone)
          .toISOString();
        tempToClear[temp[user.id][room.id].id] = temp[user.id][room.id];
      }
      delete temp[user.id][room.id];
    } else {
      const newRoomAssignment = {
        userId: user.id,
        roomId: room.id,
      };

      // edge case, unassign own room then reassign
      Object.keys(tempToClear).forEach((roomAssignmentId) => {
        if (
          tempToClear[roomAssignmentId].userId === user.id &&
          tempToClear[roomAssignmentId].roomId === room.id
        ) {
          // remove it from tempToClear
          delete tempToClear[roomAssignmentId];
          // add it back to temp
          newRoomAssignment.id = parseInt(roomAssignmentId, 10);
        }
      });
      // assign it to the user
      temp[user.id][room.id] = newRoomAssignment;
    }

    setLocalRoomAssignments(temp);
    setLocalRoomAssignmentsToClear(tempToClear);
  };

  roomStore.roomsInNumericalOrder.forEach((room) => {
    let existingRoomAssignment = null;
    Object.keys(localRoomAssignments).forEach((userId) => {
      if (
        userId.toString() !== user.id.toString() &&
        localRoomAssignments[userId][room.id]
      ) {
        existingRoomAssignment = localRoomAssignments[userId][room.id];
      }
    });

    const roomCard = (
      <SelectRoomCard
        key={room.id}
        width={screen.SCREEN_WIDTH_MAXED * 0.15}
        roomNumber={room.number}
        isSelected={!!localRoomAssignments[user.id][room.id]}
        alreadyAssigned={!!existingRoomAssignment}
        onPress={() => {
          if (existingRoomAssignment) {
            setRoomAssignmentToChange(existingRoomAssignment);
          } else {
            toggleSelectRoom({ room });
          }
        }}
      />
    );

    if (
      (room.vacancyStatus === VACANCY_STATUS.CHECKOUT ||
        room.vacancyStatus === VACANCY_STATUS.VACANT) &&
      room.cleanStatus === CLEAN_STATUS.DIRTY
    ) {
      checkoutRooms.push(roomCard);
    } else if (
      room.vacancyStatus === VACANCY_STATUS.STAYOVER &&
      room.cleanStatus === CLEAN_STATUS.DIRTY &&
      !room.doNotDisturb
    ) {
      stayoverRooms.push(roomCard);
    }
  });

  return (
    <>
      <Container paddingTop={insets.top}>
        <AssignRoomHeader title={`${user.firstName} ${user.lastName}`} />
        <ListContainer>
          <CheckoutStayoverText>
            {t(strings.CHECKOUT_ASSIGN_TEXT)} ({checkoutRooms.length})
          </CheckoutStayoverText>
          <RoomCardsContainer testID="Checkout">
            {checkoutRooms}
          </RoomCardsContainer>

          <CheckoutStayoverText>
            {t(strings.STAYOVER_ASSIGN_TEXT)} ({stayoverRooms.length})
          </CheckoutStayoverText>
          <RoomCardsContainer testID="Stayover">
            {stayoverRooms}
          </RoomCardsContainer>
        </ListContainer>
        <SubmitButtonFooter
          hideCancelButton
          submitButtonText={t(strings.OK)}
          onSubmit={() => {
            setRoomAssignments(localRoomAssignments);
            setRoomAssignmentsToClear(localRoomAssignmentsToClear);
            navigation.goBack();
          }}
        />
        {roomAssignmentToChange ? (
          <ChangeRoomAssignmentModal
            isOpen={!!roomAssignmentToChange}
            roomAssignmentToChange={roomAssignmentToChange}
            userToAssign={user}
            onClose={() => setRoomAssignmentToChange(null)}
            onChange={() => {
              const temp = cloneDeep(localRoomAssignments);
              const tempToClear = cloneDeep(localRoomAssignmentsToClear);

              // Remove the existing room assignment
              if (roomAssignmentToChange.id) {
                roomAssignmentToChange.clearedAt = moment()
                  .tz(hotelStore.timeZone)
                  .toISOString();
                tempToClear[roomAssignmentToChange.id] = roomAssignmentToChange;
              }
              delete temp[roomAssignmentToChange.userId][
                roomAssignmentToChange.roomId
              ];

              // Create a new room assignment for the user
              temp[user.id][roomAssignmentToChange.roomId] = {
                userId: user.id,
                roomId: roomAssignmentToChange.roomId,
              };

              setLocalRoomAssignments(temp);
              setLocalRoomAssignmentsToClear(tempToClear);
            }}
          />
        ) : null}
      </Container>
    </>
  );
});

SelectRoomScreen.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};
export default SelectRoomScreen;
