import React, { useContext } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';
import { Svg, Polygon } from 'react-native-svg';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { VACANCY_STATUS, CLEAN_STATUS, COLORS } from 'src/models/RoomStore';
import { useStore } from 'src/context/StoreContext';
import CleanStatusIcon from 'src/components/CleanStatusIcon';
import BlockedIcon from 'assets/icons/blocked.svg';
import DoNotDisturbIcon from 'assets/icons/do_not_disturb.svg';

export const testIds = {
  CARD: 'CARD',
  NUMBER: 'NUMBER',
  CLEAN_ICON: 'CLEAN_ICON',
  DIRTY_ICON: 'DIRTY_ICON',
  SHOULD_INSPECT_ICON: 'SHOULD_INSPECT_ICON',
  BLOCKED_ICON: 'BLOCKED_ICON',
  DO_NOT_DISTURB_ICON: 'DO_NOT_DISTURB_ICON',
  TRIANGLE: 'TRIANGLE',
  INCOMPLETE_TASK_COUNT: 'INCOMPLETE_TASK_COUNT',
};

export const CARD_WIDTH = screen.SCREEN_WIDTH_MAXED * 0.272;
const CARD_HEIGHT = screen.SCREEN_WIDTH_MAXED * 0.253;
export const CARD_MARGIN = CARD_WIDTH * 0.08;
const BORDER_RADIUS = 5;

const triangleWidth = CARD_WIDTH * 0.65;
const triangleHeight = CARD_HEIGHT * 0.65;
const p1 = `${CARD_WIDTH},${CARD_HEIGHT - triangleHeight}`;
const p2 = `${CARD_WIDTH - triangleWidth},${CARD_HEIGHT}`;
const p3 = `${CARD_WIDTH}, ${CARD_HEIGHT}`;
const trianglePoints = `${p1} ${p2} ${p3}`;

const Card = styled(TouchableOpacity)`
  background-color: ${({ hasError, theme }) =>
    hasError ? '#C7C7C7' : theme.colors.BACKGROUND_LIGHT};
  border-radius: ${BORDER_RADIUS}px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  width: ${CARD_WIDTH}px;
  height: ${CARD_HEIGHT}px;
  margin: ${CARD_MARGIN}px;
  elevation: 3;
  position: relative;
  border: ${(props) => (props.isShouldInspect ? '4px solid #F9A148' : 'none')};
`;

const Number = styled(Text)`
  ${({ theme }) => theme.textStyles.h2}
  padding-top: ${screen.SCREEN_WIDTH_MAXED * 0.03}px;
  padding-left: ${screen.SCREEN_WIDTH_MAXED * 0.025}px;
`;

const VacancyStatus = styled(Text)`
  ${({ theme }) => theme.textStyles.h5};
  padding-left: ${screen.SCREEN_WIDTH_MAXED * 0.03}px;
`;

const IconContainer = styled(View)`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

const TriangleContainer = styled(View)`
  position: absolute;
  top: ${(props) => (props.isShouldInspect ? '-7px' : '0.75px')};
  right: 0px;
  overflow: hidden;
  border-radius: ${(props) =>
    props.isShouldInspect ? '1px' : `${BORDER_RADIUS}px`};
`;

const TaskCountText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5};
  text-align: center;
  color: ${({ theme }) => theme.colors.SECONDARY};
`;

const TaskCountCircle = styled(View)`
  background-color: #d9465a;
  border-radius: 24px;
  width: 24px;
  height: 24px;
  position: absolute;
  right: ${(props) => (props.isShouldInspect ? '-11px' : '-8px')};
  top: ${(props) => (props.isShouldInspect ? '-11px' : '-8px')};
  justify-content: center;
  align-items: center;
`;

// eslint-disable-next-line react/prop-types
const getRoomColor = ({ cleanStatus, vacancyStatus, doNotDisturb }) => {
  if (vacancyStatus === VACANCY_STATUS.BLOCKED) {
    return COLORS.BLOCKED;
  }
  if (doNotDisturb) {
    return COLORS.DO_NOT_DISTURB;
  }
  switch (cleanStatus) {
    case CLEAN_STATUS.CLEAN:
      return COLORS.CLEAN;
    case CLEAN_STATUS.DIRTY:
      return COLORS.DIRTY;
    case CLEAN_STATUS.SHOULD_INSPECT:
      return COLORS.SHOULD_INSPECT;
    default:
      return '#ffffff';
  }
};

// eslint-disable-next-line react/prop-types
const RoomIcon = ({ cleanStatus, vacancyStatus, doNotDisturb }) => {
  const theme = useContext(ThemeContext);

  const iconWidth = screen.SCREEN_WIDTH_MAXED * 0.06;
  const iconHeight = screen.SCREEN_WIDTH_MAXED * 0.06;
  const iconColor = theme.colors.BLACK;

  if (vacancyStatus === VACANCY_STATUS.BLOCKED) {
    return (
      <BlockedIcon
        testID={testIds.BLOCKED_ICON}
        width={iconWidth}
        height={iconHeight}
        fill={iconColor}
      />
    );
  }

  if (doNotDisturb) {
    return (
      <DoNotDisturbIcon
        testID={testIds.DO_NOT_DISTURB_ICON}
        width={iconWidth}
        height={iconHeight}
        fill={iconColor}
      />
    );
  }

  return (
    <CleanStatusIcon
      cleanStatus={cleanStatus}
      width={iconWidth}
      height={iconHeight}
      fill={iconColor}
      // eslint-disable-next-line react/prop-types
      testID={`${cleanStatus.toUpperCase()}_ICON`}
    />
  );
};

const RoomCard = observer(({ onPress, room }) => {
  const { notificationStore } = useStore();
  const { t } = useTranslation();

  const getHasError = () => {
    const taskIds = room.tasks.map(({ id }) => id);

    return !!notificationStore.notificationsAsArray.find(
      ({ additionalId, additionalType }) => {
        switch (additionalType) {
          case 'Room':
            return additionalId === room.id;
          case 'Task':
            return taskIds.includes(additionalId);
          default:
            return false;
        }
      }
    );
  };

  const getVacancyStatusText = () => {
    switch (room.vacancyStatus) {
      case VACANCY_STATUS.STAYOVER:
        return t(strings.ROOM_STAYOVER_TEXT);
      case VACANCY_STATUS.BLOCKED:
        return t(strings.ROOM_BLOCKED_TEXT);
      case VACANCY_STATUS.VACANT:
        return t(strings.ROOM_VACANT_TEXT);
      case VACANCY_STATUS.CHECKOUT:
        return t(strings.ROOM_CHECKOUT_TEXT);
      default:
        return '';
    }
  };

  return (
    <Card
      testID={testIds.CARD}
      onPress={onPress}
      hasError={getHasError()}
      isShouldInspect={
        room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT &&
        room.vacancyStatus !== VACANCY_STATUS.BLOCKED
      }
    >
      {room.incompleteTasks.length > 0 && (
        <TaskCountCircle
          testID={testIds.INCOMPLETE_TASK_COUNT}
          isShouldInspect={
            room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT &&
            room.vacancyStatus !== VACANCY_STATUS.BLOCKED
          }
        >
          <TaskCountText>{room.incompleteTasks.length}</TaskCountText>
        </TaskCountCircle>
      )}
      <Number testID={testIds.NUMBER}>{room.number}</Number>
      <VacancyStatus>{getVacancyStatusText()}</VacancyStatus>
      <TriangleContainer
        isShouldInspect={
          room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT &&
          room.vacancyStatus !== VACANCY_STATUS.BLOCKED
        }
      >
        <Svg
          testID={testIds.TRIANGLE}
          height={CARD_HEIGHT}
          width={CARD_WIDTH}
          fill={getRoomColor(room)}
        >
          <Polygon points={trianglePoints} />
        </Svg>
        <IconContainer>
          <RoomIcon
            vacancyStatus={room.vacancyStatus}
            cleanStatus={room.cleanStatus}
            doNotDisturb={room.doNotDisturb}
          />
        </IconContainer>
      </TriangleContainer>
    </Card>
  );
});

RoomCard.propTypes = {
  onPress: PropTypes.func.isRequired,
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool,
  }).isRequired,
};

export default RoomCard;
