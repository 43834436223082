import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { View, ScrollView } from 'react-native';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import SelectActiveUserCheckbox from './components/SelectActiveUserCheckbox';
import SubmitButtonFooter from '../../../components/SubmitButtonFooter';
import AssignRoomHeader from './components/AssignRoomHeader';

const Container = styled(View)`
  flex: 1;
  justify-content: space-between;
`;

const UserListContainer = styled(ScrollView)`
  flex: 19;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  padding-top: 28px;
  padding-left: 19px;
  padding-right: 19px;
`;

const SelectActiveUserScreen = ({
  activeUsers,
  setActiveUsers,
  setActiveUsersCallback = () => {},
  onCancel = () => {},
} = {}) => {
  const { t } = useTranslation();
  const { userStore } = useStore();
  const [localActiveUsers, setLocalActiveUsers] = useState(activeUsers);

  const toggleCheckbox = ({ user }) => {
    if (user.id in localActiveUsers) {
      const tempLocalActiveUsers = {};
      Object.assign(tempLocalActiveUsers, localActiveUsers);
      delete tempLocalActiveUsers[user.id];
      setLocalActiveUsers(tempLocalActiveUsers);
    } else {
      setLocalActiveUsers({
        ...localActiveUsers,
        [user.id]: user,
      });
    }
  };

  const UserCheckboxes = () => {
    return userStore.usersAsArray.map((user) => {
      return (
        <SelectActiveUserCheckbox
          key={user.id}
          name={`${user.firstName} ${user.lastName}`}
          isSelected={user.id in localActiveUsers}
          onPress={() => {
            toggleCheckbox({ user });
          }}
        />
      );
    });
  };

  const onSubmit = () => {
    setActiveUsers(localActiveUsers);
    setActiveUsersCallback();
  };

  return (
    <Container>
      <AssignRoomHeader title={t(strings.WHO_IS_WORKING_TODAY_TEXT)} />
      <UserListContainer>
        <UserCheckboxes />
      </UserListContainer>
      <SubmitButtonFooter
        onCancel={onCancel}
        onSubmit={onSubmit}
        submitButtonText={t(strings.SET_ACTIVE_USERS_TEXT)}
        disabled={Object.keys(localActiveUsers).length === 0}
      />
    </Container>
  );
};

SelectActiveUserScreen.propTypes = {
  activeUsers: PropTypes.object.isRequired,
  setActiveUsers: PropTypes.func.isRequired,
  setActiveUsersCallback: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

export default SelectActiveUserScreen;
