/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { View } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import screen from 'src/constants/screen';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import Button from 'src/components/Button';

const Container = styled(View)`
  flex: 1;
  flex-direction: row;
  padding-top: ${(props) => props.paddingTop || screen.SCREEN_HEIGHT * 0.05}px;
  padding-bottom: ${(props) =>
    props.paddingBottom || screen.SCREEN_HEIGHT * 0.05}px;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: center;
  align-items: center;
  max-height: ${screen.SCREEN_HEIGHT * 0.1}px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const SubmitButton = styled(Button)`
  flex: 6;
`;

const CancelButton = styled(Button)`
  flex: 4;
`;

const SubmitButtonFooter = ({
  onSubmit = () => {},
  onCancel = () => {},
  cancelButtonText,
  submitButtonText,
  disabled = false,
  hideSubmitButton,
  hideCancelButton,
  ...props
} = {}) => {
  const { t } = useTranslation();
  const insets = useSafeAreaInsets();

  return (
    <Container
      paddingTop={insets.bottom}
      paddingBottom={insets.bottom}
      {...props}
    >
      {!hideCancelButton ? (
        <CancelButton
          testID="SUBMIT_BUTTON_FOOTER_CANCEL"
          onPress={onCancel}
          mode="text"
          label={cancelButtonText || t(strings.CANCEL)}
        />
      ) : null}
      {!hideSubmitButton ? (
        <SubmitButton
          testID="SUBMIT_BUTTON_FOOTER_SUBMIT"
          onPress={onSubmit}
          disabled={disabled}
          label={submitButtonText || t(strings.SUBMIT)}
        />
      ) : null}
    </Container>
  );
};

SubmitButtonFooter.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disabled: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
};

export default SubmitButtonFooter;
