import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components/native';
import { View, Text } from 'react-native';
import { Switch } from 'react-native-paper';

const Container = styled(View)`
  padding-right: 10px;
`;

const Label = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  margin-top: 40px;
  margin-bottom: 8px;
`;

const LabelSwitch = ({ labelText, testID, value, onValueChange }) => {
  const theme = useContext(ThemeContext);

  return (
    <Container>
      <Label>{labelText}</Label>
      <Switch
        testID={testID}
        value={value}
        onValueChange={onValueChange}
        color={theme.colors.RED}
        style={{
          alignSelf: 'flex-start',
        }}
      />
    </Container>
  );
};

LabelSwitch.propTypes = {
  labelText: PropTypes.string.isRequired,
  testID: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default LabelSwitch;
