import React, { useContext } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components/native';
import moment from 'moment';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { useStore } from 'src/context/StoreContext';
import { COMPLETE_STATUS, INCOMPLETE_STATUS } from 'src/models/TaskStore';
import SyncIcon from 'src/components/SyncIcon';
import Checkbox from 'src/components/Checkbox';

export const testIds = {
  CREATED_AT: 'CREATED_AT',
  COMPLETED_AT: 'COMPLETED_AT',
};

const TaskContainer = styled(View)`
  height: ${screen.SCREEN_WIDTH_MAXED * 0.2}px;
  padding-left: ${screen.SCREEN_WIDTH_MAXED * 0.03}px;
  padding-right: ${screen.SCREEN_WIDTH_MAXED * 0.02}px;
  margin-top: ${screen.SCREEN_WIDTH_MAXED * 0.02}px;
  margin-horizontal: ${screen.SCREEN_WIDTH * 0.03}px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.3);
  elevation: 3;
`;

const TextContainer = styled(View)`
  flex: 9;
`;

const Urgent = styled(Text)`
  ${({ theme }) => theme.textStyles.h6}
  font-family: ${({ theme }) => theme.fontFamily.semiBold};
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.RED};
`;
const Header = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
  text-decoration: ${({ completed }) => (completed ? 'line-through' : 'none')};
`;

const TimeStamp = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const CheckboxContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TaskCard = observer(({ task, onDialogOpen, hasRoomNumber }) => {
  const {
    id,
    description,
    createdAt,
    completedAt,
    roomId,
    status,
    urgent,
    isSyncing,
  } = task;
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { taskStore, userStore, roomStore } = useStore();
  const room = roomStore.rooms.get(roomId);
  const { authenticatedUser } = userStore;

  const toggleStatus = () => {
    const newStatus =
      status === INCOMPLETE_STATUS ? COMPLETE_STATUS : INCOMPLETE_STATUS;

    const taskUpdates = {
      status: newStatus,
    };
    taskStore.patchTask({
      taskId: id,
      options: {
        body: {
          task: taskUpdates,
        },
      },
    });
  };

  return (
    <TouchableOpacity onPress={onDialogOpen}>
      <TaskContainer>
        <TextContainer>
          {urgent && <Urgent>{t(strings.URGENT)}</Urgent>}
          <Header numberOfLines={1} completed={status === COMPLETE_STATUS}>
            {hasRoomNumber
              ? `${t(strings.ROOM_TEXT)} ${room.number}`
              : description[authenticatedUser.languagePreference]}
          </Header>
          {status === COMPLETE_STATUS && completedAt ? (
            <TimeStamp testID={testIds.COMPLETED_AT}>
              {`${t(strings.COMPLETED_TEXT)} ${moment
                .duration(moment() - moment(completedAt))
                .humanize()} ${t(strings.AGO_TEXT)}`}
            </TimeStamp>
          ) : (
            <TimeStamp testID={testIds.CREATED_AT}>
              {`${t(strings.CREATED_TEXT)} ${moment
                .duration(moment() - moment(createdAt))
                .humanize()} ${t(strings.AGO_TEXT)}`}
            </TimeStamp>
          )}
        </TextContainer>
        <CheckboxContainer>
          <Checkbox
            isChecked={status === COMPLETE_STATUS}
            onPress={toggleStatus}
          />
        </CheckboxContainer>
        <SyncIcon
          isVisible={isSyncing}
          width={screen.SCREEN_HEIGHT_MAXED * 0.025}
          height={screen.SCREEN_HEIGHT_MAXED * 0.025}
          color={theme.colors.TERTIARY}
          containerStyle={{
            position: 'absolute',
            bottom: screen.SCREEN_WIDTH_MAXED * 0.01,
            right: screen.SCREEN_WIDTH_MAXED * 0.01,
            height: screen.SCREEN_HEIGHT_MAXED * 0.025,
            width: screen.SCREEN_HEIGHT_MAXED * 0.025,
          }}
        />
      </TaskContainer>
    </TouchableOpacity>
  );
});

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    completedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    status: PropTypes.string.isRequired,
    urgent: PropTypes.bool,
  }).isRequired,
  onDialogOpen: PropTypes.func,
};

export default TaskCard;
