import React, { useContext, useState } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import { TextInput } from 'react-native-paper';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { screen } from 'src/constants';

import strings from 'src/constants/i18n/strings';
import CloseIcon from 'assets/icons/close.svg';
import Button from 'src/components/Button';
import LabelSwitch from 'src/components/LabelSwitch';
import TaskImage from './TaskImage';
import { ChosenTaskContent } from '../CreateTaskScreensStyles';

const ActiveButton = styled(Button)`
  flex: 9;
`;

const Label = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  margin-top: 40px;
  margin-bottom: 8px;
`;

export const TASK_DESCRIPTION = 'TASK_DESCRIPTION';
export const ADDITIONAL_NOTES_INPUT = 'ADDITIONAL_NOTES_INPUT';
export const SELECT_BUTTON = 'SELECT_BUTTON';
export const URGENT_TOGGLE = 'URGENT_TOGGLE';
export const DIRTY_TOGGLE = 'DIRTY_TOGGLE';
export const BLOCK_TOGGLE = 'BLOCK_TOGGLE';
export const LATER_TOGGLE = 'LATER_TOGGLE';

const FormInputs = ({
  isInspect,
  isUrgent,
  isBlock,
  isLater,
  setIsLater,
  setIsBlock,
  setIsUrgent,
  isDirty,
  setIsDirty,
  isEdit,
  task,
  description,
  additionalNotes,
  setAdditionalNotes,
  setDescription,
  setAssignedUser,
  assignedUser,
  setIsAssignModalOpen,
  navigation,
  isCreatingMultiple,
  rooms,
}) => {
  const theme = useContext(ThemeContext);
  const descriptionInputRef = React.useRef(null);
  const notesInputRef = React.useRef(null);
  const { t } = useTranslation();
  const [moreOptionsVisible, setMoreOptionsVisible] = useState(false);

  const buttonContainerStyle = {
    marginVertical: screen.SCREEN_HEIGHT_MAXED * 0.05,
    width: '80%',
    alignSelf: 'center',
    borderColor: theme.colors.DIM_GREY,
  };

  return (
    <ChosenTaskContent>
      {task?.isCustom || isEdit ? (
        <>
          <Label>{t(strings.TASK_DESCRIPTION_LABEL_TEXT)}</Label>
          <TextInput
            ref={descriptionInputRef}
            mode="outlined"
            value={description}
            disabled={isEdit}
            onChangeText={setDescription}
            placeholder={t(strings.TASK_DESCRIPTION_PLACEHOLDER_TEXT)}
            testID={TASK_DESCRIPTION}
          />
        </>
      ) : (
        <TaskImage task={task} />
      )}

      {isCreatingMultiple ? (
        <>
          <Label>{t(strings.ROOMS_SELECTED_TEXT)}</Label>
          <ActiveButton
            // testID={SELECT_BUTTON}
            onPress={navigation.goBack}
            label={`${rooms.length} ${t(strings.ROOMS_SELECTED_TEXT)}`}
            mode="outline"
          />
        </>
      ) : null}
      <Label>{t(strings.ASSIGN_TO_TEXT)}</Label>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <ActiveButton
          testID={SELECT_BUTTON}
          onPress={() => {
            /* eslint-disable-next-line no-unused-expressions */
            descriptionInputRef?.current?.blur();
            /* eslint-disable-next-line no-unused-expressions */
            notesInputRef?.current?.blur();
            setIsAssignModalOpen(true);
          }}
          label={
            assignedUser
              ? `${assignedUser.firstName} ${assignedUser.lastName}`
              : t(strings.ASSIGN_TO_BUTTON_TEXT)
          }
          mode={assignedUser ? 'outline' : 'normal'}
        />

        {assignedUser && (
          <TouchableOpacity
            style={{
              flex: 1,
              justifyContent: 'center',
              alignItems: 'center',
            }}
            onPress={() => setAssignedUser(null)}
          >
            <CloseIcon width={12} height={12} fill={theme.colors.RED} />
          </TouchableOpacity>
        )}
      </View>

      {moreOptionsVisible ? (
        <View
          style={{
            flexDirection: 'row',
          }}
        >
          <LabelSwitch
            labelText={t(strings.MARK_AS_URGENT_LABEL_TEXT)}
            testID={URGENT_TOGGLE}
            value={isUrgent}
            onValueChange={() => setIsUrgent(!isUrgent)}
          />

          <LabelSwitch
            labelText={t(strings.LATER_LABEL_TEXT)}
            testID={LATER_TOGGLE}
            value={isLater}
            onValueChange={() => setIsLater(!isLater)}
          />
          <LabelSwitch
            labelText={t(strings.BLOCK_ROOM_TEXT)}
            testID={BLOCK_TOGGLE}
            value={isBlock}
            onValueChange={() => setIsBlock(!isBlock)}
          />
        </View>
      ) : null}

      {moreOptionsVisible ? (
        <>
          <Label>{t(strings.ADDITIONAL_NOTES_TEXT)}</Label>
          <TextInput
            testID={ADDITIONAL_NOTES_INPUT}
            mode="outlined"
            value={additionalNotes}
            onChangeText={setAdditionalNotes}
            placeholder={t(strings.ADDITIONAL_NOTES_TEXT)}
          />
        </>
      ) : null}

      {isInspect ? (
        <>
          <LabelSwitch
            labelText={t(strings.MARK_AS_DIRTY_LABEL_TEXT)}
            testID={DIRTY_TOGGLE}
            value={isDirty}
            onValueChange={() => setIsDirty(!isDirty)}
          />
        </>
      ) : null}
      <View>
        {!moreOptionsVisible ? (
          <Button
            onPress={() => setMoreOptionsVisible(true)}
            label={t(strings.MORE_OPTIONS_TEXT)}
            mode="outline"
            containerStyle={buttonContainerStyle}
            labelStyle={{ textTransform: 'none', color: theme.colors.DIM_GREY }}
          />
        ) : null}
      </View>
    </ChosenTaskContent>
  );
};

FormInputs.propTypes = {
  isUrgent: PropTypes.bool,
  isInspect: PropTypes.bool,
  isDirty: PropTypes.bool,
  isBlock: PropTypes.bool,
  isCreatingMultiple: PropTypes.bool,
  setIsDirty: PropTypes.func.isRequired,
  setIsUrgent: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  task: PropTypes.object,
  description: PropTypes.string,
  setDescription: PropTypes.func.isRequired,
  assignedUser: PropTypes.object,
  setAssignedUser: PropTypes.func.isRequired,
  setIsBlock: PropTypes.func.isRequired,
  setIsAssignModalOpen: PropTypes.func.isRequired,
  additionalNotes: PropTypes.string,
  rooms: PropTypes.array,
  setAdditionalNotes: PropTypes.func.isRequired,
  isLater: PropTypes.bool.isRequired,
  setIsLater: PropTypes.func.isRequired,
  navigation: PropTypes.object,
};

export default FormInputs;
