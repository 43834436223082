import React, { useState, useRef } from 'react';
import { View, Text, Keyboard } from 'react-native';
import styled from 'styled-components/native';
import { TextInput } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import { SafeAreaView } from 'react-native-safe-area-context';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import LodgebookLogo from 'assets/icons/lodgebook_logo.svg';
import { screen } from 'src/constants';
import Button from 'src/components/Button';
import { useStore } from 'src/context/StoreContext';

export const testIds = {
  USERNAME: 'USERNAME',
  PASSWORD: 'PASSWORD',
  ERROR_MESSAGE: 'ERROR_MESSAGE',
  LOGIN_BUTTON: 'LOGIN_BUTTON',
};

const Container = styled(SafeAreaView)`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.PRIMARY};
`;

const ContentContainer = styled(KeyboardAwareScrollView).attrs(() => ({
  keyboardShouldPersistTaps: 'handled',
  keyboardDismissMode: 'on-drag',
  overScrollMode: 'always',
  contentContainerStyle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))``;

const FormContainer = styled(View)`
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
  width: 90%;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  elevation: 3;
  padding-left: ${screen.SCREEN_WIDTH * 0.06}px;
  padding-right: ${screen.SCREEN_WIDTH * 0.06}px;
  padding-top: ${screen.SCREEN_HEIGHT * 0.04}px;
  padding-bottom: ${screen.SCREEN_HEIGHT * 0.1}px;
`;

const LodgebookText = styled(Text)`
  ${({ theme }) => theme.textStyles.h1}
  color: ${({ theme }) => theme.colors.SECONDARY};
`;

const LogoContainer = styled(View)`
  display: flex;
  flex: 4;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FormInput = styled(TextInput)`
  width: 100%;
  margin-bottom: ${screen.SCREEN_WIDTH * 0.04}px;
  border-radius: 0px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
`;

const LoginScreen = observer(() => {
  const { authStore } = useStore();
  const { t } = useTranslation();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const passwordInputRef = useRef(null);

  const handleLogin = () => {
    Keyboard.dismiss();
    authStore.login({ username, password });
  };

  return (
    <Container>
      <ContentContainer>
        <LogoContainer>
          <LodgebookLogo
            width={screen.SCREEN_WIDTH_MAXED * 0.2}
            height={screen.SCREEN_WIDTH_MAXED * 0.2}
            fill="white"
          />
          <LodgebookText>Lodgebook</LodgebookText>
        </LogoContainer>
        <View
          style={{
            flex: 6,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <FormContainer>
            <FormInput
              testID={testIds.USERNAME}
              mode="outlined"
              label={t(strings.USERNAME)}
              placeholder={t(strings.USERNAME)}
              clearButtonMode="while-editing"
              value={username}
              onChangeText={setUsername}
              autoCapitalize="none"
              onSubmitEditing={() => {
                passwordInputRef.current.focus();
              }}
              blurOnSubmit={false}
            />
            <FormInput
              testID={testIds.PASSWORD}
              mode="outlined"
              label={t(strings.PASSWORD)}
              placeholder={t(strings.PASSWORD)}
              autoCompleteType="password"
              clearButtonMode="while-editing"
              value={password}
              onChangeText={setPassword}
              secureTextEntry
              ref={passwordInputRef}
              onSubmitEditing={handleLogin}
              returnKeyType="done"
            />
            <SubmitButton
              testID={testIds.LOGIN_BUTTON}
              label={t(strings.LOGIN).toUpperCase()}
              disabled={username.length === 0 || password.length === 0}
              onPress={handleLogin}
              loading={authStore.isLoading}
            />
          </FormContainer>
        </View>
      </ContentContainer>
    </Container>
  );
});

export default LoginScreen;
