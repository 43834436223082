import React, { useEffect, useState } from 'react';
import { Text, View, Platform } from 'react-native';
import styled from 'styled-components/native';
import { useNavigation } from '@react-navigation/native';
import { SafeAreaView } from 'react-native-safe-area-context';
import { observer } from 'mobx-react-lite';
import * as SecureStore from 'expo-secure-store';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { useStore } from 'src/context/StoreContext';
import { INCOMPLETE_STATUS } from 'src/models/TaskStore';
import { RoomDetailScreen, RoomFilterScreen } from 'src/screens/Room/routes';
import Button from 'src/components/Button';
import TopHeader from 'src/screens/Room/components/TopHeader';
import { USER_TYPE } from 'src/models/UserStore';
import RoomCard, { CARD_WIDTH, CARD_MARGIN } from './components/RoomCard';

const BLANK_FILTERS = {
  vacancyStatus: [],
  cleanStatus: [],
  doNotDisturb: false,
  assignedToMe: false,
};

const Container = styled(SafeAreaView).attrs(() => ({
  edges: ['top', 'left', 'right'],
}))`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const RoomList = styled.FlatList.attrs(() => ({
  contentContainerStyle: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30,
    paddingBottom: 30,
  },
}))`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  width: 100%;
`;

const FilterContainer = styled(View)`
  height: ${screen.SCREEN_WIDTH_MAXED * 0.19}px;
  padding: 16px;
  justify-content: space-evenly;
  flex-direction: row;
  border-bottom-width: 1.5px;
  border-color: rgba(0, 0, 0, 0.2);
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  z-index: 1;
`;

const FilterButton = styled(Button)`
  width: ${screen.SCREEN_WIDTH_MAXED * 0.4}px;
`;

const ClearFilterButton = styled(Button).attrs(({ disabled, theme }) => {
  if (!disabled) {
    return {
      labelStyle: {
        color: theme.colors.PRIMARY,
      },
    };
  }
  return {};
})`
  width: ${screen.SCREEN_WIDTH_MAXED * 0.5}px;
`;

const NoRoomsText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  margin-top: ${screen.SCREEN_WIDTH_MAXED * 0.05}px;
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const RoomScreen = observer(() => {
  const navigation = useNavigation();
  const { t } = useTranslation();

  const {
    fetchAll,
    isAnyFetchLoading,
    networkErrors,
    roomStore,
    userStore,
    roomAssignmentStore,
  } = useStore();
  const { authenticatedUser } = userStore;
  const [filters, setFilters] = useState(BLANK_FILTERS);

  const restoreFilters = async () => {
    if (authenticatedUser) {
      try {
        let userSettings;
        if (Platform.OS === 'web') {
          // eslint-disable-next-line no-undef
          userSettings = localStorage.getItem(authenticatedUser.id);
        } else {
          userSettings = await SecureStore.getItemAsync(
            authenticatedUser.id.toString()
          );
        }
        const jsonParsedUserSettings = JSON.parse(userSettings);
        if (
          jsonParsedUserSettings?.roomFilters &&
          authenticatedUser.userType === USER_TYPE.HOUSEKEEPER
        ) {
          setFilters({
            ...jsonParsedUserSettings.roomFilters,
            assignedToMe: true,
          });
        } else if (authenticatedUser.userType === USER_TYPE.HOUSEKEEPER) {
          setFilters({ ...BLANK_FILTERS, assignedToMe: true });
        } else if (jsonParsedUserSettings?.roomFilters) {
          setFilters(jsonParsedUserSettings.roomFilters);
        }
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const onClearFilter = async () => {
    setFilters(BLANK_FILTERS);
    try {
      let userSettings;
      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        userSettings = localStorage.getItem(authenticatedUser.id);
      } else {
        userSettings = await SecureStore.getItemAsync(
          authenticatedUser.id.toString()
        );
      }

      const parsedUserSettings = JSON.parse(userSettings);
      delete parsedUserSettings.roomFilters;

      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          authenticatedUser.id,
          JSON.stringify(parsedUserSettings)
        );
      } else {
        await SecureStore.setItemAsync(
          authenticatedUser.id.toString(),
          JSON.stringify(parsedUserSettings)
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const onRefresh = () => {
    fetchAll();
  };

  useEffect(() => {
    restoreFilters();
  }, [authenticatedUser]);

  let filterCount = filters.vacancyStatus.length + filters.cleanStatus.length;
  if (filters.doNotDisturb) filterCount++;
  if (filters.assignedToMe) filterCount++;

  const rooms = filterCount
    ? roomStore.roomsInNumericalOrder.filter((room) => {
        const vacancyStatusFilter =
          filters.vacancyStatus.length > 0
            ? filters.vacancyStatus.includes(room.vacancyStatus)
            : true;
        const cleanStatusFilter =
          filters.cleanStatus.length > 0
            ? filters.cleanStatus.includes(room.cleanStatus)
            : true;

        const doNotDisturbFilter = filters.doNotDisturb
          ? room.doNotDisturb
          : true;

        const assignedToMeFilter = filters.assignedToMe
          ? roomAssignmentStore
              .getRoomAssignmentsForUserAsArray(authenticatedUser.id)
              .some((roomAssignment) => roomAssignment.roomId === room.id) ||
            room.tasks.some((task) => {
              return (
                task.status === INCOMPLETE_STATUS &&
                task.taskAssignments.some((taskAssignment) => {
                  return taskAssignment.userId === authenticatedUser.id;
                })
              );
            })
          : true;

        return (
          vacancyStatusFilter &&
          cleanStatusFilter &&
          doNotDisturbFilter &&
          assignedToMeFilter
        );
      })
    : roomStore.roomsInNumericalOrder;

  const onListEmpty = () => {
    let emptyComponent;
    if (!isAnyFetchLoading) {
      if (networkErrors.length) {
        emptyComponent = (
          <NoRoomsText>
            {t(strings.NETWORK_ERROR_FAILED_TO_GET_ROOMS)}
          </NoRoomsText>
        );
      } else {
        emptyComponent = (
          <NoRoomsText>{t(strings.NO_ROOMS_TO_DISPLAY)}</NoRoomsText>
        );
      }
    }
    return emptyComponent;
  };

  return (
    <Container>
      <TopHeader />
      <FilterContainer>
        <FilterButton
          onPress={() => {
            navigation.navigate(RoomFilterScreen, {
              filters,
              setFilters,
            });
          }}
          label={t(strings.FILTER)}
        />
        <ClearFilterButton
          onPress={onClearFilter}
          disabled={filterCount <= 0}
          label={
            filterCount <= 0
              ? t(strings.FILTERS_APPLIED, {
                  count: filterCount,
                })
              : t(strings.CLEAR_FILTER, { count: filterCount })
          }
          mode="text"
        />
      </FilterContainer>
      <RoomList
        data={rooms}
        onRefresh={onRefresh}
        refreshing={isAnyFetchLoading}
        keyExtractor={({ id }) => id}
        renderItem={({ item }) => (
          <RoomCard
            onPress={() => {
              navigation.navigate(RoomDetailScreen, {
                roomId: item.id,
              });
            }}
            room={item}
          />
        )}
        key={screen.SCREEN_WIDTH}
        numColumns={Math.floor(
          screen.SCREEN_WIDTH / (CARD_WIDTH + 2 * CARD_MARGIN)
        )}
        ListEmptyComponent={onListEmpty()}
      />
    </Container>
  );
});

export default RoomScreen;
