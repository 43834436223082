/* eslint-disable react/jsx-filename-extension, no-use-before-define, react/jsx-props-no-spreading, global-require */
import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { Provider as PaperProvider, Portal } from 'react-native-paper';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as Notifications from 'expo-notifications';
import { Linking, Platform, StatusBar } from 'react-native';
import { createConsumer } from '@rails/actioncable';
// eslint-disable-next-line import/no-unresolved
import { API_HOST } from '@env';
import { onAction, getSnapshot } from 'mobx-state-tree';
import { debounce } from 'lodash';
import AsyncStorage from '@react-native-async-storage/async-storage';

import deepLinkConfig from './deepLinkConfig';
import StoreContext from './src/context/StoreContext';
import Store from './src/models/Store';
import { theme as navigationTheme } from './src/navigation';
import { paperTheme } from './src/constants';
import styledComponentsTheme from './src/constants/styledComponentsTheme';
import LBScreenSwitch from './LBScreenSwitch';
import ErrorBoundary from './src/components/ErrorBoundary';
import ActionCableContext from './src/context/ActionCableContext';

// required by @rails/actioncable
global.addEventListener = () => {};
global.removeEventListener = () => {};

const store = Store.create({});

const saveLatestSnapshot = debounce(async () => {
  const snapshot = getSnapshot(store);
  await AsyncStorage.setItem('mst-snapshot', JSON.stringify(snapshot));
}, 1000);

onAction(store, (call) => {
  const ignoredActions = ['register', 'restore', 'logout'];
  const shouldSaveSnapshot = !ignoredActions.some((ignoredAction) =>
    call.name.includes(ignoredAction)
  );

  if (shouldSaveSnapshot) {
    saveLatestSnapshot();
  }
});

const actionCableConsumer = createConsumer(
  `${API_HOST.replace('http', 'ws')}/cable`
);

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

export default function App() {
  const lastNotification = Notifications.useLastNotificationResponse();

  useEffect(() => {
    store.authStore.restoreCookie();
    Notifications.requestPermissionsAsync();
    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#20B2AA',
      });
    }
  }, []);

  const linking = {
    prefixes: [
      'lodgebook://',
      'https://mylodgebook.com',
      'https://*.mylodgebook.com',
    ],
    config: deepLinkConfig,
    async getInitialURL() {
      const url = await Linking.getInitialURL();
      if (url != null) {
        return url;
      }

      const notificationUrl =
        lastNotification?.notification?.request?.content?.data?.url;

      if (notificationUrl) {
        return `lodgebook:/${notificationUrl}`;
      }
      return undefined;
    },
    subscribe(listener) {
      const onReceiveURL = ({ url }) => listener(url);
      Linking.addEventListener('url', onReceiveURL);

      const subscription = Notifications.addNotificationResponseReceivedListener(
        (response) => {
          const { url } = response?.notification?.request?.content?.data;
          if (url) {
            listener(`lodgebook:/${url}`);
          }
        }
      );

      return () => {
        Linking.removeEventListener('url', onReceiveURL);
        subscription.remove();
      };
    },
  };

  return (
    <ErrorBoundary>
      <StatusBar translucent />
      <ActionCableContext.Provider value={actionCableConsumer}>
        <StoreContext.Provider value={store}>
          <SafeAreaProvider>
            <StyledComponentsThemeProvider theme={styledComponentsTheme}>
              <PaperProvider theme={paperTheme}>
                <Portal.Host>
                  <NavigationContainer
                    theme={navigationTheme}
                    linking={linking}
                  >
                    <LBScreenSwitch />
                  </NavigationContainer>
                </Portal.Host>
              </PaperProvider>
            </StyledComponentsThemeProvider>
          </SafeAreaProvider>
        </StoreContext.Provider>
      </ActionCableContext.Provider>
    </ErrorBoundary>
  );
}
