import { DefaultTheme } from '@react-navigation/native';

export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#ffffff',
    background: '#ffffff',
    card: '#20b2aa',
    text: '#ffffff',
  },
};
