import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components/native';
import { View, TouchableOpacity, Text } from 'react-native';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import CloseIcon from 'assets/icons/close.svg';
import TaskIcon from 'assets/icons/task.svg';
import BlockedTaskIcon from 'assets/icons/blockedtask.svg';
import { useStore } from 'src/context/StoreContext';
import { screen } from 'src/constants';
import { COMPLETE_STATUS } from 'src/models/TaskStore';

export const BLOCKED_TASK_ICON = 'BLOCKED_TASK_ICON';
export const TASK_ICON = 'TASK_ICON';

const Header = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
`;

const SubHeader = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  font-family: ${({ theme }) => theme.fontFamily.regular};
  margin-top: ${screen.SCREEN_WIDTH * 0.02}px;
`;

const Container = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`;

const TopRightIconContainer = styled(View)`
  margin-top: 3px;
`;

const TopLeftIconContainer = styled(View)`
  margin-top: ${screen.SCREEN_WIDTH * 0.005}px;
  margin-right: ${screen.SCREEN_WIDTH * 0.05}px;
`;

const TaskDetailDescription = observer(({ onClose, task, users }) => {
  const theme = useContext(ThemeContext);
  const { taskStore, userStore } = useStore();
  const { t } = useTranslation();

  const toggleUrgent = () => {
    taskStore.patchTask({
      taskId: task.id,
      options: {
        body: {
          task: { urgent: !task.urgent },
        },
      },
    });
  };

  let assignedName;
  if (task.taskAssignments.length > 0) {
    const assignedId =
      task.taskAssignments[task.taskAssignments.length - 1].userId;
    assignedName = users.get(assignedId).username;
  }

  return (
    <Container>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <TopLeftIconContainer>
          {task.urgent ? (
            <TouchableOpacity onPress={toggleUrgent}>
              <BlockedTaskIcon
                testID={BLOCKED_TASK_ICON}
                width={screen.SCREEN_WIDTH * 0.08}
                height={screen.SCREEN_WIDTH * 0.08}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={toggleUrgent}>
              <TaskIcon
                testID={TASK_ICON}
                width={screen.SCREEN_WIDTH * 0.08}
                height={screen.SCREEN_WIDTH * 0.08}
                fill={
                  task.status === COMPLETE_STATUS
                    ? theme.colors.PRIMARY
                    : theme.colors.BLACK
                }
              />
            </TouchableOpacity>
          )}
        </TopLeftIconContainer>
        <View
          style={{ display: 'flex', flexDirection: 'column', width: '85%' }}
        >
          <Header>
            {task.description[userStore.authenticatedUser.languagePreference]}
          </Header>
          <SubHeader>
            {assignedName
              ? `${t(strings.ASSIGNED_TO_TEXT)} ${assignedName}`
              : t(strings.UNASSIGNED_ASSIGN_TEXT)}
          </SubHeader>
        </View>
      </View>
      <TouchableOpacity onPress={onClose}>
        <TopRightIconContainer>
          <CloseIcon
            width={screen.SCREEN_WIDTH * 0.04}
            height={screen.SCREEN_WIDTH * 0.04}
            fill="#737373"
          />
        </TopRightIconContainer>
      </TouchableOpacity>
    </Container>
  );
});

TaskDetailDescription.propTypes = {
  onClose: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
};

export default TaskDetailDescription;
