import { screen } from 'src/constants';
import { css } from 'styled-components/native';

const theme = {
  colors: {
    PRIMARY: '#20b2aa',
    PRIMARY_DARK: '#16837A',
    PRIMARY_LIGHT: '#20b2ab42',
    SECONDARY: '#ffffff',
    TERTIARY: '#666666',
    BACKGROUND_LIGHT: '#ffffff',
    BACKGROUND_DARK: '#ececec',
    BACKGROUND_OPAQUE: '#FAFAFA',
    BACKGROUND_LIGHT_RED: '#eb4d603b',
    BACKGROUND_LIGHT_PRIMARY: '#20b2ab2f',
    BACKGROUND_LIGHT_YELLOW: '#f9a0483d',
    BACKGROUND_LIGHT_BLUE: '#20b2ab1e',
    GREEN: '#20b2aa',
    RED: '#eb4d5F',
    PINK: '#f6a4b2',
    ORANGE: '#f9a148',
    BLACK: '#000000',
    BLUE: '#95E6E4',
    DIM_GREY: '#696969',
    DISABLED: '#8E8E8E',
    BODY: '#C4C4C4',
  },

  fontFamily: {
    regular: 'Open Sans Regular',
    semiBold: 'Open Sans SemiBold',
    bold: 'Open Sans Bold',
  },

  rem: screen.SCREEN_WIDTH_MAXED * 0.1,
};

const textTheme = {
  textStyles: {
    h1: css`
      font-family: ${theme.fontFamily.semiBold};
      font-size: ${theme.rem * 0.9}px;
      color: ${theme.colors.BLACK};
    `,
    h2: css`
      font-family: ${theme.fontFamily.semiBold};
      font-size: ${theme.rem * 0.65}px;
      color: ${theme.colors.BLACK};
    `,
    h3: css`
      font-family: ${theme.fontFamily.semiBold};
      font-size: ${theme.rem * 0.5}px;
      color: ${theme.colors.BLACK};
    `,
    body1: css`
      font-family: ${theme.fontFamily.regular};
      font-size: ${theme.rem * 0.48}px;
      color: ${theme.colors.BODY};
    `,

    h4: css`
      font-family: ${theme.fontFamily.semiBold};
      font-size: ${theme.rem * 0.43}px;
      color: ${theme.colors.BLACK};
    `,
    body2: css`
      font-family: ${theme.fontFamily.regular};
      font-size: ${theme.rem * 0.35}px;
      color: ${theme.colors.BODY};
    `,
    h5: css`
      font-family: ${theme.fontFamily.regular};
      font-size: ${theme.rem * 0.33}px;
      color: ${theme.colors.BLACK};
    `,
    h6: css`
      font-family: ${theme.fontFamily.regular};
      font-size: ${theme.rem * 0.2}px;
      color: ${theme.colors.BLACK};
    `,
  },
};

export default {
  ...theme,
  ...textTheme,
  screen: { ...screen },
};
