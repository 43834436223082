import strings from './strings';

const reusableTranslations = {
  [strings.PLEASE_TRY_AGAIN]: {
    en: 'Please try again',
    es: 'Inténtalo de nuevo',
    zh: '请再试一次',
  },
  [strings.ROOM_UPDATE_ERROR_PREFIX]: {
    en: 'Unable to update room {{roomNumber}}',
    es: 'No se puede actualizar el cuarto {{roomNumber}}',
    zh: '无法更新房间{{roomNumber}}',
  },
  [strings.SOMETHING_WENT_WRONG]: {
    en: 'Something went wrong.',
    es: 'Algo salió mal.',
    zh: '出了些问题.',
  },
};

export default {
  ...reusableTranslations,
  [strings.ADD_A_TASK_TEXT]: {
    en: 'Add a Task',
    es: 'Agregar Trabajo',
    zh: '加工作',
  },
  [strings.ADDITIONAL_NOTES_PLACEHOLDER_TEXT]: {
    en: 'Add a note',
    es: 'Agregar una nota',
    zh: '內容',
  },
  [strings.ADDITIONAL_NOTES_TEXT]: {
    en: 'Additional Notes (optional)',
    es: 'Notas adicionales (opcionales)',
    zh: '而外須知 (可空白）',
  },
  [strings.AGO_TEXT]: {
    en: 'ago',
    es: '',
    zh: '前',
  },
  [strings.APPLY_ASSIGNMENTS_TEXT]: {
    en: 'APPLY ASSIGNMENTS',
    es: 'APLICAR ASIGNACIOES',
    zh: '確定分配',
  },
  [strings.APPLY_BUTTON_FILTER_TEXT]: {
    en: 'APPLY FILTER',
    es: 'APPLICAR FILTRO',
    zh: '應用',
  },
  [strings.ARE_YOU_SURE_YOU_WANT_TO_EXIT_TEXT]: {
    en: 'Are you sure you want to exit?',
    es: '¿Estás seguro de que quieres salir?',
    zh: '你确定要离开?',
  },
  [strings.ASSIGN_ERROR_TEXT]: {
    en: 'Unable to Assign Rooms',
    es: 'No se Pudo Asignar Los Cuartos',
    zh: '分配失敗',
  },
  [strings.ASSIGN_LOADING_TEXT]: {
    en: 'Assigning Rooms',
    es: 'Asignando cuartos',
    zh: '分配中',
  },
  [strings.ASSIGN_ROOMS_MENU_TEXT]: {
    en: 'Assign Rooms',
    es: 'Asignar Cuartos',
    zh: '房間分配',
  },
  [strings.ASSIGN_SUCCESS_TEXT]: {
    en: 'Rooms Assigned Successfully',
    es: 'Asignación de Cuartos Completado',
    zh: '分配成功',
  },
  [strings.ASSIGN_TO_BUTTON_TEXT]: {
    en: 'SELECT',
    es: 'SELECIONAR',
    zh: '員工',
  },
  [strings.ASSIGN_TO_TEXT]: {
    en: 'Assign To (optional)',
    es: 'Asignar a (opcional)',
    zh: '分配給 (可空白）',
  },
  [strings.ASSIGNED_TEXT]: {
    en: 'Assigned',
    es: 'Asignado',
    zh: '已分配',
  },
  [strings.CREATED_BY_TEXT]: {
    en: 'Created by',
    es: 'Creado por',
    zh: '創造',
  },
  [strings.ASSIGNED_TO_ME_FILTER_TEXT]: {
    en: 'Assigned to Me',
    es: 'Mis Trabajos',
    zh: '我的工作',
  },
  [strings.ASSIGNED_TO_TEXT]: {
    en: 'Assigned to',
    es: 'Asignado a',
    zh: '分配給',
  },
  [strings.BACK_BUTTON_CREATE_TEXT]: {
    en: 'Back',
    es: 'Salir',
    zh: '上一頁',
  },
  [strings.BLOCK_ROOM_TEXT]: {
    en: 'Block Room',
    es: 'Bloquear Cuarto',
    zh: '保留房間',
  },
  [strings.MORE_OPTIONS_TEXT]: {
    en: 'More Options',
    es: 'Mas Opciones',
    zh: '更多選擇',
  },
  [strings.ROOM_SORT_TEXT]: {
    en: 'Room',
    es: 'Cuarto',
    zh: '房间',
  },
  [strings.RECENT_SORT_TEXT]: {
    en: 'Most Recent',
    es: 'Más reciente',
    zh: '最近日期',
  },
  [strings.DESCRIPTION_SORT_TEXT]: {
    en: 'Description',
    es: 'Descripción',
    zh: '內容',
  },
  [strings.SORT_TASKS_TEXT]: {
    en: 'Sort tasks by',
    es: 'Ordenar trabajo',
    zh: '排序工作',
  },
  [strings.SORT_TEXT]: {
    en: 'Sort',
    es: 'Ordenar',
    zh: '種類',
  },
  [strings.RECENT_SORT_LABEL]: {
    en: 'Sort: Recent',
    es: 'Ordenar: Reciente',
    zh: '種類: 最近日期',
  },
  [strings.DESCRIPTION_SORT_LABEL]: {
    en: 'Sort: Description',
    es: 'Ordenar: Descripción',
    zh: '種類: 內容',
  },
  [strings.BLOCKED_FILTER_TEXT]: {
    en: 'Blocked',
    es: 'Bloqueado',
    zh: '停用',
  },
  [strings.CANCEL]: {
    en: 'Cancel',
    es: 'Cancelar',
    zh: '取消',
  },
  [strings.CHANGE_ACTIVE_STAFF_ASSIGN_TEXT]: {
    en: 'CHANGE ACTIVE STAFF',
    es: 'CAMBIA EMPLEADO ELEGIDO',
    zh: '修改在職員工',
  },
  [strings.CHANGE_TEXT]: {
    en: 'Change',
    es: 'Cambia',
    zh: '確定',
  },
  [strings.CHECKOUT_ASSIGN_TEXT]: {
    en: 'Checkout',
    es: 'Checkout',
    zh: '待付款',
  },
  [strings.CHECKOUT_FILTER_TEXT]: {
    en: 'Checkout',
    es: 'Checkout',
    zh: '待付款',
  },
  [strings.CHOOSE_A_STAFF_MEMBER_ASSIGN_TEXT]: {
    en: 'Choose a staff member',
    es: 'Escoger un empleado',
    zh: '請選一個員工',
  },
  [strings.CLEAN_FILTER_TEXT]: {
    en: 'Clean',
    es: 'Limpio',
    zh: '已打掃',
  },
  [strings.CLEAN_STATUS]: {
    en: 'Clean Status',
    es: 'Estado De Limpia',
    zh: '干净状态',
  },
  [strings.CLEAR_FILTER]: {
    en: 'Clear {{count}} Filter',
    es: 'Remover {{count}} Filtro',
    zh: '重置 {{count}} 個分類',
  },
  [`${strings.CLEAR_FILTER}_plural`]: {
    en: 'Clear {{count}} Filters',
    es: 'Remover {{count}} Filtros',
    zh: '重置 {{count}} 個分類',
  },
  [strings.AGO_TEXT_SPANISH]: {
    en: '',
    es: 'Hace ',
    zh: '',
  },
  [strings.COMPLETED_TEXT]: {
    en: 'Completed',
    es: 'Terminado hace',
    zh: '完成',
  },
  [strings.COMPLETED_TODAY_TEXT]: {
    en: 'Completed Today',
    es: 'Terminado Hoy',
    zh: '今天完成',
  },
  [strings.CREATE_A_NEW_TASK_TEXT]: {
    en: 'Create a New Task',
    es: 'Agregar Nuevo Trabajo',
    zh: '新工作',
  },
  [strings.COMPLETE_TASKS_PROMPT]: {
    en: 'Do you want to complete the tasks in this room?',
    es: 'Le gustaría completar el trabajo en este cuarto?',
    zh: '您要完成房間的工作嗎？',
  },
  [strings.YES_RESPONSE]: {
    en: 'Yes',
    es: 'Sí',
    zh: '要',
  },
  [strings.NO_RESPONSE]: {
    en: 'No',
    es: 'No',
    zh: '不要',
  },
  [strings.CREATE_BUTTON_CREATE_TEXT]: {
    en: 'Create Task',
    es: 'Crear Trabajo',
    zh: '創建',
  },
  [strings.CREATE_CUSTOM_TASK_TEXT]: {
    en: 'Custom Task',
    es: 'Nuevo Trabajo',
    zh: '新工作',
  },
  [strings.CREATE_ERROR_TITLE]: {
    en: 'Unable to Create Task',
    es: 'No Se Pudo Crear',
    zh: '創建失敗',
  },
  [strings.CREATE_LOADING_TITLE]: {
    en: 'Creating Task',
    es: 'Creando Trabajo',
    zh: '創建中',
  },
  [strings.CREATE_SUCCESS_TITLE]: {
    en: 'Task Created Successfully',
    es: 'Trabajo Creado',
    zh: '創建成功',
  },
  [strings.CREATED_TEXT]: {
    en: 'Created',
    es: 'Creado hace',
    zh: '創造',
  },
  [strings.DIRTY_FILTER_TEXT]: {
    en: 'Dirty',
    es: 'Sucio',
    zh: '未打掃',
  },
  [strings.DND_OFF_TEXT]: {
    en: 'OFF',
    es: 'OFF',
    zh: '關',
  },
  [strings.DND_ON_TEXT]: {
    en: 'ON',
    es: 'ON',
    zh: '開',
  },
  [strings.DO_NOT_DISTURB_FILTER_TEXT]: {
    en: 'Do Not Disturb',
    es: 'No Molestar',
    zh: '勿擾',
  },
  [strings.EDIT_BUTTON_TEXT]: {
    en: 'Edit Task',
    es: 'Editar Trabajo',
    zh: '修改',
  },
  [strings.EDIT_ERROR_TITLE]: {
    en: 'Unable to Edit Task',
    es: 'No Se Pudo Actualizar Trabajo',
    zh: '修改失敗',
  },
  [strings.EDIT_LOADING_TITLE]: {
    en: 'Editing Task',
    es: 'Actualizando Trabajo',
    zh: '修改中',
  },
  [strings.EDIT_SUCCESS_TITLE]: {
    en: 'Task Edited Successfully',
    es: 'Actualización Completado',
    zh: '修改成功',
  },
  [strings.EDIT_TASK_DETAIL_TEXT]: {
    en: 'EDIT TASK',
    es: 'EDITAR',
    zh: '修改',
  },
  [strings.EDIT_TASK_TEXT]: {
    en: 'Edit Task',
    es: 'Editar Trabajo',
    zh: '修改',
  },
  [strings.FILTER]: {
    en: 'Filter',
    es: 'Filtros',
    zh: '分類',
  },
  [strings.FILTER_COMPLETE_TEXT]: {
    en: 'COMPLETE',
    es: 'TERMINADO',
    zh: '完成',
  },
  [strings.FILTER_OPEN_TEXT]: {
    en: 'OPEN',
    es: 'INCOMPLETO',
    zh: '未完成',
  },
  [`${strings.FILTERS_APPLIED}`]: {
    en: '{{count}} Filters Applied',
    es: '{{count}} Filtros',
    zh: '{{count}}個分類',
  },
  [`${strings.FILTERS_APPLIED}_1`]: {
    en: '{{count}} Filter Applied',
    es: '{{count}} Filtro',
    zh: '{{count}}個分類',
  },
  [strings.INCOMPLETE_TEXT]: {
    en: 'Open Tasks',
    es: 'Trabajos Incompletos',
    zh: '未處理',
  },
  [strings.INSPECT_CLEAN_TEXT]: {
    en: 'ROOM IS CLEAN',
    es: 'LISTO',
    zh: '已打掃',
  },
  [strings.INSPECT_NOT_READY_TEXT]: {
    en: 'NOT READY',
    es: 'NO LISTO',
    zh: '還沒好',
  },
  [strings.INSPECT_TEXT]: {
    en: 'Ready to Inspect',
    es: 'Listo para Inspeccionar',
    zh: '準備檢查',
  },
  [strings.LATER_LABEL_TEXT]: {
    en: 'Later',
    es: 'Luego',
    zh: '稍后',
  },
  [strings.LOGIN]: {
    en: 'Login',
    es: 'Iniciar Sesión',
    zh: '登录',
  },
  [strings.LOGIN_CREDENTIALS_FAILURE_TEXT]: {
    en: 'Your credentials do not match our records',
    es: 'Sus credenciales no coinciden con nuestros registros',
    zh: '您的凭据与我们的记录不符',
  },
  [strings.LOGIN_NETWORK_FAILURE_TEXT]: {
    en: 'Something went wrong.',
    es: 'Algo salió mal.',
    zh: '出了些问题.',
  },
  [strings.LOG_OUT_MENU_TEXT]: {
    en: 'Log Out',
    es: 'Cerrar Sesión',
    zh: '登出',
  },
  [strings.MARK_AS_CLEAN_TEXT]: {
    en: 'Mark as Clean',
    es: 'Marcar Como Limpio',
    zh: '已打掃',
  },
  [strings.MARK_AS_COMPLETED_TASK_DETAIL_TEXT]: {
    en: 'MARK AS COMPLETED',
    es: 'COMPLETADO',
    zh: '完成',
  },
  [strings.MARK_AS_DIRTY_LABEL_TEXT]: {
    en: 'Mark as Dirty',
    es: 'Marcar Como Sucio',
    zh: '髒亂',
  },
  [strings.ADDITIONAL_NOTES_TEXT]: {
    en: 'Additional Notes',
    es: 'Notas Adicionales',
    zh: '筆記',
  },
  [strings.ROOMS_SELECTED_TEXT]: {
    en: 'Rooms Selected',
    es: 'Cuartos Seleccionadas',
    zh: '房間',
  },
  [strings.MARK_AS_DIRTY_TEXT]: {
    en: 'Mark as Dirty',
    es: 'Marcar Como Sucio',
    zh: '需打掃',
  },
  [strings.MARK_AS_URGENT_LABEL_TEXT]: {
    en: 'Urgent',
    es: 'urgente',
    zh: '緊急',
  },
  [strings.MENU_BAR_TEXT]: {
    en: 'This room is currently',
    es: 'Este cuarto es',
    zh: '這個房間',
  },
  [`${strings.MORE_THAN_THREE_NOTIFICATIONS_MESSAGE}`]: {
    en: `Unable to update {{count}} rooms, ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `{{count}} cuartos no se pueden actualizar. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `无法更新{{count}}个房间, ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.NETWORK_ERROR_FAILED_TO_GET_ROOMS]: {
    en: 'Failed to get rooms',
    es: 'No se pudieron obtener las cuartos',
    zh: '无法获得房间',
  },
  [strings.NO]: {
    en: 'No',
    es: 'No',
    zh: '不是',
  },
  [strings.NO_ROOMS_TO_DISPLAY]: {
    en: 'No rooms to display',
    es: 'No hay cuartos para mostrar',
    zh: '没有可显示的房间',
  },
  [strings.NO_TASKS_TEXT]: {
    en: 'No Tasks',
    es: 'No Hay Trabajos',
    zh: '無未完成工作',
  },
  [strings.OK]: {
    en: 'Ok',
    es: 'Okay',
    zh: '好',
  },
  [strings.PASSWORD]: {
    en: 'Password',
    es: 'Contraseña',
    zh: '密码',
  },
  [strings.PROMPT_TEXT]: {
    en: 'Would you like to change the assignment to ',
    es: '¿Le gustaría cambiar la asignación a ',
    zh: '是否確定要分配給  ',
  },
  [strings.REOPEN_TASK_TASK_DETAIL_TEXT]: {
    en: 'REOPEN TASK',
    es: 'MARCAR COMO INCOMPLETADO',
    zh: '未完成',
  },
  [strings.RETRY]: {
    en: 'Retry',
    es: 'Reintentar',
    zh: '重试',
  },
  [strings.ROOM_BLOCKED_TEXT]: {
    en: 'Blocked',
    es: 'Bloqueado',
    zh: '停用',
  },
  [strings.ROOM_CHECKOUT_TEXT]: {
    en: 'Checkout',
    es: 'Checkout',
    zh: '待付款',
  },
  [strings.ROOM_DETAILS_HEADER_CLEAN]: {
    en: 'Clean',
    es: 'Limpio',
    zh: '已打掃',
  },
  [strings.ROOM_DETAILS_HEADER_DIRTY]: {
    en: 'Dirty',
    es: 'Sucio',
    zh: '未打掃',
  },
  [strings.ROOM_DETAILS_HEADER_INSPECT]: {
    en: 'Inspect',
    es: 'Inspeccionar',
    zh: '待檢查',
  },
  [strings.ROOM_DND_TEXT]: {
    en: 'Do Not Disturb',
    es: 'No Molestar',
    zh: '勿擾',
  },
  [strings.ROOM_STATUS]: {
    en: 'Room Status',
    es: 'Estado De Cuarto',
    zh: '房间状态',
  },
  [strings.ROOM_STAYOVER_TEXT]: {
    en: 'Stayover',
    es: 'Stayover',
    zh: '入住',
  },
  [strings.ROOM_TEXT]: {
    en: 'Room',
    es: 'Cuarto',
    zh: '房间',
  },
  [strings.ROOM_TEXT_PLURAL]: {
    en: 'Rooms',
    es: 'Cuartos',
    zh: '房间',
  },

  [strings.ROOM_UPDATE_CLEAN_STATUS_TO_CLEAN_ERROR]: {
    en: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.en} to clean. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.es} para limpio. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.zh}进行清洁. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.ROOM_UPDATE_CLEAN_STATUS_TO_DIRTY_ERROR]: {
    en: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.en} to dirty. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.es} para sucia. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.zh}更新为脏. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.ROOM_UPDATE_DO_NOT_DISTURB_TO_OFF_ERROR]: {
    en: `Unable to turn off "Do not Disturb" for room {{roomNumber}}. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `No se puede desactivar "No molestar" para la cuarto {{roomNumber}}. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    zh: `无法关闭房间{{roomNumber}}的“请勿打扰”. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}`,
  },
  [strings.ROOM_UPDATE_DO_NOT_DISTURB_TO_ON_ERROR]: {
    en: `Unable to turn on "Do not Disturb" for room {{roomNumber}}. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `No se puede activar "No molestar" para la cuarto {{roomNumber}}. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `无法打开房间{{roomNumber}}的“请勿打扰”. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}`,
  },
  [strings.ROOM_UPDATE_VACANCY_STATUS_TO_BLOCKED_ERROR]: {
    en: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.en} to blocked. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.es} para bloqueado. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.zh}更新为停用. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.ROOM_UPDATE_VACANCY_STATUS_TO_VACANT_ERROR]: {
    en: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.en} to vacant. ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.es} para vacío. ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `${reusableTranslations.ROOM_UPDATE_ERROR_PREFIX.zh}更新为空置. ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.ROOM_VACANT_TEXT]: {
    en: 'Vacant',
    es: 'Vacío',
    zh: '空房',
  },
  [strings.SEARCH_ALL_TASKS_TEXT]: {
    en: 'Search All Tasks',
    es: 'Otra',
    zh: '搜尋',
  },
  [strings.SEARCH_PLACEHOLDER_TEXT]: {
    en: 'Search for a Task',
    es: 'Buscar Trabajo',
    zh: '搜尋指定工作',
  },
  [strings.SELECT_TASK_BUTTON_TEXT]: {
    en: 'Select Task',
    es: 'Seleccionar',
    zh: '確定',
  },
  [strings.SET_ACTIVE_USERS_TEXT]: {
    en: 'Set Active Users',
    es: 'Escoge Empleados',
    zh: '確認',
  },
  [strings.CHOOSE_ROOMS_TEXT]: {
    en: 'Choose Rooms',
    es: 'Escoge Cuartos',
    zh: '選房間',
  },
  [strings.NEVER_TEXT]: {
    en: 'Never',
    es: 'Nunca',
    zh: '沒發生',
  },
  [strings.STAYOVER_ASSIGN_TEXT]: {
    en: 'Stayover',
    es: 'Stayover',
    zh: '入住',
  },
  [strings.STAYOVER_FILTER_TEXT]: {
    en: 'Stayover',
    es: 'Stayover',
    zh: '入住',
  },
  [strings.SUBMIT]: {
    en: 'Submit',
    es: 'Entregar',
    zh: '递交',
  },
  [strings.TASK_DESCRIPTION_LABEL_TEXT]: {
    en: 'Task Description',
    es: 'Descripción de Trabajo',
    zh: '工作內容',
  },
  [strings.TASK_DESCRIPTION_PLACEHOLDER_TEXT]: {
    en: 'What needs to be done?',
    es: '¿Qué necesita ser completado?',
    zh: '須知',
  },
  [strings.TASK_HISTORY_HEADER_TEXT]: {
    en: 'Completed Tasks',
    es: 'Trabajo Terminado',
    zh: '已完成工作',
  },
  [strings.TASK_TEXT_PLURAL]: {
    en: 'Tasks',
    es: 'Trabajos',
    zh: '工作',
  },
  [strings.TASK_UPDATE_STATUS_TO_COMPLETE_ERROR]: {
    en: `Unable to complete the task for room {{roomNumber}}, ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `No se pudo completar la trabajo de la cuarto {{roomNumber}}, ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `无法完成房间{{roomNumber}}的工作, ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.TASK_UPDATE_STATUS_TO_INCOMPLETE_ERROR]: {
    en: `Unable to open the task for room {{roomNumber}}, ${reusableTranslations.PLEASE_TRY_AGAIN.en}.`,
    es: `No se puede abrir la trabajo para la cuarto {{roomNumber}}, ${reusableTranslations.PLEASE_TRY_AGAIN.es}.`,
    zh: `无法打开房间{{roomNumber}}的工作, ${reusableTranslations.PLEASE_TRY_AGAIN.zh}.`,
  },
  [strings.TURN_OFF_DND_TEXT]: {
    en: 'Turn OFF Do Not Disturb',
    es: 'Remover No Molesta',
    zh: '關 勿擾',
  },
  [strings.TURN_ON_DND_TEXT]: {
    en: 'Turn ON Do Not Disturb',
    es: 'Marcar Como No Molesta',
    zh: '非請勿擾',
  },
  [strings.UNASSIGNED_ASSIGN_TEXT]: {
    en: 'Unassigned',
    es: 'No Asignado',
    zh: '不在職',
  },
  [strings.UNBLOCK_CLEAN_TEXT]: {
    en: 'Unblock as Clean',
    es: 'Abrir y Marcar Como Limpio',
    zh: '可以使用',
  },
  [strings.UNBLOCK_DIRTY_TEXT]: {
    en: 'Unblock as Dirty',
    es: 'Abrir y Marcar Como Sucio',
    zh: '需打掃',
  },
  [strings.UNBLOCK_ROOM_TEXT]: {
    en: 'Unblock Room',
    es: 'Abrir Cuarto',
    zh: '可以使用',
  },
  [strings.UPDATE_TASK_BUTTON_TEXT]: {
    en: 'Update Task',
    es: 'Actualizar',
    zh: '更新',
  },
  [strings.URGENT]: {
    en: 'URGENT',
    es: 'URGENTE',
    zh: '緊急',
  },
  [strings.USERNAME]: {
    en: 'Username',
    es: 'Nombre de Usuario',
    zh: '用户名',
  },
  [strings.VACANT_FILTER_TEXT]: {
    en: 'Vacant',
    es: 'Vacío',
    zh: '空房',
  },
  [strings.WHO_IS_WORKING_TODAY_TEXT]: {
    en: 'Who is working today?',
    es: '¿Quién trabaja hoy?',
    zh: '在職員工',
  },
  [strings.YES]: {
    en: 'Yes',
    es: 'Sí',
    zh: '是',
  },
  [strings.YOU_HAVE_UNSAVED_CHANGES_TEXT]: {
    en: 'You have unsaved changes',
    es: 'Tiene cambios sin guardar',
    zh: '您有未保存的更改',
  },
};
