/* eslint-disable import/prefer-default-export, global-require */
import * as SecureStore from 'expo-secure-store';
import { Platform } from 'react-native';

// eslint-disable-next-line import/no-unresolved
import { API_HOST } from '@env';
import { camelCase, snakeCase } from 'lodash';

let CookieManager;
if (Platform.OS !== 'web') {
  CookieManager = require('@react-native-community/cookies');
}

const fullUrl = (url) => `${API_HOST}${url}`;

const httpRequestOptions = async (method, optionsWithDefaults) => {
  const { body, signal } = optionsWithDefaults;
  let options = {};
  if (body) {
    options = {
      method,
      body: JSON.stringify(snakeifyKeys(body)),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      credentials: 'include',
    };
  } else {
    options = { method, credentials: 'include', headers: {} };
  }
  if (signal) options.signal = signal;

  if (Platform.OS !== 'web') {
    const cookie = await SecureStore.getItemAsync('set-cookie');

    if (cookie) {
      await CookieManager.clearAll();
      options.headers.cookie = cookie;
    }
  }

  return options;
};

const defaultOptions = {
  parseResponse: true,
  body: null,
  signal: null,
};
/*  Given a Lodgebook API URL, an HTTP request method, and an `options` object, sends an HTTP request to the Lodgebook API via the Web Fetch API.
`options`:
- body: JSON - HTTP Request body - default null
- parseResponse: Boolean - When true, will attempt to convert response to JSON and return that. Default: true
- signal: AbortSignal - generated by a AbortController instance to tell fetch to cancel early when `AbortController.abort()` is called

Return:
- JSON Body - When fetch successfully returns with an HTTP status code of 200-299
- null - when `parseResponse` is false, or undefined in the options.

Thrown Errors:
- Web Fetch API Response~ - When fetch returns with an HTTP status code of 300-500
- String - When fetch throws an error (i.e. NetworkError), or encounter an error trying to parse the json of the response.

~See https://developer.mozilla.org/en-US/docs/Web/API/Response for Fetch Response schema.
~See https://developer.mozilla.org/en-US/docs/Web/API/AbortController for more information about Aborting fetch request
*/
export const request = async (url, method, options = {}) => {
  const optionsWithDefaults = { ...defaultOptions };
  Object.assign(optionsWithDefaults, options);
  try {
    const response = await fetch(
      fullUrl(url),
      await await httpRequestOptions(method, optionsWithDefaults)
    );
    if (!response.ok) {
      throw response;
    }
    try {
      return optionsWithDefaults.parseResponse
        ? camelizeKeys(await response.json())
        : response;
    } catch (jsonParsingError) {
      console.warn(
        `${method} request to ${fullUrl(url)} did not return JSON response`
      );
      throw jsonParsingError;
    }
  } catch (error) {
    console.warn(
      `${method} request to ${fullUrl(
        url
      )} resulted in error with status text: ${error}`
    );
    throw error;
  }
};

const snakeifyKeys = (obj) => {
  return transformKeys(obj, snakeCase);
};

const camelizeKeys = (obj) => {
  return transformKeys(obj, camelCase);
};

const transformKeys = (obj, transform) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => transformKeys(v, transform));
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [transform(key)]: transformKeys(obj[key], transform),
      }),
      {}
    );
  }
  return obj;
};
