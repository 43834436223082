import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { USER_TYPE } from 'src/models/UserStore';
import MarkAsCleanIcon from 'assets/icons/clean.svg';
import MarkAsDirtyIcon from 'assets/icons/dirty.svg';
import { CLEAN_STATUS, VACANCY_STATUS } from 'src/models/RoomStore';
import MenuItem from './MenuItem';

export const MARK_AS_CLEAN = 'Mark as Clean';
export const MARK_AS_DIRTY = 'Mark as Dirty';

const CleanStatusMenuItem = ({
  room,
  updateRoom,
  handleSheetOpen,
  showAlert,
  userType,
}) => {
  const { t } = useTranslation();

  if (
    room.cleanStatus === CLEAN_STATUS.CLEAN ||
    (room.cleanStatus === CLEAN_STATUS.SHOULD_INSPECT &&
      userType === USER_TYPE.HOUSEKEEPER)
  ) {
    return (
      <MenuItem
        color="#E57A3B"
        testID={MARK_AS_DIRTY}
        title={t(strings.MARK_AS_DIRTY_TEXT)}
        icon={<MarkAsDirtyIcon />}
        onPress={() => {
          updateRoom({
            roomId: room.id,
            options: {
              body: {
                room: {
                  cleanStatus: CLEAN_STATUS.DIRTY,
                },
              },
            },
          });
          handleSheetOpen();
        }}
      />
    );
  }
  return (
    <MenuItem
      color="#16837A"
      testID={MARK_AS_CLEAN}
      title={t(strings.MARK_AS_CLEAN_TEXT)}
      icon={<MarkAsCleanIcon />}
      onPress={() => {
        let newStatus = CLEAN_STATUS.CLEAN;
        if (userType === USER_TYPE.HOUSEKEEPER) {
          newStatus =
            room.vacancyStatus === VACANCY_STATUS.STAYOVER
              ? CLEAN_STATUS.CLEAN
              : CLEAN_STATUS.SHOULD_INSPECT;
        }
        updateRoom({
          roomId: room.id,
          options: {
            body: {
              room: {
                cleanStatus: newStatus,
              },
            },
          },
        });
        if (room.incompleteTasks.length > 0) {
          showAlert();
        }
        handleSheetOpen();
      }}
    />
  );
};

CleanStatusMenuItem.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
    incompleteTasks: PropTypes.array.isRequired,
  }).isRequired,
  handleSheetOpen: PropTypes.func,
  updateRoom: PropTypes.func,
  showAlert: PropTypes.func,
  userType: PropTypes.string,
};

export default CleanStatusMenuItem;
