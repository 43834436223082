import React, { useEffect, useState, useMemo } from 'react';
import { View, ScrollView, Text } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { useNavigation } from '@react-navigation/native';
import Button from 'src/components/Button';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';
import NetworkLoadingModal from 'src/components/NetworkLoadingModal';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { cloneDeep, isEqual } from 'lodash';
import AssignRoomHeader from './components/AssignRoomHeader';
import SelectActiveUserScreen from './SelectActiveUserScreen';
import ActiveUserCard from './components/ActiveUserCard';

let abortController;

const Container = styled(View)`
  flex: 1;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.SECONDARY};
`;

const ActiveUsersContainer = styled(ScrollView)`
  flex: 19;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
`;

const ChangeActiveStaffButton = styled(Button)`
  margin: 16px;
`;

const UnassignedCountTextContainer = styled(View)`
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  padding: 8px 12px;
`;

const UnassignedCountText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  font-family: ${({ bold, theme }) =>
    bold ? theme.fontFamily.semiBold : theme.fontFamily.regular};
`;

const getRoomAssignmentsGroupedByUser = (roomAssignmentArray) => {
  return roomAssignmentArray.reduce((map, { id, roomId, userId }) => {
    if (!map[userId]) {
      map[userId] = {};
    }

    map[userId][roomId] = {
      id,
      roomId,
      userId,
    };
    return map;
  }, {});
};

const ActiveUserScreen = observer(({ activeUsers, setActiveUsers }) => {
  const { roomAssignmentStore, roomStore } = useStore();
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [roomAssignments, setRoomAssignments] = useState(
    getRoomAssignmentsGroupedByUser(roomAssignmentStore.activeRoomAssignments)
  );
  const [originalRoomAssignments] = useState(roomAssignments);
  const [hasChangesBeenMade, setHasChangesBeenMade] = useState(false);
  const [roomAssignmentsToClear, setRoomAssignmentsToClear] = useState({});
  const [
    isNetworkLoadingModalVisible,
    setIsNetworkLoadingModalVisible,
  ] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(
    false
  );

  const activeUsersAsArray = useMemo(() => {
    return Object.keys(activeUsers).map((userId) => activeUsers[userId]);
  }, [activeUsers]);

  const roomAssignmentsAsArray = useMemo(() => {
    const temp = [];
    Object.keys(roomAssignments).forEach((userId) => {
      Object.keys(roomAssignments[userId]).forEach((roomId) => {
        temp.push(roomAssignments[userId][roomId]);
      });
    });
    return temp;
  }, [roomAssignments]);

  const roomAssignmentsAsObjectByRoomId = useMemo(() => {
    const temp = {};
    Object.keys(roomAssignments).forEach((userId) => {
      Object.keys(roomAssignments[userId]).forEach((roomId) => {
        temp[roomId] = roomAssignments[userId][roomId];
      });
    });
    return temp;
  }, [roomAssignments]);

  useEffect(() => {
    const temp = cloneDeep(roomAssignments);
    Object.keys(activeUsers).forEach((userId) => {
      if (!temp[userId]) {
        temp[userId] = {};
      }
    });
    setRoomAssignments(temp);
  }, [activeUsers]);

  useEffect(() => {
    setHasChangesBeenMade(!isEqual(roomAssignments, originalRoomAssignments));
  }, [roomAssignments]);

  const dirtyRooms = roomStore.roomsAsArray.filter(
    (room) => room.cleanStatus === 'dirty'
  );

  const unassignedRooms = dirtyRooms.filter(({ id: roomId }) => {
    return !roomAssignmentsAsObjectByRoomId[roomId];
  });

  const stayoverRoomCount = unassignedRooms.filter(
    (room) =>
      room.vacancyStatus === 'stayover' &&
      room.cleanStatus === 'dirty' &&
      !room.doNotDisturb
  ).length;

  const checkoutRoomCount = unassignedRooms.filter(
    (room) =>
      (room.vacancyStatus === 'vacant' || room.vacancyStatus === 'checkout') &&
      room.cleanStatus === 'dirty'
  ).length;

  const [
    isSelectActiveUserScreenVisible,
    setIsSelectActiveUserScreenVisible,
  ] = useState(false);

  if (isSelectActiveUserScreenVisible) {
    return (
      <SelectActiveUserScreen
        activeUsers={activeUsers}
        setActiveUsers={setActiveUsers}
        setActiveUsersCallback={() => {
          setIsSelectActiveUserScreenVisible(false);
        }}
        onCancel={() => {
          setIsSelectActiveUserScreenVisible(false);
        }}
      />
    );
  }

  const onSubmit = () => {
    setIsNetworkLoadingModalVisible(true);
    // eslint-disable-next-line no-undef
    abortController = new AbortController();
    const roomAssignmentsToSend = [];

    roomAssignmentsAsArray.forEach((roomAssignment) => {
      if (!roomAssignment.id) {
        roomAssignmentsToSend.push(roomAssignment);
      }
    });
    Object.keys(roomAssignmentsToClear).forEach((roomAssignmentId) => {
      roomAssignmentsToSend.push(roomAssignmentsToClear[roomAssignmentId]);
    });

    // signal and loading modal
    roomAssignmentStore.updateRoomAssignments({
      roomAssignments: roomAssignmentsToSend,
      signal: abortController.signal,
    });
  };

  const ActiveUserCards = () =>
    activeUsersAsArray.map((activeUser) => {
      return (
        <ActiveUserCard
          user={activeUser}
          key={activeUser.id}
          roomAssignments={roomAssignments}
          onPress={() =>
            navigation.navigate('SelectRoomScreen', {
              user: activeUser,
              roomAssignments,
              setRoomAssignments,
              roomAssignmentsToClear,
              setRoomAssignmentsToClear,
            })
          }
        />
      );
    });

  return (
    <Container>
      <ConfirmationModal
        isVisible={isConfirmationModalVisible}
        onCancel={() => setIsConfirmationModalVisible(false)}
        onOk={() => {
          navigation.goBack();
        }}
        title={t(strings.YOU_HAVE_UNSAVED_CHANGES_TEXT)}
        prompt={t(strings.ARE_YOU_SURE_YOU_WANT_TO_EXIT_TEXT)}
        cancelButtonText={t(strings.NO)}
        okButtonText={t(strings.YES)}
      />
      <NetworkLoadingModal
        isVisible={isNetworkLoadingModalVisible}
        isLoading={roomAssignmentStore.isFetchingOne}
        networkError={roomAssignmentStore.networkError}
        loadingTitle={t(strings.ASSIGN_LOADING_TEXT)}
        errorTitle={t(strings.ASSIGN_ERROR_TEXT)}
        successTitle={t(strings.ASSIGN_SUCCESS_TEXT)}
        onAbort={() => abortController.abort()}
        onRetry={onSubmit}
        onCancel={() => setIsNetworkLoadingModalVisible(false)}
        onOk={() => {
          setIsNetworkLoadingModalVisible(false);
          navigation.goBack();
        }}
        onDismissNetworkError={roomAssignmentStore.dismissNetworkError}
      />
      <AssignRoomHeader title={t(strings.CHOOSE_A_STAFF_MEMBER_ASSIGN_TEXT)}>
        <ChangeActiveStaffButton
          onPress={() => {
            setIsSelectActiveUserScreenVisible(true);
          }}
          label={`${t(strings.CHANGE_ACTIVE_STAFF_ASSIGN_TEXT)} (${
            Object.keys(activeUsers).length
          })`}
        />
      </AssignRoomHeader>
      <UnassignedCountTextContainer>
        <UnassignedCountText>
          {`${t(strings.UNASSIGNED_ASSIGN_TEXT)}: ${checkoutRoomCount} ${t(
            strings.CHECKOUT_ASSIGN_TEXT
          )} | ${stayoverRoomCount} ${t(strings.STAYOVER_ASSIGN_TEXT)}`}
        </UnassignedCountText>
      </UnassignedCountTextContainer>
      <ActiveUsersContainer>
        <ActiveUserCards />
      </ActiveUsersContainer>
      <SubmitButtonFooter
        onSubmit={onSubmit}
        disabled={!hasChangesBeenMade}
        onCancel={() => {
          // Only show this if there are actually any changes
          if (hasChangesBeenMade) {
            setIsConfirmationModalVisible(true);
          } else {
            navigation.goBack();
          }
        }}
      />
    </Container>
  );
});

ActiveUserScreen.propTypes = {
  activeUsers: PropTypes.object.isRequired,
  setActiveUsers: PropTypes.func.isRequired,
};

export default ActiveUserScreen;
