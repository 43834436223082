import { types, flow, getRoot } from 'mobx-state-tree';
import { values } from 'mobx';
import moment from 'moment-timezone';
import i18n from 'i18next';
import { cloneDeep, merge } from 'lodash';

import { request } from 'src/utils/LodgebookAPIClient';
import theme from 'src/constants/styledComponentsTheme';
import addAttributeTimestamps from 'src/utils/addAttributeTimestamps';
import { strings } from 'src/constants/i18n';
import { INCOMPLETE_STATUS, COMPLETE_STATUS, LATER_STATUS } from './TaskStore';

export const ROOMS_URL = '/rooms';
export const UPDATE_ROOM_URL = '/rooms';

export const VACANCY_STATUS = {
  VACANT: 'vacant',
  CHECKOUT: 'checkout',
  STAYOVER: 'stayover',
  BLOCKED: 'blocked',
};

export const CLEAN_STATUS = {
  CLEAN: 'clean',
  DIRTY: 'dirty',
  SHOULD_INSPECT: 'should_inspect',
};

export const DO_NOT_DISTURB = 'Do Not Disturb';

export const COLORS = {
  CLEAN: theme.colors.GREEN,
  DIRTY: theme.colors.ORANGE,
  SHOULD_INSPECT: theme.colors.BLUE,
  BLOCKED: theme.colors.RED,
  DO_NOT_DISTURB: theme.colors.PINK,
};

export const Room = types
  .model('Room', {
    id: types.identifierNumber,
    number: types.string,
    vacancyStatus: types.enumeration(Object.values(VACANCY_STATUS)),
    cleanStatus: types.enumeration(Object.values(CLEAN_STATUS)),
    doNotDisturb: types.boolean,
    updatedAt: types.maybeNull(types.string),
    isSyncing: types.optional(types.boolean, false),
    attributeTimestamps: types.maybeNull(types.string),
  })
  .views((self) => ({
    get tasks() {
      const root = getRoot(self);
      return root.taskStore.tasksAsArray.filter(
        (task) => task.roomId === self.id
      );
    },

    get laterTasks() {
      const filteredTasks = self.tasks.filter(
        (task) => task.status === LATER_STATUS && task.roomId === self.id
      );
      return filteredTasks.sort((a, b) => b.createdAt - a.createdAt);
    },

    get incompleteTasks() {
      const filteredTasks = self.tasks.filter(
        (task) => task.status === INCOMPLETE_STATUS && task.roomId === self.id
      );
      return filteredTasks.sort((a, b) => {
        return b.urgent - a.urgent || b.createdAt - a.createdAt;
      });
    },

    get completeTasks() {
      const filteredTasks = self.tasks.filter(
        (task) => task.status === COMPLETE_STATUS && task.roomId === self.id
      );
      return filteredTasks.sort((a, b) => b.createdAt - a.createdAt);
    },

    get completedTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      const filteredTasks = self.tasks.filter(
        (task) =>
          task.status === COMPLETE_STATUS &&
          timeZone &&
          task.completedAt &&
          moment(task.completedAt)
            .tz(timeZone)
            .isSame(moment().tz(timeZone), 'day')
      );
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },

    get tasksCompletedLastMonth() {
      const filteredTasks = self.tasks.filter((task) => {
        if (task.status !== COMPLETE_STATUS || !task.completedAt) {
          return false;
        }
        const completedAtMoment = moment(task.completedAt);
        const taskCompletedInLastMonth = completedAtMoment.isSameOrAfter(
          moment().subtract(30, 'days'),
          'day'
        );
        return taskCompletedInLastMonth;
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
  }));

const RoomStore = types
  .model('RoomStore', {
    rooms: types.optional(types.map(Room), {}),
    isFetchingAll: types.optional(types.boolean, false),
    isFetchingOne: types.optional(types.boolean, false),
    networkError: types.maybe(types.string),
  })
  .views((self) => ({
    get roomsAsArray() {
      return values(self.rooms);
    },
    get incompleteTasks() {
      const filteredTasks = self.tasks.filter(
        (task) => task.status === INCOMPLETE_STATUS && task.roomId === self.id
      );
      return filteredTasks.sort((a, b) => b.createdAt - a.createdAt);
    },
    get completedTodayTasks() {
      const root = getRoot(self);
      const { timeZone } = root.hotelStore;
      const filteredTasks = self.tasks.filter((task) => {
        return (
          task.status === COMPLETE_STATUS &&
          (!task.completedAt ||
            moment(task.completedAt)
              .tz(timeZone)
              .isSame(moment().tz(timeZone), 'day'))
        );
      });
      return filteredTasks.sort((a, b) => b.completedAt - a.completedAt);
    },
    get roomsInNumericalOrder() {
      return self.roomsAsArray.sort(
        (room1, room2) => room1.number - room2.number
      );
    },
  }))
  .actions((self) => ({
    fetchAllRooms: flow(function*(hotelId) {
      self.isFetchingAll = true;
      try {
        const roomsRequest = yield request(
          `${ROOMS_URL}?hotel_id=${hotelId}`,
          'GET'
        );
        roomsRequest.rooms.forEach((room) => {
          /* 
            Compare the timestamp for each changed attribute,
            If the local timestamp for an attribute is newer,
            That means that the local data is the newest and should not be overwritten
          */
          const localRoom = self.rooms.get(room.id);
          if (room?.attributeTimestamps && localRoom?.isSyncing) {
            const localTimestamps = JSON.parse(localRoom?.attributeTimestamps);
            Object.keys(localTimestamps).forEach((key) => {
              const attribute = key.replace('updatedAt', '');
              const localTimestamp = localTimestamps[key];
              const timestamp = room?.attributeTimestamps[key];
              if (moment(localTimestamp).isSameOrAfter(moment(timestamp))) {
                // Replace the attribute with the old attribute
                room[attribute] = localRoom[attribute];
                room.attributeTimestamps[key] = localTimestamp;
              }
            });
          }

          room.attributeTimestamps =
            JSON.stringify(room?.attributeTimestamps) || null;
          self.rooms.set(room.id, room);
        });
      } catch (error) {
        self.networkError = JSON.stringify(error);
        console.warn('Failed to fetch rooms', error);
      }
      self.isFetchingAll = false;
    }),
    fetchRoom: flow(function*(roomId) {
      try {
        const { room } = yield request(`${ROOMS_URL}/${roomId}`, 'GET');

        /* 
            Compare the timestamp for each changed attribute,
            If the local timestamp for an attribute is newer,
            That means that the local data is the newest and should not be overwritten
        */
        const localRoom = self.rooms.get(room.id);
        if (room?.attributeTimestamps && localRoom?.isSyncing) {
          const localTimestamps = JSON.parse(localRoom?.attributeTimestamps);
          Object.keys(localTimestamps).forEach((key) => {
            const attribute = key.replace('updatedAt', '');
            const localTimestamp = localTimestamps[key];
            const timestamp = room?.attributeTimestamps[key];
            if (moment(localTimestamp).isSameOrAfter(moment(timestamp))) {
              // Replace the attribute with the old attribute
              room[attribute] = localRoom[attribute];
              room.attributeTimestamps[key] = localTimestamp;
            }
          });
        }
        room.attributeTimestamps =
          JSON.stringify(room?.attributeTimestamps) || null;
        self.rooms.set(room.id, room);
      } catch (error) {
        console.log(error);
      }
    }),
    dismissNetworkError() {
      self.networkError = undefined;
    },
    updateRoom: flow(function*({ roomId, options }) {
      self.isFetchingOne = true;

      try {
        // Create a local copy of the room and update it locally
        const updatedRoom = cloneDeep(self.rooms.get(roomId));
        updatedRoom.isSyncing = true;
        merge(updatedRoom, options.body.room);
        updatedRoom.attributeTimestamps = addAttributeTimestamps(
          options.body.room,
          ['vacancyStatus', 'cleanStatus', 'doNotDisturb']
        );
        self.rooms.set(roomId, updatedRoom);

        const updatedRoomResponse = yield request(
          `${UPDATE_ROOM_URL}/${roomId}`,
          'PATCH',
          {
            body: options.body,
          }
        );
        updatedRoomResponse.room.attributeTimestamps =
          JSON.stringify(updatedRoomResponse.room?.attributeTimestamps) || null;
        self.rooms.set(roomId, updatedRoomResponse.room);
      } catch (error) {
        console.warn('Failed to update room', error);
        self.networkError = JSON.stringify(error);

        // On a failed network request to update the room,
        if (
          error
            .toString()
            .toLowerCase()
            .includes('network request failed')
        ) {
          // Create a mobx-state-tree action that mimics 'updateRoom'
          const action = {
            args: [{ roomId, options }],
            name: 'updateRoom',
            path: '/roomStore',
          };
          //  Store that action in the offlineStore,
          //  using the roomId as an identifier to update that action if it already exists
          getRoot(self).offlineStore.upsertAction({ id: `${roomId}`, action });
        } else {
          const room = self.rooms.get(roomId);
          self.rooms.set(roomId, {
            ...room,
            isSyncing: false,
          });
          getRoot(self).notificationStore.createNotification({
            text: i18n.t(strings.SOMETHING_WENT_WRONG),
          });
        }
      }
      self.isFetchingOne = false;
    }),
    updateRooms: flow(function*({ options }) {
      const hotelId = getRoot(self).hotelStore.selectedHotelId;

      options.body.rooms.forEach((room) => {
        // Create a local copy of the room and update it locally
        const updatedRoom = cloneDeep(self.rooms.get(room.id));
        updatedRoom.isSyncing = true;
        merge(updatedRoom, room);
        updatedRoom.attributeTimestamps = addAttributeTimestamps(room, [
          'vacancyStatus',
          'cleanStatus',
          'doNotDisturb',
        ]);
        self.rooms.set(room.id, updatedRoom);
      });

      try {
        const updatedRoomsResponse = yield request(
          `${UPDATE_ROOM_URL}?hotel_id=${hotelId}`,
          'PUT',
          options
        );
        updatedRoomsResponse.rooms.forEach((room) => {
          room.attributeTimestamps =
            JSON.stringify(room?.attributeTimestamps) || null;
          self.rooms.set(room.id, room);
        });
      } catch (error) {
        console.warn('Failed to update rooms', error);
        self.networkError = JSON.stringify(error);

        if (
          error
            .toString()
            .toLowerCase()
            .includes('network request failed')
        ) {
          const action = {
            args: [{ options }],
            name: 'updateRooms',
            path: '/roomStore',
          };

          getRoot(self).offlineStore.upsertAction({ action });
        } else {
          options.body.rooms.forEach(({ id }) => {
            const room = self.rooms.get(id);
            self.rooms.set(id, {
              ...room,
              isSyncing: false,
            });
          });
          getRoot(self).notificationStore.createNotification({
            text: i18n.t(strings.SOMETHING_WENT_WRONG),
          });
        }
      }
    }),
  }));

export default RoomStore;
