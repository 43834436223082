import { Dimensions } from 'react-native';

const MAX_SCREEN_WIDTH = 450;
const MAX_SCREEN_HEIGHT = 850;

const SCREEN_WIDTH = Dimensions.get('window').width;
const SCREEN_WIDTH_MAXED =
  SCREEN_WIDTH > MAX_SCREEN_WIDTH ? MAX_SCREEN_WIDTH : SCREEN_WIDTH;

const SCREEN_HEIGHT = Dimensions.get('window').height;
const SCREEN_HEIGHT_MAXED =
  SCREEN_HEIGHT > MAX_SCREEN_HEIGHT ? MAX_SCREEN_HEIGHT : SCREEN_HEIGHT;

export default {
  SCREEN_WIDTH,
  SCREEN_WIDTH_MAXED,
  MAX_SCREEN_WIDTH,
  SCREEN_HEIGHT,
  SCREEN_HEIGHT_MAXED,
};
