/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { TouchableOpacity, Text, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import TaskIcon from 'assets/icons/task.svg';
import { VACANCY_STATUS } from 'src/models/RoomStore';
import strings from 'src/constants/i18n/strings';
import screen from 'src/constants/screen';

const Container = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${screen.SCREEN_HEIGHT * 0.07}px;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.BACKGROUND_DARK};
  padding-horizontal: ${screen.SCREEN_WIDTH * 0.03}px;
  background-color: ${(props) =>
    props.selected
      ? ({ theme }) => theme.colors.PRIMARY_LIGHT
      : ({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const RoomNumber = styled(Text)`
  ${({ theme }) => theme.textStyles.h2}
`;

const RoomVacancyText = styled(Text)`
    ${({ theme }) => theme.textStyles.h5}
    margin-top: ${screen.SCREEN_HEIGHT * 0.01}px;
    margin-left: ${screen.SCREEN_WIDTH * 0.02}px;
`;

const TimestampText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5};
  color: ${(props) =>
    props.isRed
      ? ({ theme }) => theme.colors.RED
      : ({ theme }) => theme.colors.DIM_GREY};
`;

const LeftContent = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RightContent = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const RoomListItem = ({
  selected,
  room,
  timeZone,
  roomsSelected,
  setRoomsSelected,
  completeTasks,
  hotelTask,
}) => {
  const theme = useContext(ThemeContext);
  const completeTasksForRoom = completeTasks.filter(
    (task) => task.roomId === room.id
  );

  const laterTask = room.laterTasks.filter((task) => {
    if (JSON.stringify(task.description) === hotelTask.descriptionAsJSON) {
      return true;
    }
    return false;
  });

  const incompleteTask = room.incompleteTasks.filter((task) => {
    if (JSON.stringify(task.description) === hotelTask.descriptionAsJSON) {
      return true;
    }
    return false;
  });

  const { t } = useTranslation();

  let timeStamp = 'Never';
  if (completeTasksForRoom.length > 0) {
    const mostRecentCompletedAt = new Date(
      Math.max(...completeTasksForRoom.map((e) => new Date(e.completedAt)))
    );
    timeStamp = `${t(strings.AGO_TEXT_SPANISH)}${moment
      .duration(moment().tz(timeZone) - moment(mostRecentCompletedAt))
      .humanize()} ${t(strings.AGO_TEXT)}`;
  }

  const getVacancyStatusText = () => {
    if (room.doNotDisturb) {
      return t(strings.ROOM_DND_TEXT);
    }
    switch (room.vacancyStatus) {
      case VACANCY_STATUS.STAYOVER:
        return t(strings.ROOM_STAYOVER_TEXT);
      case VACANCY_STATUS.BLOCKED:
        return t(strings.ROOM_BLOCKED_TEXT);
      case VACANCY_STATUS.VACANT:
        return t(strings.ROOM_VACANT_TEXT);
      case VACANCY_STATUS.CHECKOUT:
        return t(strings.ROOM_CHECKOUT_TEXT);
      default:
        return '';
    }
  };

  return (
    <Container
      selected={selected}
      onPress={() => {
        let updatedRoomsSelected;
        if (roomsSelected.includes(room)) {
          updatedRoomsSelected = roomsSelected.filter((item) => item !== room);
        } else {
          updatedRoomsSelected = [...roomsSelected, room];
        }
        setRoomsSelected(updatedRoomsSelected);
      }}
    >
      <LeftContent>
        <RoomNumber>{room.number}</RoomNumber>
        <RoomVacancyText>{getVacancyStatusText()}</RoomVacancyText>
        {incompleteTask.length > 0 ? (
          <TaskIcon
            width={screen.SCREEN_WIDTH_MAXED * 0.05}
            height={screen.SCREEN_WIDTH_MAXED * 0.05}
            style={{ marginLeft: 8 }}
            fill={theme.colors.DIM_GREY}
          />
        ) : null}
        <View
          style={{
            marginTop: screen.SCREEN_HEIGHT * 0.01,
            marginLeft: screen.SCREEN_WIDTH * 0.01,
          }}
        />
      </LeftContent>
      <RightContent>
        <TimestampText isRed={laterTask.length > 0}>{timeStamp}</TimestampText>
      </RightContent>
    </Container>
  );
};

RoomListItem.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
    incompleteTasks: PropTypes.array.isRequired,
    laterTasks: PropTypes.array.isRequired,
    completeTasks: PropTypes.array.isRequired,
  }).isRequired,
  selected: PropTypes.bool,
  roomsSelected: PropTypes.array,
  setRoomsSelected: PropTypes.func,
  completeTasks: PropTypes.array,
  timeZone: PropTypes.string,
  hotelTask: PropTypes.object,
};

export default RoomListItem;
