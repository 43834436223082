import React from 'react';
import PropTypes from 'prop-types';
import { FlatList, View, Text, TouchableOpacity } from 'react-native';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import TaskCard from 'src/components/TaskCard';

const RoomTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  padding-bottom: 5px;
  margin-left: ${screen.SCREEN_WIDTH * 0.03}px;
  margin-top: ${screen.SCREEN_WIDTH * 0.05}px;
  color: ${({ theme }) => theme.colors.BLACK};
`;

const Header = styled(View)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: row;
`;

const SortButton = styled(TouchableOpacity)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  border: 1px solid ${({ theme }) => theme.colors.DIM_GREY};
  padding-horizontal: ${screen.SCREEN_WIDTH * 0.05}px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: ${screen.SCREEN_HEIGHT * 0.03}px;
  margin-right: ${screen.SCREEN_WIDTH * 0.04}px;
  margin-bottom: ${screen.SCREEN_HEIGHT * 0.005}px;
`;

const RoomTaskContainer = observer(
  ({ roomTask, setTaskSelected, index, setMenuVisible, sortOption }) => {
    const { t } = useTranslation();
    const ROOM_SORT_VALUE = 'room';

    let sortLabel;

    if (sortOption === ROOM_SORT_VALUE) {
      sortLabel = t(strings.SORT_TEXT);
    } else {
      sortLabel = t(strings.RECENT_SORT_LABEL);
    }

    return (
      <View>
        <Header>
          <RoomTitle>
            {t(strings.ROOM_TEXT)} {roomTask.room.number}
          </RoomTitle>
          {index === 0 ? (
            <SortButton onPress={() => setMenuVisible(true)}>
              <Text>{sortLabel}</Text>
            </SortButton>
          ) : null}
        </Header>

        <FlatList
          data={roomTask.tasks}
          keyExtractor={({ id }) => id.toString()}
          renderItem={({ item: task }) => (
            <TaskCard
              task={task}
              onDialogOpen={() => {
                setTaskSelected(task);
              }}
            />
          )}
        />
      </View>
    );
  }
);

RoomTaskContainer.propTypes = {
  setTaskSelected: PropTypes.func,
  setMenuVisible: PropTypes.func,
  index: PropTypes.number,
  roomTask: PropTypes.object,
};

export default RoomTaskContainer;
