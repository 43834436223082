import React from 'react';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { View, Text } from 'react-native';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import * as routes from 'src/screens/Room/routes';
import { screen } from 'src/constants';
import Button from 'src/components/Button';
import { useStore } from 'src/context/StoreContext';
import { CLEAN_STATUS } from 'src/models/RoomStore';

const Container = styled(View)`
  padding-left: ${screen.SCREEN_WIDTH_MAXED * 0.03}px;
  padding-right: ${screen.SCREEN_WIDTH_MAXED * 0.02}px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.3);
  elevation: 3;
  border-radius: 4px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-horizontal: ${screen.SCREEN_WIDTH * 0.03}px;
  padding: ${screen.SCREEN_WIDTH_MAXED * 0.04}px;
`;

const ButtonContainer = styled(View)`
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin-top: ${screen.SCREEN_WIDTH_MAXED * 0.06}px;
`;

const NotReadyButton = styled(Button)`
  width: 45%;
  color: #20b2aa;
`;

const CleanButton = styled(Button)`
  background-color: #20b2aa;
  width: 45%;
`;

const HeaderText = styled(Text)`
  ${({ theme }) => theme.textStyles.h3};
`;

const TimestampText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const InspectionCard = ({ room }) => {
  const { roomStore } = useStore();
  const navigation = useNavigation();
  const { t } = useTranslation();
  const isInspect = true;

  return (
    <Container>
      <HeaderText>{t(strings.INSPECT_TEXT)}</HeaderText>
      <TimestampText>{`Cleaned ${moment
        .duration(moment() - moment(room.updatedAt))
        .humanize()} ago`}</TimestampText>
      <ButtonContainer>
        <NotReadyButton
          label={t(strings.INSPECT_NOT_READY_TEXT)}
          mode="text"
          labelStyle={{ color: '#20b2aa' }}
          onPress={() => {
            navigation.push(routes.ChooseTaskScreen, {
              room,
              isInspect,
            });
          }}
        />
        <CleanButton
          label={t(strings.INSPECT_CLEAN_TEXT)}
          onPress={() => {
            roomStore.updateRoom({
              roomId: room.id,
              options: {
                body: {
                  room: {
                    cleanStatus: CLEAN_STATUS.CLEAN,
                  },
                },
              },
            });
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

InspectionCard.propTypes = {
  room: PropTypes.shape({
    id: PropTypes.number.isRequired,
    number: PropTypes.string.isRequired,
    cleanStatus: PropTypes.string.isRequired,
    vacancyStatus: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
    doNotDisturb: PropTypes.bool.isRequired,
  }).isRequired,
};

export default InspectionCard;
