import React from 'react';
import { Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import * as SecureStore from 'expo-secure-store';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import SubmitButtonFooter from 'src/components/SubmitButtonFooter';

export const CANCEL_BUTTON = 'CANCEL_BUTTON';
export const APPLY_FILTER_BUTTON = 'APPLY_FILTER_BUTTON';

const RoomFilterFooter = ({ setFilters, localFilters, isDisabled }) => {
  const navigation = useNavigation();
  const { t } = useTranslation();
  const { userStore } = useStore();

  const applyFilter = async () => {
    setFilters(localFilters);
    tryToPersistFilters();
    navigation.goBack();
  };

  const tryToPersistFilters = async () => {
    try {
      let userSettings;

      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        userSettings = localStorage.getItem(userStore.authenticatedUser.id);
      } else {
        userSettings = await SecureStore.getItemAsync(
          userStore.authenticatedUser.id.toString()
        );
      }

      if (userSettings) {
        const parsedUserSettings = JSON.parse(userSettings);
        userSettings = {
          ...parsedUserSettings,
          roomFilters: localFilters,
        };
      } else {
        userSettings = {
          roomFilters: localFilters,
        };
      }

      if (Platform.OS === 'web') {
        // eslint-disable-next-line no-undef
        localStorage.setItem(
          userStore.authenticatedUser.id,
          JSON.stringify(userSettings)
        );
      } else {
        await SecureStore.setItemAsync(
          userStore.authenticatedUser.id.toString(),
          JSON.stringify(userSettings)
        );
      }
    } catch (e) {
      console.warn(e);
    }
  };

  return (
    <SubmitButtonFooter
      onSubmit={applyFilter}
      onCancel={navigation.goBack}
      disabled={isDisabled && !localFilters.doNotDisturb}
      submitButtonText={t(strings.APPLY_BUTTON_FILTER_TEXT)}
      cancelButtonText={t(strings.CANCEL).toUpperCase()}
    />
  );
};

RoomFilterFooter.propTypes = {
  localFilters: PropTypes.object,
  setFilters: PropTypes.func,
  isDisabled: PropTypes.bool,
};

export default RoomFilterFooter;
