import React, { useEffect, useContext } from 'react';
import { Text, View } from 'react-native';
import PropTypes from 'prop-types';
import styled, { ThemeContext } from 'styled-components/native';
import { ActivityIndicator } from 'react-native-paper';
import { observer } from 'mobx-react-lite';
import Modal from 'react-native-modal';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import ErrorIcon from 'assets/icons/error.svg';
import SuccessIcon from 'assets/icons/success.svg';
import Button from 'src/components/Button';

const ICON_SIZE = 72;
const REDIRECT_TIME = 2000;

const Title = styled(Text)`
  ${({ theme }) => theme.textStyles.h3}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const TitleContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Container = styled(View)`
  justify-content: space-evenly;
  align-items: center;
  height: 33%;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  padding: 15px;
`;

const CenterContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const BottomContainer = styled(View)`
  flex: 1;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
`;

const NetworkLoadingModal = observer(
  ({
    isVisible,
    isLoading,
    networkError,
    loadingTitle = 'Loading',
    errorTitle = 'Error',
    successTitle = 'Success',
    onAbort,
    onRetry,
    onCancel,
    onOk,
    onDismissNetworkError,
    autoOkAfterSuccess = true,
  }) => {
    const theme = useContext(ThemeContext);
    const { t } = useTranslation();

    const LoadingComponent = () => (
      <>
        <TitleContainer>
          <Title>{loadingTitle}</Title>
        </TitleContainer>

        <CenterContainer>
          <ActivityIndicator size={ICON_SIZE} color={theme.colors.BLACK} />
        </CenterContainer>

        <BottomContainer>
          <Button
            onPress={onAbort}
            testID="LOADING_MODAL_CANCEL"
            label={t(strings.CANCEL)}
            mode="text"
          />
        </BottomContainer>
      </>
    );

    const ErrorComponent = () => {
      return (
        <>
          <TitleContainer>
            <Title>{errorTitle}</Title>
          </TitleContainer>

          <CenterContainer>
            <ErrorIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={theme.colors.RED}
            />
          </CenterContainer>

          <BottomContainer>
            <Button
              style={{ flex: 4 }}
              onPress={() => {
                onCancel();
                onDismissNetworkError();
              }}
              label={t(strings.CANCEL)}
              mode="text"
            />
            <Button
              style={{ flex: 6 }}
              onPress={() => {
                onDismissNetworkError();
                onRetry();
              }}
              label={t(strings.RETRY)}
            />
          </BottomContainer>
        </>
      );
    };

    const SuccessComponent = () => {
      // eslint-disable-next-line consistent-return
      useEffect(() => {
        if (autoOkAfterSuccess) {
          const timer = setTimeout(() => {
            onCancel();
            onOk();
          }, REDIRECT_TIME);
          return () => clearTimeout(timer);
        }
      }, []);

      return (
        <>
          <TitleContainer>
            <Title>{successTitle}</Title>
          </TitleContainer>
          <CenterContainer>
            <SuccessIcon
              width={ICON_SIZE}
              height={ICON_SIZE}
              fill={theme.colors.PRIMARY}
            />
          </CenterContainer>

          <BottomContainer>
            <Button
              style={{
                width: '30%',
              }}
              onPress={() => {
                onCancel();
                onOk();
              }}
              label={t(strings.OK)}
            />
          </BottomContainer>
        </>
      );
    };

    const toRender = () => {
      if (isLoading) {
        return <LoadingComponent />;
      }
      if (networkError) {
        return <ErrorComponent />;
      }

      return <SuccessComponent />;
    };

    return (
      <Modal isVisible={isVisible} backdropOpacity={0.6} useNativeDriver>
        <Container testID="NetworkLoadingModal">
          {isVisible && toRender()}
        </Container>
      </Modal>
    );
  }
);

NetworkLoadingModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  networkError: PropTypes.any,
  loadingTitle: PropTypes.string.isRequired,
  errorTitle: PropTypes.string.isRequired,
  successTitle: PropTypes.string.isRequired,
  onAbort: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  onDismissNetworkError: PropTypes.func.isRequired,
  autoOkAfterSuccess: PropTypes.bool,
};

export default NetworkLoadingModal;
