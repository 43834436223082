import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native';

import LBAirbrakeNotifier from '../utils/LBAirbrakeNotifier';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    if (!process.env.NODE_ENV === 'development') {
      LBAirbrakeNotifier.notify({
        error,
        params: { info },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return <Text>Something went wrong.</Text>;
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default ErrorBoundary;
