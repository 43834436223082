import React, { useContext } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import styled, { ThemeContext, css } from 'styled-components/native';
import PropTypes from 'prop-types';
import FastImage from 'react-native-fast-image';

import { screen } from '../../../../constants';

const ContainerStyle = css`
  width: ${screen.SCREEN_WIDTH_MAXED * 0.4}px;
  height: ${screen.SCREEN_WIDTH_MAXED * 0.4}px;
  margin-vertical: ${screen.SCREEN_HEIGHT * 0.01}px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  border-radius: 4px;
`;

const CardBackground = styled(FastImage)`
  justify-content: flex-end;
  border-radius: 4px;
  height: 100%;
  width: 100%;
`;

const CardFooter = styled(View)`
  display: flex;
  width: 100%;
  justify-content: center;
  height: ${screen.SCREEN_WIDTH_MAXED * 0.12}px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  opacity: 0.9;
  align-items: center;
  bottom: -0.5px;
`;

const FooterText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  font-family: ${({ theme }) => theme.fontFamily.regular};
  text-align: center;
`;

export const testIds = {
  ICON: 'ICON',
  IMAGE: 'IMAGE',
};

const TaskImage = ({ task, touchable, selected, onPressImage }) => {
  const theme = useContext(ThemeContext);

  const Container = touchable
    ? styled(TouchableOpacity)`
        ${ContainerStyle}
      `
    : styled(View)`
        ${ContainerStyle}
      `;

  const borderColor = selected ? theme.colors.PRIMARY : 'rgba(0, 0, 0, 0.0)';
  const Icon = task.icon;

  const Footer = () => {
    return (
      <CardFooter>
        <FooterText>{task.title}</FooterText>
      </CardFooter>
    );
  };

  return (
    <Container onPress={() => onPressImage(task)} testID={task.title}>
      {Icon ? (
        <View
          style={{
            borderColor,
            borderWidth: selected ? 4 : 0,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: 'column',
            elevation: 3,
            borderRadius: 4,
            backgroundColor: task.backgroundColor,
          }}
        >
          <Icon
            width="40%"
            height="40%"
            style={{
              color: 'black',
              marginVertical: screen.SCREEN_HEIGHT * 0.02,
            }}
            testID={testIds.ICON}
          />
          <Footer />
        </View>
      ) : (
        <CardBackground
          source={{ uri: task.image }}
          style={{
            borderColor,
            borderWidth: selected ? 4 : 0,
            elevation: 3,
          }}
          imageStyle={{ borderRadius: 4 }}
          testID={testIds.IMAGE}
        >
          <Footer />
        </CardBackground>
      )}
    </Container>
  );
};

TaskImage.propTypes = {
  task: PropTypes.object.isRequired,
  touchable: PropTypes.bool,
  selected: PropTypes.bool,
  onPressImage: PropTypes.func,
};

export default TaskImage;
