import React, { useMemo } from 'react';
import { View, TouchableOpacity, Text } from 'react-native';
import styled from 'styled-components/native';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { useStore } from 'src/context/StoreContext';
import RightIcon from 'assets/icons/right.svg';

const Container = styled(TouchableOpacity)`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
  border-radius: 4px;
  height: 72px;
  padding: 16px;
  margin-bottom: 16px;
  flex-direction: row;
`;

const TextContainer = styled(View)`
  flex: 9;
  justify-content: center;
`;

const IconContainer = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const NameText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
`;

const AssignedCountText = styled(Text)`
  ${({ theme }) => theme.textStyles.h5}
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const ActiveUserCard = observer(({ user, onPress, roomAssignments }) => {
  const { firstName, lastName } = user;
  const { roomStore } = useStore();
  const { t } = useTranslation();

  const userRoomAssignmentsAsArray = useMemo(() => {
    if (roomAssignments[user.id]) {
      return Object.keys(roomAssignments[user.id]).map(
        (roomId) => roomAssignments[user.id][roomId]
      );
    }
    return [];
  }, [roomAssignments]);

  const stayoverAssignmentCount = userRoomAssignmentsAsArray.filter(
    ({ roomId }) => {
      const room = roomStore.rooms.get(roomId);
      return room.vacancyStatus === 'stayover';
    }
  ).length;

  const checkoutAssignmentCount = userRoomAssignmentsAsArray.filter(
    ({ roomId }) => {
      const room = roomStore.rooms.get(roomId);
      return (
        room.vacancyStatus === 'vacant' || room.vacancyStatus === 'checkout'
      );
    }
  ).length;

  return (
    <Container onPress={onPress}>
      <TextContainer>
        <NameText>{`${firstName} ${lastName}`}</NameText>
        <AssignedCountText>
          {`${checkoutAssignmentCount} ${t(
            strings.CHECKOUT_ASSIGN_TEXT
          )} | ${stayoverAssignmentCount} ${t(strings.STAYOVER_ASSIGN_TEXT)}`}
        </AssignedCountText>
      </TextContainer>
      <IconContainer>
        <RightIcon width={32} fill="#737373" />
      </IconContainer>
    </Container>
  );
});

ActiveUserCard.propTypes = {
  user: PropTypes.object.isRequired,
  onPress: PropTypes.func.isRequired,
};

export default ActiveUserCard;
