import { DefaultTheme } from 'react-native-paper';
/*
Configuration options available at
https://callstack.github.io/react-native-paper/theming.html
https://github.com/callstack/react-native-paper/blob/master/src/styles/DefaultTheme.tsx
*/
export default {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: '#20b2aa',
    accent: '#20b2aa',
    background: '#ffffff',
  },
};
