import React from 'react';
import { View, Text } from 'react-native';
import PropTypes from 'prop-types';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';

import strings from 'src/constants/i18n/strings';
import { VACANCY_STATUS, COLORS } from 'src/models/RoomStore';
import RoomFilterItem from './RoomFilterItem';

export const BLOCKED = 'BLOCKED';
export const CHECKOUT = 'CHECKOUT';
export const STAYOVER = 'STAYOVER';
export const VACANT = 'VACANT';

const ItemsTitle = styled(Text)`
  ${({ theme }) => theme.textStyles.body2};
  color: ${({ theme }) => theme.colors.BLACK};
  margin-bottom: 8px;
`;

const RoomFilterVacancyItems = ({ localFilters, setVacancyStatusFilter }) => {
  const { t } = useTranslation();

  return (
    <View style={{ marginBottom: 20 }}>
      <ItemsTitle>{t(strings.ROOM_STATUS)}</ItemsTitle>
      <RoomFilterItem
        testID={BLOCKED}
        label={t(strings.BLOCKED_FILTER_TEXT)}
        backgroundColor={COLORS.BLOCKED}
        selected={localFilters.vacancyStatus.includes(VACANCY_STATUS.BLOCKED)}
        onPress={() => {
          setVacancyStatusFilter(VACANCY_STATUS.BLOCKED);
        }}
      />
      <RoomFilterItem
        testID={CHECKOUT}
        label={t(strings.CHECKOUT_FILTER_TEXT)}
        selected={localFilters.vacancyStatus.includes(VACANCY_STATUS.CHECKOUT)}
        onPress={() => {
          setVacancyStatusFilter(VACANCY_STATUS.CHECKOUT);
        }}
      />
      <RoomFilterItem
        testID={STAYOVER}
        label={t(strings.STAYOVER_FILTER_TEXT)}
        selected={localFilters.vacancyStatus.includes(VACANCY_STATUS.STAYOVER)}
        onPress={() => {
          setVacancyStatusFilter(VACANCY_STATUS.STAYOVER);
        }}
      />
      <RoomFilterItem
        testID={VACANT}
        label={t(strings.VACANT_FILTER_TEXT)}
        selected={localFilters.vacancyStatus.includes(VACANCY_STATUS.VACANT)}
        onPress={() => {
          setVacancyStatusFilter(VACANCY_STATUS.VACANT);
        }}
      />
    </View>
  );
};

RoomFilterVacancyItems.propTypes = {
  localFilters: PropTypes.object,
  setVacancyStatusFilter: PropTypes.func,
};

export default RoomFilterVacancyItems;
