/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import { TouchableOpacity, Text, ActivityIndicator } from 'react-native';
import styled, { ThemeContext, css } from 'styled-components/native';
import PropTypes from 'prop-types';

const NormalMode = css`
  background-color: ${({ theme, disabled }) => {
    if (disabled) {
      return theme.colors.DISABLED;
    }
    return theme.colors.PRIMARY;
  }};
`;

const NormalModeLabel = css`
  color: ${({ theme }) => theme.colors.SECONDARY};
`;

const TextMode = css`
  background-color: transparent;
`;

const TextModeLabel = css`
  color: ${({ theme }) => theme.colors.TERTIARY};
`;

const OutlineMode = css`
  background-color: ${({ theme }) => theme.colors.BACKGROUND_OPAQUE};
  border: 2px solid ${({ theme }) => theme.colors.PRIMARY};
`;

const OutlineModeLabel = css`
  color: ${({ theme }) => theme.colors.PRIMARY_DARK};
`;

const Container = styled(TouchableOpacity)`
  ${({ mode }) => {
    switch (mode) {
      case 'text':
        return TextMode;
      case 'outline':
        return OutlineMode;
      default:
        return NormalMode;
    }
  }}

  opacity: ${({ disabled }) => (disabled ? 0.75 : 1)};
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border-radius: 4px;
  height: ${({ theme }) => theme.rem}px;
`;

const LabelText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4};
  ${({ mode }) => {
    switch (mode) {
      case 'text':
        return TextModeLabel;
      case 'outline':
        return OutlineModeLabel;
      default:
        return NormalModeLabel;
    }
  }}
  text-transform: uppercase;
  letter-spacing: 0.75px;
`;

const Button = ({
  onPress,
  label,
  disabled,
  loading,
  iconLabelLeft,
  iconLabelRight,
  mode = 'normal',
  containerStyle,
  labelStyle,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Container
      onPress={onPress}
      mode={mode}
      style={containerStyle}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <ActivityIndicator size="small" color={theme.colors.SECONDARY} />
      ) : (
        <>
          {iconLabelLeft || null}
          <LabelText mode={mode} style={labelStyle}>
            {label}
          </LabelText>
          {iconLabelRight || null}
        </>
      )}
    </Container>
  );
};

Button.propTypes = {
  onPress: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  mode: PropTypes.oneOf(['text', 'outline', 'normal']),
  containerStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  iconLabel: PropTypes.object,
  iconLabelLeft: PropTypes.object,
  iconLabelRight: PropTypes.object,
};

export default Button;
