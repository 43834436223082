/* eslint-disable global-require */
import { types, flow, getRoot } from 'mobx-state-tree';
import { Platform } from 'react-native';
// eslint-disable-next-line import/no-unresolved
import { AUTH_COOKIE_NAME } from '@env';
import * as SecureStore from 'expo-secure-store';
import i18n from 'i18next';
import * as Localization from 'expo-localization';

import { strings } from 'src/constants/i18n';
import { request } from 'src/utils/LodgebookAPIClient';
import registerForPushNotificationsAsync from 'src/utils/registerForPushNotificationsAsync';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const LOGIN_URL = '/users/sign_in';
export const LOGOUT_URL = '/users/sign_out';
export const NOTIFICATION_TOKEN_URL = '/notification_tokens';

let CookieManager;
let useCookies;
if (Platform.OS === 'web') {
  ({ useCookies } = require('react-cookie'));
} else {
  CookieManager = require('@react-native-community/cookies');
}

let allCookies;
let removeCookie;
if (Platform.OS === 'web') {
  [allCookies, , removeCookie] = useCookies([AUTH_COOKIE_NAME]);
}

const AuthStore = types.snapshotProcessor(
  types
    .model('AuthStore', {
      cookie: types.maybeNull(types.string),
      isLoading: types.optional(types.boolean, false),
      isRestoringCookie: types.optional(types.boolean, false),
    })
    .actions((self) => ({
      login: flow(function*({ username, password }) {
        self.isLoading = true;

        try {
          const body = {
            user: {
              username,
              password,
            },
          };

          const res = yield request(LOGIN_URL, 'POST', {
            body,
            parseResponse: false,
          });

          let cookie = res.headers.get('set-cookie');
          if (Platform.OS === 'web') {
            cookie = allCookies[AUTH_COOKIE_NAME];
          } else {
            yield SecureStore.setItemAsync('set-cookie', cookie);
          }
          self.cookie = cookie;
          self.isLoading = false;
        } catch (err) {
          console.log(err);
          // Error message returned by current API does not contain any context
          if (err.status === 401) {
            getRoot(self).notificationStore.createNotification({
              text: i18n.t(strings.LOGIN_CREDENTIALS_FAILURE_TEXT),
            });
          } else {
            getRoot(self).notificationStore.createNotification({
              text: i18n.t(strings.LOGIN_NETWORK_FAILURE_TEXT),
            });
          }
        }
        self.isLoading = false;
      }),
      logout: flow(function*() {
        if (Platform.OS === 'web') {
          removeCookie(AUTH_COOKIE_NAME);
        } else {
          yield self.deregisterNotificationToken();
          yield CookieManager.clearAll();
          yield SecureStore.deleteItemAsync('set-cookie');
          yield AsyncStorage.removeItem('mst-snapshot');
        }
        if (process.env.NODE_ENV !== 'test') {
          const deviceLanguage = Localization.locale.split('-')[0];
          i18n.changeLanguage(deviceLanguage);
        }
        self.cookie = null;
        getRoot(self).resetStore();
      }),
      restoreCookie: flow(function*() {
        self.isRestoringCookie = true;
        let cookie;
        try {
          if (Platform.OS === 'web') {
            cookie = allCookies[AUTH_COOKIE_NAME];
          } else {
            cookie = yield SecureStore.getItemAsync('set-cookie');
          }
        } catch (e) {
          // Restoring cookie failed
        }
        self.cookie = cookie || null;
        self.isRestoringCookie = false;
      }),
      registerNotificationToken: flow(function*() {
        try {
          const notificationToken = yield registerForPushNotificationsAsync();
          if (notificationToken) {
            yield request(NOTIFICATION_TOKEN_URL, 'POST', {
              body: {
                notificationToken,
              },
            });
          }
        } catch (error) {
          console.warn(error);
        }
      }),
      deregisterNotificationToken: flow(function*() {
        try {
          const notificationToken = yield registerForPushNotificationsAsync();
          if (notificationToken) {
            yield request(NOTIFICATION_TOKEN_URL, 'DELETE', {
              body: {
                notificationToken,
              },
            });
          }
        } catch (error) {
          console.warn(error);
        }
      }),
    })),
  {
    postProcessor() {
      return {
        cookie: null,
      };
    },
  }
);

export default AuthStore;
