import React, { useContext } from 'react';
import { TouchableOpacity, View, Text } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';

import strings from 'src/constants/i18n/strings';
import { screen } from 'src/constants';
import { INCOMPLETE_STATUS, COMPLETE_STATUS } from 'src/models/TaskStore';

export const OPEN_FILTER_BUTTON = 'OPEN_FILTER_BUTTON';
export const COMPLETE_FILTER_BUTTON = 'COMPLETE_FILTER_BUTTON';

const FilterContainer = styled(View)`
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  width: 100%;
  margin-bottom: ${screen.SCREEN_WIDTH_MAXED * 0.04}px;
  background-color: ${({ theme }) => theme.colors.BACKGROUND_LIGHT};
`;

const FilterButton = styled(TouchableOpacity)`
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: ${screen.SCREEN_WIDTH_MAXED * 0.11}px;
  background-color: ${(props) => props.color};
  border-bottom-width: 2px;
  border-bottom-color: ${(props) => props.borderColor};
`;

const FilterText = styled(Text)`
  ${({ theme }) => theme.textStyles.h4}
  color: ${(props) => props.color};
`;

const RoomDetailsTabs = observer(
  ({ setStatusSelected, statusSelected, room }) => {
    const theme = useContext(ThemeContext);
    const { t } = useTranslation();

    const selectedTextColor = theme.colors.PRIMARY_DARK;
    const unSelectedTextColor = theme.colors.TERTIARY;
    const selectedButtonColor = theme.colors.PRIMARY_LIGHT;
    const unSelectedButtonColor = theme.colors.BACKGROUND_OPAQUE;
    const selectedBorderColor = theme.colors.PRIMARY_DARK;
    const unSelectedBorderColor = theme.colors.PRIMARY_LIGHT;

    return (
      <FilterContainer>
        <FilterButton
          onPress={() => setStatusSelected(INCOMPLETE_STATUS)}
          color={
            statusSelected === INCOMPLETE_STATUS
              ? selectedButtonColor
              : unSelectedButtonColor
          }
          borderColor={
            statusSelected === INCOMPLETE_STATUS
              ? selectedBorderColor
              : unSelectedBorderColor
          }
          testID={OPEN_FILTER_BUTTON}
        >
          <FilterText
            color={
              statusSelected === INCOMPLETE_STATUS
                ? selectedTextColor
                : unSelectedTextColor
            }
          >
            {t(strings.FILTER_OPEN_TEXT)} ({room.incompleteTasks.length})
          </FilterText>
        </FilterButton>
        <FilterButton
          onPress={() => setStatusSelected(COMPLETE_STATUS)}
          color={
            statusSelected === COMPLETE_STATUS
              ? selectedButtonColor
              : unSelectedButtonColor
          }
          borderColor={
            statusSelected === COMPLETE_STATUS
              ? selectedBorderColor
              : unSelectedBorderColor
          }
          testID={COMPLETE_FILTER_BUTTON}
        >
          <FilterText
            color={
              statusSelected === COMPLETE_STATUS
                ? selectedTextColor
                : unSelectedTextColor
            }
          >
            {t(strings.FILTER_COMPLETE_TEXT)} ({room.completedTodayTasks.length}
            )
          </FilterText>
        </FilterButton>
      </FilterContainer>
    );
  }
);

RoomDetailsTabs.propTypes = {
  setStatusSelected: PropTypes.func,
  statusSelected: PropTypes.string,
  room: PropTypes.shape({
    incompleteTasks: PropTypes.array,
    completedTodayTasks: PropTypes.array,
  }).isRequired,
};

export default RoomDetailsTabs;
